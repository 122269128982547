import {
  IonContent,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
} from "@ionic/react";
import { IProductWithStore } from "../../types/ProductTypes";
import ProductCard from "../ProductCard";
import "./style.scss";
import { TProduct } from "../../pages/CategoryPage";
import { ArrayLength } from "../../core/baseFunction";
import { useTranslation } from "react-i18next";
interface SearchResultViewProps {
  isLoading: boolean;
  products?: TProduct[];
  getDatanext: (ev) => void;
  isLoad: boolean;
}
const store = {
  id: "sad",
  display_name: "shop",
  username: "string",
  is_verified: true,
  store_img_src: "",
};
const SearchResultView: React.FC<SearchResultViewProps> = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <IonContent>
      {props.isLoading || !props.products ? (
        <div className="spinner-container">
          <IonSpinner name="crescent" color="primary" />
        </div>
      ) : (
        <>
          {props.products?.length > 0 ? (
            <>
              <IonGrid className="product-card-grid">
                {props.products.map((product, index) => (
                  <ProductCard
                    store={product.shops[0].shop}
                    added_on={""}
                    preview_src={
                      ArrayLength(product.images)
                        ? product.images[0].location
                        : ""
                    }
                    {...product}
                    key={index}
                  />
                ))}
              </IonGrid>
              <IonInfiniteScroll
                onIonInfinite={(ev: any) => {
                  props.getDatanext(ev);
                }}
                threshold="100px"
                disabled={props.isLoad}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
          ) : (
            <div className="spinner-container">
              <p className="ion-text-center">{t("noResult")}</p>
            </div>
          )}
        </>
      )}
    </IonContent>
  );
};

export default SearchResultView;
