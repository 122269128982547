import {
  IonContent,
  IonItem,
  IonAvatar,
  IonIcon,
  IonChip,
  IonLabel,
} from "@ionic/react";
import { bagHandleOutline, timeOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { BasicShopDataType } from "../../types/DataTypes";
import "./style.scss";
import { useTranslation } from "react-i18next";

interface SearchingPageProps {
  q: string;
  searchHistory?: { shops: BasicShopDataType[]; keywords: string[] };
  popularItems?: string[];
  relatedShops?: BasicShopDataType[];
  relatedProducts?: string[];
}

/*************************************************************************
	Shop Item for search history and related shops
	************************************************************************/
const ShopItem = (shop) => {
  return (
    <Link to={`/shop/${shop.username}`}>
      <IonItem
        lines="none"
        className="history-item text-color-grey-lighter ion-text-capitalize"
      >
        <IonAvatar className="small-avatar ion-margin-end">
          <img
            src={shop.avatar ? shop.avatar : "https://via.placeholder.com/150"}
          />
        </IonAvatar>
        {shop.name}
      </IonItem>
    </Link>
  );
};

/*************************************************************************
	SearchingView
	************************************************************************/
const SearchingView: React.FC<SearchingPageProps> = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <IonContent className="searching-view">
      {props.popularItems && (
        <>
          <p className="text-color-grey-title subtitle-heavy-14 ion-padding-horizontal ion-no-margin ion-margin-top">
            {t("popular")}
          </p>

          <div className="popular-items-container">
            {props.popularItems.map((item, index) => (
              <Link to={`/search?q=${item}`} key={index}>
                <IonChip>
                  <IonLabel className="ion-text-capitalize">{item}</IonLabel>
                </IonChip>
              </Link>
            ))}
          </div>
        </>
      )}
    </IonContent>
    //   {/*************************************************************************
    // NOTE: BELOW ARE NOT FOR PHASE 1
    // ************************************************************************/}
    // {props.q ? (
    // 	<>
    // 		{/*************************************************************************
    // 				related shops (NOTE: NOT FOR PHASE 1)
    // 				************************************************************************/}
    // 		{props.relatedShops && (
    // 			<>
    // 				<p className="text-color-grey-title subtitle-heavy-14 ion-padding-horizontal ion-no-margin ion-margin-top">Related Shops</p>

    // 				{props.relatedShops.map((shop, index) => (
    // 					<ShopItem {...shop} key={index} />
    // 				))}
    // 			</>
    // 		)}

    // 		{/************************************************************************
    // 				related products (NOTE: NOT FOR PHASE 1)
    // 				************************************************************************/}
    // 		{props.relatedProducts && (
    // 			<>
    // 				<p className="text-color-grey-title subtitle-heavy-14 ion-padding-horizontal ion-no-margin ion-margin-top">Related Products</p>
    // 				{props.relatedProducts.map((product, index) => (
    // 					<Link to={`/search?q=${product}`} key={index}>
    // 						<IonItem lines="none" className="history-item text-color-grey-lighter ion-text-capitalize">
    // 							<IonIcon icon={bagHandleOutline} slot="start" />
    // 							{product}
    // 						</IonItem>
    // 					</Link>
    // 				))}
    // 			</>
    // 		)}
    // 	</>
    // ) : (
    // 	<>
    // 		{/************************************************************************
    // 				search history (NOTE: NOT FOR PHASE 1)
    // 				************************************************************************/}
    // 		{props.searchHistory && (
    // 			<>
    // 				<p className="text-color-grey-title subtitle-heavy-14 ion-padding-horizontal ion-no-margin ion-margin-top">Search History</p>

    // 				{props.searchHistory.shops?.map((shop, index) => (
    // 					<ShopItem {...shop} key={index} />
    // 				))}
    // 				{props.searchHistory.keywords?.map((keyword, index) => (
    // 					<Link to={`/search?q=${keyword}`} key={index}>
    // 						<IonItem lines="none" className="history-item text-color-grey-lighter ion-text-capitalize">
    // 							<IonIcon icon={timeOutline} className="history-icon ion-margin-end" />
    // 							{keyword}
    // 						</IonItem>
    // 					</Link>
    // 				))}
    // 			</>
    // 		)}

    // 		{/************************************************************************
    // 				popular items (NOTE: HARD CODE FOR NOW)
    // 				************************************************************************/}
    // 		{props.popularItems && (
    // 			<>
    // 				<p className="text-color-grey-title subtitle-heavy-14 ion-padding-horizontal ion-no-margin ion-margin-top">Popular Items</p>

    // 				<div className="popular-items-container">
    // 					{props.popularItems.map((item, index) => (
    // 						<Link to={`/search?q=${item}`} key={index}>
    // 							<IonChip>
    // 								<IonLabel className="ion-text-capitalize">{item}</IonLabel>
    // 							</IonChip>
    // 						</Link>
    // 					))}
    // 				</div>
    // 			</>
    // 		)}
    // 	</>
    // )}
  );
};

export default SearchingView;
