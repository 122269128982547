import {
  IonPage,
  useIonViewWillEnter,
  IonToolbar,
  IonButtons,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { chevronBackOutline, filter, optionsOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import IconButton from "../../components/IconButton";
import SearchingView from "../../components/SearchingView";
import SearchResultView from "../../components/SearchResultView";
import SortFilterModal from "../../components/SortFilterModal";
import { useQuery } from "../../utils/urlHelper";
import "./style.scss";
import Searchbar from "../../components/Searchbar";
import { useMutation } from "react-query";
import { searchProducts } from "../../apis/productApis";
import { GetProductsQuery, IProductFilter } from "../../types/ProductTypes";
import { TProduct } from "../CategoryPage";
import { useTranslation } from "react-i18next";
const searchHistory = {
  shops: [
    {
      id: 1652,
      name: "Earringslover",
      username: "earringslover",
      avatar: "",
      isCheckmarked: false,
    },
    {
      id: 1651,
      name: "Earringslover2",
      username: "earringslover2",
      avatar:
        "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
      isCheckmarked: false,
    },
  ],
  keywords: ["earrings", "earrings2"],
};

const popularItems = [
  "watches",
  "clothes",
  "accessories",
  "instruments",
  "pets",
  "caligraphy",
  "wallet",
];

const relatedShops = [
  {
    id: 1653,
    name: "Earringslover",
    username: "earringslover",
    avatar: "",
    isCheckmarked: false,
  },
  {
    id: 1654,
    name: "Earringslover2",
    username: "earringslover2",
    avatar:
      "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    isCheckmarked: false,
  },
];

const relatedProducts = ["earrings", "earrings2"];

const MAX_PRICE = 99999;

const INIT_FILTER: IProductFilter = {
  price_min: 0,
  price_max: MAX_PRICE,
  sort_by: "",
};
// BUG: type and enter too fast remain in the prev serach resulty

const SearchPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const query = useQuery();
  const q = query.get("q");
  const [data, setData] = useState<TProduct[]>([]);
  const [limit, setlimit] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filterQ, setFilter] = useState<any>({});
  const [searchQuery, setSearchQuery] = useState<GetProductsQuery>({
    startFrom: 0,
    q: "",
    categoryId: "",
    filter: {
      price_min: 0,
      price_max: MAX_PRICE,
      sort_by: "",
    },
  });

  // search value before submit
  const [searchValue, setSearchValue] = useState("");
  const {
    data: products,
    mutate: productsMutate,
    isLoading,
  } = useMutation(searchProducts, {
    onSuccess: (data, variables, context) => {
      if (!data.haveError) {
        setIsLoad(false);
        setData(data.data);
        setlimit(0);
      }
    },
  });
  useEffect(() => {
    const newQuery = { ...searchQuery, q: q ?? "", filter: INIT_FILTER };
    setSearchValue(newQuery.q);
    setSearchQuery(newQuery);
    productsMutate(newQuery);
  }, [q]);

  const handleSearchOnChange = (e) => {
    const value = e.detail.value!;
    setSearchValue(value);
    if (!value) {
      setSearchQuery({ ...searchQuery, q: "" });
    }
  };

  const handleSearchOnEnter = (e) => {
    if (e.key === "Enter") {
      props.history.push(`/search?q=${searchValue}`);
    }
  };

  /***
   *
   * 	NOTE: may remvoe back btn later
   * now here just to allow user go back to SearchingView easier
   * expected behaviour: click search button on tab again to go back (?)
   *
   ***/
  const handleBackOnClick = () => {
    setSearchValue("");
    setSearchQuery({ ...searchQuery, q: "" });
    props.history.push("/search");
  };

  const handleFilterOnSubmit = (filter: IProductFilter) => {
    const newQuery = { ...searchQuery, filter: filter };
    setFilter(newQuery);
    setSearchQuery(newQuery);
    productsMutate(newQuery);
  };
  const getDatanext = (ev) => {
    searchProducts({
      startFrom: limit + 10,
      filter: searchQuery?.filter,
      q: searchQuery.q,
    }).then((e) => {
      ev.target.complete();
      if (!e.haveError) {
        setData([...data, ...e.data]);
        if (e.data.length < 10) {
          setIsLoad(true);
        } else {
          setlimit(limit + 10);
        }
      } else {
        setData([]);
      }
    });
  };
  const handleProducts = () => {
    console.log("TYPE CHECK");
  };
  return (
    <IonPage className="search-page">
      <IonToolbar className="ion-padding-top">
        {q && (
          <IonButtons slot="start">
            <IconButton
              iconProps={{ icon: chevronBackOutline }}
              color="medium"
              onClick={handleBackOnClick}
            />
          </IonButtons>
        )}
        <Searchbar
          placeholder={t("search")}
          value={searchValue}
          onChange={handleSearchOnChange}
          onEnter={handleSearchOnEnter}
        ></Searchbar>
        {q && (
          <IonButtons slot="end">
            <IconButton
              className="default-icon-button"
              iconProps={{ icon: optionsOutline }}
              color="medium"
              onClick={() => setShowModal(true)}
            />
          </IonButtons>
        )}
      </IonToolbar>

      {q ? (
        <>
          <SearchResultView
            isLoading={isLoading}
            products={data}
            getDatanext={getDatanext}
            isLoad={isLoad}
          />
        </>
      ) : (
        <SearchingView
          q={searchValue}
          searchHistory={searchHistory}
          popularItems={popularItems}
          relatedShops={relatedShops}
          relatedProducts={relatedProducts}
        />
      )}

      <SortFilterModal
        handleProducts={handleProducts}
        show={showModal}
        onDismiss={() => setShowModal(false)}
        filter={searchQuery.filter}
        onSubmit={handleFilterOnSubmit}
      />
    </IonPage>
  );
};

export default SearchPage;
