import { IonToolbar, IonTitle, IonPage, IonContent } from "@ionic/react";
import "./style.scss";
import SectionTab from "../../components/SectionTab";
import SectionTabs from "../../components/SectionTabs";
import { RouteComponentProps } from "react-router-dom";
import FavProductsTab from "../../components/FavProductsTab";
import FavShopsTab from "../../components/FavShopsTab";
import { useTranslation } from "react-i18next";

const FavouritesPage: React.FC<RouteComponentProps> = () => {
  const { i18n, t } = useTranslation();
  return (
    <IonPage className="fav-page">
      <IonContent>
        <IonToolbar>
          <IonTitle className="toolbar-ion-title">{t("favourites")}</IonTitle>
        </IonToolbar>

        <SectionTabs>
          <SectionTab label={t("products")}>
            <FavProductsTab />
          </SectionTab>

          <SectionTab label={t("shop")}>
            <FavShopsTab />
          </SectionTab>
        </SectionTabs>
      </IonContent>
    </IonPage>
  );
};

export default FavouritesPage;
