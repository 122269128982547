export function ArrayLength(e: any[]) {
  return Array.isArray(e) && e.length > 0;
}

export function GetParams(e: string): any {
  const data = {};
  if (e != "") {
    const a: any[] = e.split("&");
    a.forEach((f) => {
      f = f.split("=");
      f[0] = f[0].replace("?", "");
      f[0] = f[0].replace("&", "");
      data[f[0]] = f[1];
    });
    return data;
  }
  return [];
}

export function getToken() {
  return localStorage.getItem("access_token");
}
export function getLocaleHeaders() {
  const language = localStorage.getItem("language");
  if (language !== null && language !== undefined) {
    if (language === "en") {
      return "en-US";
    } else {
      return "zh-Hant-HK";
    }
  } else return "en-US";
}
