import { IonSearchbar } from "@ionic/react";
import "./style.scss";
import { detect } from "detect-browser";
interface SearchbarProps {
  value: string;
  placeholder?: string;
  className?: string;
  onChange?: (event: CustomEvent) => void;
  onEnter?: any;
}

const Searchbar: React.FC<SearchbarProps> = (props) => {
  const brow = detect();
  console.log("BORSER NAME", brow);
  return (
    <IonSearchbar
      className={`searchbar ${props.className}`}
      placeholder={props.placeholder}
      value={props.value}
      onIonChange={props.onChange}
      inputmode="search"
      onKeyPress={props.onEnter}
      enterkeyhint="search"
      style={{ backgroundColor: "white" }}
    ></IonSearchbar>
  );
};

export default Searchbar;
