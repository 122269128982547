import { useState, useEffect } from "react";
import { isPlatform } from "@ionic/react";

import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Storage } from "@capacitor/storage";
import { Capacitor } from "@capacitor/core";

export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
  data?: string;
}

const PHOTO_STORAGE = "photos";

export function usePhotoGallery() {
  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  // useEffect(() => {
  //   const loadSaved = async () => {
  //     const { value } = await Storage.get({ key: PHOTO_STORAGE });

  //     const photosInStorage = (value ? JSON.parse(value) : []) as UserPhoto[];
  //     // If running on the web...
  //     if (!isPlatform('hybrid')) {
  //       for (const photo of photosInStorage) {
  //         const file = await Filesystem.readFile({
  //           path: photo.filepath,
  //           directory: Directory.Data,
  //         });
  //         // Web platform only: Load the photo as base64 data
  //         photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
  //       }
  //     }
  //     setPhotos(photosInStorage);
  //   };
  //   loadSaved();
  // }, []);

  const savePicture = async (
    photo: Photo,
    fileName: string
  ): Promise<UserPhoto> => {
    let base64Data: string;
    // "hybrid" will detect Cordova or Capacitor;
    if (isPlatform("hybrid")) {
      const file = await Filesystem.readFile({
        path: photo.path!,
      });
      base64Data = file.data;
    } else {
      base64Data = await base64FromPath(photo.webPath!);
    }
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });
    console.log("savedfile", savedFile);
    console.log("base64data", base64Data);
    if (isPlatform("hybrid")) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        filepath: savedFile.uri,
        webviewPath: Capacitor.convertFileSrc(savedFile.uri),
        data: base64Data,
      };
    } else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return {
        filepath: fileName,
        webviewPath: photo.webPath,
        data: base64Data,
      };
    }
  };

  const takePhoto = async (index?: number) => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });

      const fileName = new Date().getTime() + ".jpeg";
      const savedFileImage = await savePicture(cameraPhoto, fileName);
      let newPhotos = [...photos];
      if (index !== undefined) {
        newPhotos[index] = savedFileImage;
      } else {
        newPhotos = [...photos, savedFileImage];
      }
      setPhotos(newPhotos);

      Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });

      return newPhotos;
    } catch (error) {
      console.log("User close camera");
    }
  };

  const deletePhoto = (index: number) => {
    const newPhotos = [...photos].splice(0, index);
    setPhotos(newPhotos);
    Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) });
    return newPhotos;
  };

  return {
    photos,
    takePhoto,
    deletePhoto,
  };
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject("method did not return a string");
      }
    };
    reader.readAsDataURL(blob);
  });
}
