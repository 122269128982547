import {RESET_LIKES, GET_LIKES, RESET_FOLLOW, GET_FOLLOW} from "../../types/LikeTypes";

export const resetLikes = () => ({
    type: RESET_LIKES,
});

export const setLikes = (likes) => ({
    type: GET_LIKES,
    data: likes
});

export const resetFollow = () => ({
    type: RESET_FOLLOW,
});

export const setFollow = (likes) => ({
    type: GET_FOLLOW,
    data: likes
});
