import {
  IonButton,
  IonContent,
  IonGrid,
  IonIcon,
  IonSpinner,
  IonPage,
  IonRow,
  useIonToast,
  useIonViewDidLeave,
  IonItem,
  IonSegment,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonToolbar,
  IonTitle,
  IonLabel,
  IonCol,
  useIonViewDidEnter
} from "@ionic/react";
import { logoGoogle } from "ionicons/icons";
import "./style.scss";
import { useEffect, useState, useRef } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import PasswordInput from "../../components/PasswordInput";
import TextInput from "../../components/TextInput";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import { useMutation } from "react-query";
import { login } from "../../apis/userApis";
import { Storage } from "@capacitor/storage";
import { AxiosError } from "axios";
import { ILoginRequest } from "../../types/UserTypes";
import { setUser } from "../../redux/actions/userActions";
import { store } from "../../redux/store";
import { changeLanguage } from "../../redux/actions/languageAction";
import { message } from "antd-notifications-messages";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { getMyFollowShops, getMyLikedProduct } from "../../apis/LikeApi";
import { setFollow, setLikes } from "../../redux/actions/likeActions";
import { getMe, googleLogin } from "../../apis/AuthApi";
import { useTranslation } from "react-i18next";
import { getLocaleHeaders } from "../../core/baseFunction";


const LoginPage: React.FC<RouteComponentProps> = (props) => {

  const modal = useRef<HTMLIonModalElement>(null);

  const { i18n, t } = useTranslation();
  const history = useHistory();
  const [locale, setLocale] = useState("en");
  const isAuthenticated = () => {
    const token = localStorage.getItem("refresh_token");
    if (token !== "" && token !== undefined && token !== null) {
      if (typeof token === "string") {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        console.log("DECODED TOKEN", decodedToken);
        if (decodedToken.exp * 1000 < Date.now()) {
          console.log("TOKEN IS EXPIRED");
          history.replace("/login");
          localStorage.clear();
          // window.location.pathname = "/login";
        } else {
          refreshToken();
          console.log("TOKEN IS STILL ALIVE");

          history.replace("/home");
        }
      }
    }
  };

  
  const saveSetting = (e) => {
    const locale = e.detail.value;
    
    setLocale(locale);
    store.dispatch(changeLanguage(locale));
    localStorage.setItem("language", locale);
    i18n.changeLanguage(locale);
  };
  const refreshToken = () => {
    const token = localStorage.getItem("refresh_token");
    if (token !== "" && token !== undefined && token !== null) {
      axios
        .post(
          `https://api.merchport.hk/api/auth/refresh`,
          { refresh_token: token },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data) {
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            store.dispatch(setUser(res.data.user));
            Storage.set({ key: "user", value: res.data.user });
            Storage.set({ key: "api_token", value: res.data.access_token });
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log("error", error);
          } else {
            console.log("error", error);
          }
        });
    }
  };

useIonViewDidEnter(() => {

const firstTime = localStorage.getItem("first_time");

if(!firstTime) {
    // first time loaded!
    setModalOpen(true);
    localStorage.setItem("first_time","1");
} 
});
  
  useEffect(() => {
    isAuthenticated();
    // const token = localStorage.getItem("refresh_token");
    // if (token !== "" && token !== undefined && token !== null) {
    //   // history.push("/home");
    //   window.location.pathname = "/home";
    //   // console.log("TOKEN IS here");
    // }
  }, []);

 

  const [account, setAccount] = useState<ILoginRequest>({
    login: "",
    password: "",
  });
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<ILoginRequest>({
    login: "",
    password: "",
  });
  const [present] = useIonToast();

  useIonViewDidLeave(() => {
    setAccount({ login: "", password: "" });
    setIsHidden(true);
    setError({ login: "", password: "" });
  });

  const { isLoading, mutate } = useMutation(login, {
    onSuccess: async (data) => {
      // store api token to storage
      await Storage.set({
        key: "api_token",
        value: data.api.token,
      });
      await Storage.set({
        key: "user",
        value: JSON.stringify(data.data),
      });
      // set user in redux store
      store.dispatch(setUser(data.data));
      getMyLikedProduct().then((e) => {
        if (!e.haveError) {
          store.dispatch(setLikes(e.data));
        }
      });
      props.history.push("/");
    },
    onError: (error: AxiosError) => {
      present({
        duration: 3000,
        color: "danger",
        message: error.response?.data.message,
      });
    },
  });

  const handleInputOnChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.detail.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const tempError = { login: "", password: "" };

    // check emptyness
    if (!account.login) {
      tempError.login = `${t("eremail")}`;
    }
    // else if (account.password) {
    //   mutate(account);
    // }
    // check password
    else if (!account.password) {
      tempError.password = `${t("pasword")}`;
    }
    setError(tempError);
    if (tempError.login?.length <= 0 && tempError.password?.length <= 0) {
      setIsLoading(true);
      const body = {
        login: account.login,
        password: account.password,
      };
      axios
        .post("https://api.merchport.hk/api/auth/login", body, {
          headers: {
            "Content-Type": "application/json",
            local: getLocaleHeaders(),
          },
        })
        .then((res) => {
          if (res.data) {
            setIsLoading(false);
            message({
              type: "success",
              message: t("login_response_success"),
            });
            localStorage.setItem("access_token", res.data.access_token);
            localStorage.setItem("refresh_token", res.data.refresh_token);
            Storage.set({ key: "user", value: res.data.user });
            Storage.set({ key: "api_token", value: res.data.access_token });
            getMe().then((meData) => {
              if (!meData.haveError) {
                getMyFollowShops().then((e) => {
                  if (!e.haveError) {
                    store.dispatch(setFollow(e.data));
                  }
                });
                getMyLikedProduct().then((e) => {
                  if (!e.haveError) {
                    store.dispatch(setUser(meData.data));
                    store.dispatch(setLikes(e.data));
                    const path = "/home";
                    history.push(path);
                  }
                });
              }
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            setIsLoading(false);
            message({
              type: "error",
              // message: `${error.response.data.message}`,
              message: t("login_response_error", {
                Errormessage: `${error.response.data.message}`,
              }), //dynamic tranlation
            });
          } else {
            setIsLoading(false);
            message({
              type: "error",
              message: t("network_Error"),
            });
          }
        });
    }
  };

  return (

    
    <NoTabBarLayout>
      <IonPage className="auth-page">


        <IonContent fullscreen>

        <IonModal isOpen={modalOpen} cssClass="auto-height" ref={modal} >
        <div className="inner-content">
      
            <IonToolbar>
              <h3>歡迎使用Merchport! 💛 </h3>
              <hr></hr>
              <p className="ion-padding">你將會進入Merchport測試版本，但部分功能有機會唔太穩定，敬請原諒 🙏🏼</p><p className="ion-padding">係呢段期間, 如遇到任何異常或有其他意見, 歡迎透過IG (@merchporthk) 或者 Telegram(@hkmerchport)聯絡我哋，讓大家之後能置身喺更完善嘅平台中 ☺️</p><p className="ion-padding">接下嚟，就請你踏上開創自己品牌嘅道路啦! </p>
            </IonToolbar>
           

            <IonRow class="ion-align-items-center">
              <IonCol size="12" class="ion-text-center">
            <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    type="submit"
              
                    mode="ios"
                    onClick={() => {  modal.current?.dismiss();}}
                  >Continue</IonButton>
              </IonCol>
            </IonRow>

            </div>
         
        </IonModal>

        <IonItem lines="none">
         
        <IonSelect 
        slot="end"
        placeholder="Select Language"
        cancelText="Cancel"
        okText="OK"
        onIonChange={saveSetting}

        >
          
         <IonSelectOption value="en">English</IonSelectOption>
         <IonSelectOption value="ch">中文</IonSelectOption>
         </IonSelect>

          
        </IonItem>

          <IonGrid>
            <IonRow>
              <div className="ion-text-center" style={{width:'70%'}}>
                <p className="auth-heading">{t("welcome")}</p>
                <p color="secondary" className="auth-slogan">
                  {t("enteracc")}
                </p>
                <form onSubmit={handleLogin}>
                  <TextInput
                    placeholder={t("email")}
                    value={account.login}
                    error={error.login}
                    clearInput
                    onChange={handleInputOnChange}
                    inputmode="text"
                    name="login"
                    disabled={isLoading}
                  />
                  <PasswordInput
                    placeholder={t("pass")}
                    value={account.password}
                    isHidden={isHidden}
                    error={error.password}
                    onChange={handleInputOnChange}
                    onClick={() => setIsHidden(!isHidden)}
                    name="password"
                    disabled={isLoading}
                  />
                  <div className="ion-text-right ion-padding-bottom">
                    <Link to="/forgot-password" className="text-color-grey">
                      {t("fg")}
                    </Link>
                  </div>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    type="submit"
                    className="auth-button"
                    mode="ios"
                    disabled={loading}
                  >
                    {t("loginbt")}{" "}
                    {loading && (
                      <IonSpinner
                        name="circles"
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </IonButton>
                </form>
                {/* <p className="text-color-grey-lightest ion-padding">
                  {t("contn")}
                </p>
                <IonButton
                  color="medium"
                  fill="outline"
                  shape="round"
                  expand="block"
                  onClick={() => {
                    googleLogin().then((e) => {
                      console.log(e);
                    });
                  }}
                  className="auth-button"
                  mode="ios"
                >
                  <IonIcon
                    icon={logoGoogle}
                    className="ion-padding-end"
                  ></IonIcon>
                  Google
                </IonButton> */}
                <p className="ion-padding text-color-grey-title text-smaller">
                  {t("dont")}&nbsp;
                  <Link
                    to="/signup"
                    className="text-bold text-color-grey-lighter"
                  >
                    <span> {t("signup")}</span>
                  </Link>
                </p>
                <Link
                  to="/"
                  onClick={() => {
                    localStorage.setItem("allow", "yes");
                  }}
                  className="text-bold text-color-grey-lighter pd-30"
                >
                  <span className={"pd-30"}>{t("continwithout")}</span>
                </Link>
              </div>
            </IonRow>
          </IonGrid>
          
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default withRouter(LoginPage);
