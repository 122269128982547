import { IonItem } from '@ionic/react';
import React, { useState } from 'react';
import './style.scss';
import IconButton from '../IconButton';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
interface CollapsibleItemProps {
  title: string;
  children?: any;
  defaultExpanded?: boolean;
}

const CollapsibleItem: React.FC<CollapsibleItemProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(props.defaultExpanded || false);

  return (
    <div className="collapisible-item">
      <IonItem
        lines="none"
        className="ion-no-padding"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <p className="subtitle-medium-12 ion-text-capitalize">{props.title}</p>
        <IconButton
          className="ion-no-padding"
          slot="end"
          iconProps={{
            icon: isExpanded ? chevronUpOutline : chevronDownOutline,
          }}
          color="dark"
          fill="clear"
        />
      </IonItem>
      {isExpanded && <>{props.children}</>}
    </div>
  );
};

export default CollapsibleItem;
