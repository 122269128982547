import { message } from "antd-notifications-messages";

export const errorMessage = (st: string) => {
  message({
    type: "error",
    message: st,
    position: "topCenter"
  });
};
export const successMessage = (st: string) => {
  message({
    type: "success",
    message: st,
    position: "topCenter",
  });
};
