import {
  IonActionSheet,
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonToolbar,
  useIonViewDidEnter,
  IonSpinner,
  IonHeader
} from "@ionic/react";
import { Lightbox } from "react-modal-image";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { message } from "antd-notifications-messages";
import { useTranslation } from "react-i18next";

import {
  checkmarkCircle,
  chevronBackOutline,
  heart,
  shareOutline,
  trash,
  share,
  caretForwardCircle,
  copyOutline,
  close,
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Link, useHistory } from "react-router-dom";
import Carousel from "../../components/Carousel";
import CollapsibleItem from "../../components/CollapsibleItem";
import CustomTruncate from "../../components/CustomTruncate";
import IconButton from "../../components/IconButton";
import ImageWrapper from "../../components/ImageWrapper";
import RatingStars from "../../components/RatingStars";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import "./style.scss";
import { getProductById, getProductReviewsById } from "../../apis/productApis";
import { checkProductLike } from "../../redux/functions/product";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  getMyLikedProduct,
  removeLikeProduct,
  setLikeProduct,
} from "../../apis/LikeApi";
import { store } from "../../redux/store";
import { setLikes } from "../../redux/actions/likeActions";
import { Browser } from "@capacitor/browser";
import { errorMessage } from "../../core/popups";
import { ArrayLength } from "../../core/baseFunction";
import Wrapper from "../../components/Wrapper";
export const SampleData = {
  id: "",
  createdAt: "2022-08-05T03:31:18.118Z",
  updatedAt: "2022-08-05T03:31:18.119Z",
  name: "",
  description: "",
  paymentDetails: "",
  shippingDetails: "",
  price: "",
  categories: [
    {
      id: "",
      createdAt: "2021-10-02T14:20:02.992Z",
      updatedAt: "2022-07-15T12:34:19.651Z",
      name: "",
      description: "",
      backgroundImageFileId: null,
      shortLabel: null,
    },
  ],
  images: [
    {
      location: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/shoes.jpg",
    },
  ],
  shops: [
    {
      id: "",
      shopId: "",
      createdAt: "2022-08-05T05:57:28.894Z",
      productId: "",
      assignedByUserId: "",
      shop: {
        id: "",
        createdAt: "2022-07-27T08:09:43.607Z",
        updatedAt: "2022-07-27T08:09:43.608Z",
        name: "",
        foundDate: "2022-07-27T08:09:14.949Z",
        description: "",
        iconFileId: "",
        ownerUserId: "",
        contactMethods: "",
        warranty: "yes",
        icon: {
          location:
            "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
        },
        _count: {
          productPhotos: 0,
          categories: 0,
          products: 0,
          views: 0,
          comments: 0,
          bookmarks: 0,
          followers: 0,
        },
      },
    },
  ],
  _count: {
    images: 0,
    views: 0,
    comments: 0,
    bookmarks: 0,
    categories: 0,
    shops: 0,
  },
};
export type SampleReviewType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  content: string;
  productId: string;
  rating: number;
  user: {
    profile: {
      avatar: {
        location: string;
      };
      name: string;
    };
  };
  additionalPhotos: [
    {
      location: string;
    }
  ];
};
// eslint-disable-next-line prefer-const
interface MatchParams {
  id: string;
}
type Props = RouteComponentProps<MatchParams>;
const ProductPage: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();
  const footerRef = useRef<any>(null);
  const [role, setRole] = useState("customer");
  const [shopId, setShopId] = useState("");
  const [footerHeight, setFooterHeight] = useState(0);
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [shareopen, setShareOpen] = useState(false);
  const [contect, setContect] = useState<{ key: string; value: string }[]>([]);
  const [exCount, setExCount] = useState(0);
  const state = useSelector((s: RootState) => s.like);
  const user = useSelector((s: RootState) => s.user);
  const [data, setData] = useState(SampleData);
  const id = props.match.params.id;
  const historyReact = useHistory();
  const [reviews, setReviews] = useState<SampleReviewType[]>([]);
  const [isFav, setIsFav] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imgarr, setImgArr] = useState([{}]);
  const history = useHistory();
  interface img {
    original: string;
    thumbnail: string;
  }
  // const arr: img[] = [];
  useIonViewDidEnter(() => {
    getProductById(id).then((e) => {
      if (!e.haveError) {
        const arr: img[] = [];
        setLoading(false);
        setData(e.data);

        e.data?.images.forEach((item, i) => {
          arr.push({
            original: item ? item?.location : "",
            thumbnail: item ? item?.location : "",
          });
        });
        console.log("ARRAY OF IMAGES", arr);
        setImgArr(arr);
        setIsFav(checkProductLike(id, state.product));
        if (user.user?.account_type == "shop") {
          if (e?.data?.ownerUserId === (user?.user?.id || "")) {
            setRole("shop");
          }
        }
        if (
          Array.isArray(e.data.shops) &&
          e.data.shops.length > 0 &&
          e.data.shops[0]?.shop?.contactMethods &&
          e.data.shops[0]?.shop?.contactMethods != ""
        ) {
          setContect(JSON.parse(e.data.shops[0]?.shop?.contactMethods));
        }
        if (ArrayLength(e.data.shops)) {
          setShopId(e.data.shops[0].shop.id);
        }
      } else {
        setLoading(false);
      }
    });
    getProductReviewsById(id).then((e) => {
      if (!e.haveError) {
        setReviews(e.data);
      }
    });
    setFooterHeight(footerRef?.current?.offsetHeight);
  });
  const handleContactOnClick = () => {
    setIsActionSheetOpen(true);
  };

  const handleEditOnClick = () => {
    historyReact.push(`/product/create?id=${shopId}&productId=${id}`);
  };

  const handleFavOnClick = () => {
    if (user.user == null) {
      message({
        type: "error",
        message: t("lgrcom"),
      });

      // errorMessage("Login/ Register to add this product to your favourites");
      setTimeout(() => {
        history.push("/login");
      }, 2000);
    }
    if (isFav) {
      removeLikeProduct(data.id).then((e) => {
        if (!e.haveError) {
          setIsFav(false);
          if (exCount == 0) {
            setExCount(-1);
          } else {
            setExCount(0);
          }
          getMyLikedProduct().then((e) => {
            if (!e.haveError) {
              store.dispatch(setLikes(e.data));
            }
          });
        }
      });
    } else {
      setLikeProduct(data.id).then((e) => {
        if (!e.haveError) {
          setIsFav(true);
          if (exCount == 0) {
            setExCount(1);
          } else {
            setExCount(0);
          }
          getMyLikedProduct().then((e) => {
            if (!e.haveError) {
              store.dispatch(setLikes(e.data));
            }
          });
        }
      });
    }
  };
  const handleShare = () => {
    setShareOpen(true);
    console.log("CLICKED");
  };
  const renderCollapsibleContent = (title: string, content: string) => {
    return (
      <div className="content text-color-grey-lighter">
        {content && content.length > 0 ? (
          <CustomTruncate text={content} />
        ) : (
          `No ${title}`
        )}
      </div>
    );
  };
  const images = [
    {
      original:
        "https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      thumbnail:
        "https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    },
    {
      original:
        "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=700&q=60",
      thumbnail:
        "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=700&q=60",
    },
  ];
  // console.log("AS", imgarr);
  const [toggle, setToggle] = useState(false);
  const [url, setUrl] = useState("");
  const handleToggle = (url: string) => {
    console.log("URL", url);
    setToggle(true);
    setUrl(url);
  };
  const closeModal = () => {
    setToggle(false);
    setUrl("");
  };
  return (
    <NoTabBarLayout>
      <IonPage className="product-page">
     
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/"
              color="light"
              text=""
              icon={chevronBackOutline}
              mode="ios"
            />
          </IonButtons>
          <IonButtons slot="end">
            <IconButton
              iconProps={{ icon: shareOutline }}
              onClick={handleShare}
              color="light"
            />
          </IonButtons>
         
        </IonToolbar>
       
        {toggle ? (
          <Lightbox
            // alt=""
            // hideAlt={true}
            hideDownload={true}
            large={url}
            onClose={closeModal}
          />
        ) : (
          <>
            <IonContent style={{ height: `calc(100% - ${footerHeight}px)` }}>
              {loading ? (
                <div className="spinner-container">
                  <IonSpinner name="crescent" color="primary" />
                </div>
              ) : (
                <>
                  {data?.images?.length > 0 ? (
                    <Carousel
                      itemsToShow={1}
                      itemHeight={340}
                      isFullWidth
                      showIndicators
                    >
                      {data.images.map((image, index) => (
                        <Wrapper
                          src={image.location}
                          className="product-image"
                          key={index}
                          handleClick={() => handleToggle(image.location)}
                        />
                        // <ImageWrapper
                        //   src={image.location}
                        //   className="product-image"
                        //   key={index}
                        //   // handleClick={handleToggle}
                        // />
                      ))}
                    </Carousel>
                  ) : (
                    // <ImageGallery
                    //   showNav={false}
                    //   showThumbnails={true}
                    //   showPlayButton={false}
                    //   autoPlay={true}
                    //   disableSwipe={true}
                    //   renderFullscreenButton={(onClick, isFullscreen) => (
                    //     <div
                    //       onClick={onClick}
                    //       style={{
                    //         position: "absolute",
                    //         color: "transparent",
                    //         cursor: "context-menu",
                    //         userSelect: "none",

                    //         width: "100%",

                    //         left: 0,
                    //         right: 0,
                    //         bottom: 0,
                    //         top: 0,
                    //       }}
                    //     >
                    //       Full screen{" "}
                    //     </div>
                    //   )}
                    //   items={imgarr}
                    // />
                    // <Carousel
                    //   itemsToShow={1}
                    //   itemHeight={340}
                    //   isFullWidth
                    //   showIndicators
                    // >
                    //   {data.images.map((image, index) => (
                    //     <ImageWrapper
                    //       src={image.location}
                    //       className="product-image"
                    //       key={index}
                    //     />
                    //   ))}
                    // </Carousel>
                    <div
                      className="ion-padding ion-text-center ion-justify-content-center text-color-grey-lighter product-image"
                      style={{
                        boxShadow: "0 0 3px var(--ion-color-grey-lightest)",
                      }}
                    >
                      <div>{t("noproductImage")}</div>
                    </div>
                  )}
                </>
              )}
              <div className="product-details">
                <IonGrid className="ion-justify-content-between ion-align-items-center ion-no-padding">
                  <IonRow>
                    <IonCol size="9">{data?.name}</IonCol>
                    <IonCol size="3" className="ion-text-right">
                      <IonIcon
                        icon={heart}
                        size="small"
                        color={isFav ? "primary" : "medium"}
                        className="heart-icon"
                      ></IonIcon>
                      <span
                        className={
                          (isFav ? "yellow-color" : "") + " fav-number"
                        }
                      >
                        {(data?._count?.bookmarks || 0) + exCount}
                      </span>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <p className="price"> ${data.price}</p>
                <RatingStars
                  rating={
                    Array.isArray(reviews)
                      ? reviews.reduce((total, count) => {
                          return total + count.rating;
                        }, 0) / reviews.length
                      : 0
                  }
                />
                <Link to={`/shop/${data.id}/reviews`}>
                  <span className="text-color-grey-lightest reviews-link">
                    ({t("view")} {Array.isArray(reviews) ? reviews.length : 0}{" "}
                    {t("review")})
                  </span>
                </Link>

                <p className="subtitle-medium-12">{t("owner")}</p>
                <div className="shop-container">
                  <IonItem lines="none" className="ion-no-padding ">
                    <IonAvatar
                      // onClick={handleToggle}
                      slot="start"
                      className="shop-avatar"
                    >
                      <img
                        src={
                          Array.isArray(data?.shops)
                            ? data.shops[0].shop.icon.location || ""
                            : ""
                        }
                      />
                    </IonAvatar>
                    <IonLabel>
                      <h2 className="text-bold text-color-grey shop-name">
                        {Array.isArray(data?.shops)
                          ? data?.shops[0]?.shop?.name || ""
                          : ""}{" "}
                        <IonIcon
                          icon={checkmarkCircle}
                          className="checkmark"
                          color={"primary"}
                        ></IonIcon>
                      </h2>
                      <p className="text-color-grey shop-followers">
                        {Array.isArray(data?.shops)
                          ? data?.shops[0]?.shop?._count?.followers || 0
                          : 0}{" "}
                        {t("follower")}
                      </p>
                    </IonLabel>
                    <Link
                      to={`/shop/${
                        Array.isArray(data?.shops)
                          ? data?.shops[0]?.shop?.id || ""
                          : ""
                      }`}
                    >
                      <IonButton
                        slot="end"
                        fill="outline"
                        color="primary"
                        mode="ios"
                      >
                        {t("enterStore")}
                      </IonButton>
                    </Link>
                  </IonItem>
                </div>

                <CollapsibleItem title={t("description")} defaultExpanded>
                  {renderCollapsibleContent("Description", data.description)}
                </CollapsibleItem>
                <CollapsibleItem title={t("shippingDetail")}>
                  {renderCollapsibleContent(
                    "Shipping Details",
                    data.shippingDetails
                  )}
                </CollapsibleItem>
                <CollapsibleItem title={t("paymentDetail")}>
                  {renderCollapsibleContent(
                    "Payment Details",
                    data.paymentDetails
                  )}
                </CollapsibleItem>
              </div>
            </IonContent>

            <IonFooter className="ion-no-border footer" ref={footerRef}>
              <IonToolbar>
                {role !== "shop" && (
                  <IconButton
                    className="like-button ion-margin"
                    iconProps={{
                      icon: heart,
                      color: isFav ? "primary" : "medium",
                    }}
                    color="light"
                    fill="solid"
                    slot="start"
                    onClick={() => {
                      handleFavOnClick();
                    }}
                  />
                )}
                <IonButton
                  color="primary"
                  fill="solid"
                  expand="block"
                  className="ion-margin contact-button"
                  mode="ios"
                  onClick={
                    role === "shop" ? handleEditOnClick : handleContactOnClick
                  }
                >
                  {role === "shop"
                    ? t("EditProductDetail")
                    : t("contactSeller")}
                </IonButton>
                <IonActionSheet
                  mode="ios"
                  isOpen={isActionSheetOpen}
                  onDidDismiss={() => setIsActionSheetOpen(false)}
                  buttons={[
                    ...contect.map((e) => ({
                      text: e.key,
                      role: e.key,
                      handler: () => {
                        Browser.open({
                          url: e.value,
                        });
                      },
                      cssClass: "text-color-grey",
                    })),
                    {
                      text: t("Cancel"),
                      role: "cancel",
                      cssClass: "text-color-grey",
                    },
                  ]}
                ></IonActionSheet>
                <IonActionSheet
                  isOpen={shareopen}
                  mode="ios"
                  onDidDismiss={() => setShareOpen(false)}
                  // cssClass="my-custom-class"
                  buttons={[
                    {
                      text: "Copy link to product",

                      icon: copyOutline,
                      cssClass: "text-color-grey",
                      handler: async () => {
                        await navigator.clipboard.writeText(
                          window.location.href
                        );
                        message({
                          type: "success",
                          message: `Copied`,
                        });
                        // alert("Text copied");
                        // console.log("Delete clicked");
                      },
                    },

                    {
                      text: "Cancel",
                      icon: close,
                      role: "cancel",
                      cssClass: "text-color-grey",
                      handler: () => {
                        console.log("Cancel clicked");
                      },
                    },
                  ]}
                ></IonActionSheet>
              </IonToolbar>
            </IonFooter>
          </>
        )}
      </IonPage>
    </NoTabBarLayout>
  );
};

export default ProductPage;
