import {ADD_LIKE, GET_FOLLOW, GET_LIKES, REMOVE_LIKE, RESET_LIKES} from "../../types/LikeTypes";

const initialState: State = {
    shop: [],
    product: [],
    error: '',
};

export const likeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_LIKES:
            return { ...state, product: action.data, error: '' };
        case RESET_LIKES:
            return { ...state, product: [], shop: [], error: '' };
        case GET_FOLLOW:
            return { ...state, shop: action.data, error: '' };
        default:
            return state;
    }
};

type State = {
    shop: IShopFollow[],
    product: IProductLike[],
    error: string,
}

export interface IProductLike  {
    id: string,
    userId: string,
    productId: string,
    createdAt: string,
    product: {
        id: string,
        createdAt: string,
        updatedAt: string,
        name: string,
        description: string,
        price: string,
        images: [
            {
                location: string
            }
        ]
        shippingDetails: null,
        paymentDetails: null,
        ownerUserId: string
    }
}
export interface IShopFollow  {
    id: string,
    userId: string,
    shopId: string,
    createdAt: string,
    shop: {
        id: string,
        "createdAt": string,
        "updatedAt": string,
        "name": string,
        "foundDate": string,
        "description": string,
        "iconFileId": string,
        "contactMethods": string,
        "warranty": string
        "verified": boolean
        "ownerUserId": string
    }
}