import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { useContext } from 'react';
import UIContext from './UIContext';

export const NoTabBarLayout: React.FC = ({ children }) => {
	const { setShowTabBar } = useContext(UIContext);

	useIonViewWillEnter(() => {
		setShowTabBar(false);
	});

	useIonViewWillLeave(() => {
		setShowTabBar(true);
	});

	return <>{children}</>;
};

export default NoTabBarLayout;
