import {ServerApi} from "../core/api";

const api = new ServerApi();

export const refreshToken = async () => {
  return await api.refreshToken();
};

export const sendOtp = async () => {
  return await api.sendOtp();
};

export const verifyOtp = async (otp: string) => {
  return await api.verifyOtp(otp);
};

export const googleLogin = async () => {
  return await api.googleLogin();
};

export const getMe = async () =>{
  return api.getMe();
};