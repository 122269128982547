import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import "./style.scss";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import Textarea from "../../components/Textarea";
import { useTranslation } from "react-i18next";

const MAX_LEN = 140;

const about = {
  payment:
    "Lorem ipsum dolor sit amet, condimentum nonummy sagittis aptent pede lobortis, hendrerit eget erat. Vestibulum vel adipiscing quisque consectetuer malesuada, tempus ullamcorper lectus molestie cursus. Sit in ut mollis nullam rhoncus mus.  ",
  shipping: "Lor",
  termsConditions:
    "Lorem ipsum dolor sit amet, condimentum nonummy sagittis aptent pede lobortis, hendrerit eget erat. Vestibulum vel adipiscing quisque consectetuer malesuada, tempus ullamcorper lectus molestie cursus. Sit in ut mollis nullam rhoncus mus.  ",
};

const EditAboutUsPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const [content, setContent] = useState<any>(null);

  useIonViewDidEnter(() => {
    setContent(about);
  });

  const handleConfirm = (e) => {
    e.preventDefault();
    alert(JSON.stringify(content));
  };

  const handleInputOnChange = (e) => {
    setContent({ ...content, [e.target.name]: e.detail.value! });
  };

  return (
    <NoTabBarLayout>
      <IonPage className="edit-about-us-page">
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="profile"
              icon={chevronBackOutline}
              text=""
              color="dark"
              mode="ios"
            />
          </IonButtons>
          <IonTitle>{t("aboutUs")}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              fill="clear"
              color="primary"
              mode="ios"
              onClick={handleConfirm}
            >
              {t("confirm")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        </IonHeader>
        <IonContent>
          <p className="text-color-grey-lighter ion-text-center form-description-14">
            {t("fillInThisForm")}
          </p>

          {content && (
            <form>
              <p className="subtitle-medium-14 ion-no-margin">
                {t("shippingDetail")}
              </p>
              <Textarea
                name="shipping"
                value={content.shipping}
                onChange={handleInputOnChange}
                maxlength={MAX_LEN}
              ></Textarea>

              <p className="subtitle-medium-14 ion-no-margin">
                {t("paymentDetail")}
              </p>
              <Textarea
                name="payment"
                value={content.payment}
                onChange={handleInputOnChange}
                maxlength={MAX_LEN}
              ></Textarea>

              <p className="subtitle-medium-14 ion-no-margin">
                {t("Conditions")}
              </p>
              <Textarea
                name="shipping"
                value={content.termsConditions}
                onChange={handleInputOnChange}
                maxlength={MAX_LEN}
              ></Textarea>
            </form>
          )}
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default EditAboutUsPage;
