import { IonInput, IonItem } from '@ionic/react';
import { eye } from 'ionicons/icons';
import ErrorNote from '../ErrorNote';
import IconButton from '../IconButton';
import './style.scss';

interface PasswordInputProps {
  name?: string;
  placeholder: string;
  value: string;
  isHidden: boolean;
  error?: string;
  className?: string;
  disabled?: boolean;
  onChange?: (event: CustomEvent) => void;
  onClick?: () => void;
}

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  return (
    <div className={`input-container ${props.className}`}>
      <IonItem className="input" lines="none">
        <IonInput
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onIonChange={props.onChange}
          type={props.isHidden ? 'password' : 'text'}
          inputmode="text"
          disabled={props.disabled}
        ></IonInput>
        <span className="show-button">
          <IconButton
            slot="end"
            fill="clear"
            shape="round"
            color={props.isHidden ? 'medium' : 'primary'}
            onClick={props.onClick}
            iconProps={{ icon: eye }}
          />
        </span>
      </IonItem>
      {props.error && <ErrorNote error={props.error} />}
    </div>
  );
};

export default PasswordInput;
