import React, { useState } from "react";
import "./style.scss";

/***************************************************************************
 * Section Tab Buttons
 **************************************************************************/

const SectionTabButtons = (props) => {
  return (
    <div className="section-tab-buttons">
      {props.buttons.map((button, index) => {
        return (
          <button
            key={index}
            className={`section-tab-button ion-text-capitalize ${
              button === props.activeTab && "active"
            }`}
            onClick={() => props.onChange(button)}
          >
            {button}
          </button>
        );
      })}
    </div>
  );
};

/***************************************************************************
 * Section Tabs
 **************************************************************************/

interface SectionTabsProps {
  children: any;
}

const SectionTabs: React.FC<SectionTabsProps> = (props) => {
  let content;
  const buttons: any = [];

  const [activeTab, setActiveTab] = useState(props.children[0].props.label);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="section-tabs">
      {React.Children.map(props.children, (child: any) => {
        buttons.push(child.props.label);
        if (child.props.label === activeTab) content = child.props.children;
      })}

      <SectionTabButtons
        activeTab={activeTab}
        buttons={buttons}
        onChange={changeTab}
      />
      <div className="tab-content">{content}</div>
    </div>
  );
};

export default SectionTabs;
