import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router-dom";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import "./style.scss";
import { useTranslation } from "react-i18next";

const TermsAndConditions: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <NoTabBarLayout>
      <IonPage className="tos-page">
      <IonHeader>

        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref=""
              icon={chevronBackOutline}
              text={t("backToHome")}
              color="medium"
              mode="ios"
            />
          </IonButtons>
        </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonText className={"center"} color="medium">
            <h5 color={"medium"}>{t("title1")}</h5>
            <IonText>{t("content1")}</IonText>
            <h5>{t("title2")}</h5>
            <IonText> {t("content2")}</IonText>
          </IonText>
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default TermsAndConditions;
