import { languageReducer } from "./languageReducer";
import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { likeReducer } from "./likeReducer";

export const rootReducer = combineReducers({
  user: userReducer,
  like: likeReducer,
  lang: languageReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
