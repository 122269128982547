import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux/reducers';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  path: string;
}

const mapStateToProps = (state: RootState) => ({
  user: state.user.user,
});

const connector = connect(mapStateToProps);
type PropsType = ConnectedProps<typeof connector> & ProtectedRouteProps;

const ProtectedRoute: React.FC<PropsType> = ({ component: Component, path, user }) => {
  return <Route path={path} render={(props) => (user ? <Component {...props} /> : <Redirect to="/login" />)} exact />;
};

export default connector(ProtectedRoute);
