import { IonInput, IonItem } from "@ionic/react";
import ErrorNote from "../ErrorNote";
import "./style.scss";

interface TextInputProps {
  name?: string;
  placeholder?: string;

  value: null | number | string | undefined;
  clearInput?: boolean;
  error?: string;
  className?: string;
  maxlength?: number | undefined;
  inputmode?:
    | "decimal"
    | "email"
    | "none"
    | "numeric"
    | "search"
    | "tel"
    | "text"
    | "url"
    | undefined;
  type?:
    | "date"
    | "datetime-local"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  isPrice?: boolean;
  disabled?: boolean;
  onChange?: (event: CustomEvent) => void;
  // onKeyPress?: (event: CustomEvent) => void;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  return (
    <div className={`input-container ${props.className}`}>
      <IonItem className="input" lines="none">
        {props.isPrice && <span>$&nbsp;</span>}
        <IonInput
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onIonChange={props.onChange}
          clearInput={props.clearInput}
          maxlength={props.maxlength}
          inputmode={props.inputmode}
          type={props.type}
          disabled={props.disabled}
          // onKeyPress={props.onKeyPress}
        ></IonInput>
      </IonItem>
      {props.error && <ErrorNote error={props.error} />}
    </div>
  );
};

export default TextInput;
