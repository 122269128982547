import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonHeader
} from "@ionic/react";
import {
  callOutline,
  checkmarkCircle,
  chevronBackOutline,
  settingsOutline,
} from "ionicons/icons";
// import { detect } from "detect-browser";
import { useEffect, useState } from "react";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { getProductsByStoreId, searchProducts } from "../../apis/productApis";
import { getUserProfile } from "../../apis/userApis";
import CustomTruncate from "../../components/CustomTruncate";
import IconButton from "../../components/IconButton";
import SectionTab from "../../components/SectionTab";
import SectionTabs from "../../components/SectionTabs";
import ShopAboutTab from "../../components/ShopAboutTab";
import ShopProductsTab from "../../components/ShopProductsTab";
import ShopReviewsTab from "../../components/ShopReviewsTab";
import { RootState } from "../../redux/reducers";
import "./style.scss";
import { getShopById } from "../../apis/shopApi";
import { TProduct } from "../CategoryPage";
import { checkShopFollow } from "../../redux/functions/product";
import {
  getMyFollowShops,
  removeFollowShop,
  setFollowShop,
} from "../../apis/LikeApi";
import { store } from "../../redux/store";
import { setFollow } from "../../redux/actions/likeActions";
import { errorMessage } from "../../core/popups";
import { userType } from "../../redux/reducers/userReducer";
import { useTranslation } from "react-i18next";

interface MatchParams {
  username: string;
}
const ShopDefault = {
  id: "",
  createdAt: "2022-07-27T08:09:43.607Z",
  updatedAt: "2022-07-27T08:09:43.608Z",
  name: "",
  foundDate: "2022-07-27T08:09:14.949Z",
  description: "",
  iconFileId: "",
  ownerUserId: "",
  contactMethods: "",
  owner: {
    profile: {
      displayName: "",
    },
    account: {
      phoneNumber: "",
    },
  },
  verified: false,
  warranty: "",
  icon: {
    location:
      "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
  },
  _count: {
    productPhotos: 0,
    categories: 0,
    products: 0,
    views: 0,
    comments: 0,
    bookmarks: 0,
    followers: 0,
  },
};
type Props = RouteComponentProps<MatchParams>;
const ShopProfilePage: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();
  const [isSearch, setIsSearch] = useState(false);
  const [isScrollDown, setIsScrollDown] = useState(false);
  const [filter, setFilter] = useState({ price_max: 100000, price_min: 0 });
  const [query, setQuery] = useState("");
  const [isShopOwner, setIsShopOwner] = useState(userType[0]);
  const [isEnabled, setIsEnabled] = useState(false);
  const state = useSelector((s: RootState) => s.like);
  const [shop, setShop] = useState(ShopDefault);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<TProduct[]>([]);
  const [limit, setlimit] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);
  const id = props.match.params.username;

  // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  const [isFav, setIsFav] = useState(checkShopFollow(id, state.shop));
  const { data: profile } = useQuery(
    "getStoreProfile",
    () => getUserProfile(user?.id ?? ""),
    {
      onSettled: () => setIsEnabled(true),
    }
  );
  useEffect(() => {
    setIsFav(checkShopFollow(props.match.params.username, state.shop));
  }, [state.shop]);
  useIonViewWillEnter(() => {
    setIsSearch(false);
    getShopById(id).then((e) => {
      if (!e.haveError) {
        setShop(e.data);
        if (user?.account_type == "shop") {
          if (e?.data?.ownerUserId === (user?.id || "")) {
            
            setIsShopOwner("shop");
          }
        }
      }
    });
    getProductsByStoreId(id, 0).then((e) => {
      if (!e.haveError) {
        setProducts(e.data);
      }
      setLoading(false);
    });
  });
  const getData = () => {
    // console.log("ABC");
    setLoading(true);
    getProductsByStoreId(id, 0).then((e) => {
      if (!e.haveError) {
        setProducts(e.data);
      }
      setLoading(false);
    });
  };
  const getDatanext = (ev) => {
    searchProducts({ startFrom: limit + 10, filter: filter, q: query }).then(
      (e) => {
        ev.target.complete();
        if (!e.haveError) {
          setProducts([...products, ...e.data]);
          if (e.data.length < 10) {
            setIsLoad(true);
          } else {
            setlimit(limit + 10);
          }
        } else {
          setProducts([]);
        }
      }
    );
  };
  const onFilter = (e) => {
    // console.log("EE", e);
    setLoading(true);
    setFilter(e);
    searchProducts({
      filter: e,
      q: query,
      categoryId: undefined,
      startFrom: 0,
      storeId: id,
      scope: "products",
    }).then((a) => {
      if (!a.haveError) {
        setProducts(a.data);
      }
      setLoading(false);
    });
  };
  const onQuery = (e) => {
    setLoading(true);
    setQuery(e);
    searchProducts({
      filter: filter,
      q: e,
      categoryId: undefined,
      startFrom: 0,
      storeId: id,
      // scope: "products",
    }).then((a) => {
      if (!a.haveError) {
        setProducts(a.data);
      }
      setLoading(false);
    });
  };
  const handlefollow = async () => {
    if (user != null) {
      const fun = isFav ? removeFollowShop : setFollowShop;
      fun(id).then((e) => {
        if (!e.haveError) {
          getMyFollowShops().then((e) => {
            if (!e.haveError) store.dispatch(setFollow(e.data));
          });
        }
      });
    } else {
      errorMessage(t("lgrshp"));
    }
  };

  const handleScroll = async (e) => {
    const scrollPosition = e.detail.scrollTop;
    if (scrollPosition < 30) {
      setIsScrollDown(false);
    } else {
      setIsScrollDown(true);
    }
  };

  return (
    <NoTabBarLayout>
      <IonPage className="shop-profile-page" id="shop-profile-page">
        <IonToolbar
          style={isShopOwner !== "shop" ? {} : { marginTop: "-20px" }}
        >
          {isShopOwner !== "shop" && (
            <IonButtons slot="start" className="ion-margin">
              <IonBackButton
                defaultHref=""
                icon={chevronBackOutline}
                color="dark"
                text=""
                mode="ios"
              />
            </IonButtons>
          )}
          {isScrollDown && <IonTitle>{shop.name}</IonTitle>}
          <IonButtons slot="end" className="ion-margin">
            <IonBackButton
              icon={chevronBackOutline}
              color="dark"
              text=""
              mode="ios"
            />
            {isShopOwner === "shop" && (
              <IconButton
                className="default-icon-button"
                fill="clear"
                shape="round"
                color="medium"
                onClick={() => props.history.push("/owner-settings")}
                iconProps={{ icon: settingsOutline }}
              />
            )}
          </IonButtons>
        </IonToolbar>
        <IonContent
          scrollEvents
          onIonScroll={handleScroll}
          id="shop-profile-content"
        >
          <div style={{}} className="profile-container">
            <IonItem lines="none">
              <IonAvatar slot="start" className="profile-avatar">
                <img
                  src={
                    shop.icon.location ||
                    "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
                  }
                />
              </IonAvatar>
              <IonLabel>
                <h2 className="text-bold">
                  {shop.name}{" "}
                  {shop.verified && (
                    <IonIcon
                      icon={checkmarkCircle}
                      className="checkmark"
                      color="primary"
                    ></IonIcon>
                  )}
                </h2>
                <p className="text-color-grey-lighter">
                  @{shop?.owner?.profile?.displayName || "No UserName"}
                </p>
                <p className="text-color-grey-title">
                  {shop._count.products} {t("product")} |{" "}
                  {shop._count.followers} {t("followers")}
                </p>
              </IonLabel>
            </IonItem>
            <div className="profile-buttons">
              {isShopOwner === "shop" ? (
                <Link to="/profile-edit">
                  <IonButton
                    color="primary"
                    fill="outline"
                    size="small"
                    className="long-profile-button"
                    mode="ios"
                  >
                    {t("editProfile")}
                  </IonButton>
                </Link>
              ) : (
                <>
                  <IonButton
                    onClick={handlefollow}
                    color={isFav ? "danger" : "primary"}
                    size="small"
                    fill="solid"
                    mode="ios"
                    className="long-profile-button"
                  >
                    {isFav ? t("unFollow") : t("follow")}
                  </IonButton>
                  <IconButton
                    onClick={() => {
                      if (
                        shop?.owner?.account?.phoneNumber &&
                        shop?.owner?.account?.phoneNumber != ""
                      ) {
                        window.open("tel:+59999999");
                      } else {
                        errorMessage("Shop owner have no Phone number.");
                      }
                    }}
                    iconProps={{ icon: callOutline }}
                    color="primary"
                    size="small"
                    fill="outline"
                    className="contact-button"
                  />
                </>
              )}
            </div>
            <CustomTruncate text={profile?.store?.description ?? ""} />
          </div>

          <SectionTabs>
            <SectionTab label={t("product")}>
              {loading || !isEnabled ? (
                <div className="spinner-container">
                  <IonSpinner name="crescent" color="primary" />
                </div>
              ) : (
                <ShopProductsTab
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  filter={filter}
                  setFilter={setFilter}
                  searchValue={query}
                  setSearchValue={setQuery}
                  getDatanext={getDatanext}
                  isLoad={isLoad}
                  onQuery={onQuery}
                  id={id}
                  products={products ?? []}
                  role={isShopOwner ?? ""}
                  onFilter={onFilter}
                  getData={getData}
                />
              )}
            </SectionTab>

            <SectionTab label={t("reviews")}>
              <ShopReviewsTab storeId={id} />
            </SectionTab>

            <SectionTab label={t("aboutUs")}>
              <ShopAboutTab
                isShop={isShopOwner === "shop"}
                paymentDetails={shop.warranty}
                paymentDesc={shop.description}
              />
            </SectionTab>
          </SectionTabs>
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default ShopProfilePage;
