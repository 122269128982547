import apiManager from '../config/apiManager';
import { ILoginRequest, IRegisterRequest, ILoginResponse, IUserResponse } from '../types/UserTypes';

export const login = async (account: ILoginRequest): Promise<ILoginResponse> => {
  return apiManager.post('/login.php', account);
};

export const register = async (account: IRegisterRequest): Promise<ILoginResponse> => {
  return apiManager.post('/register.php', account);
};

export const getUserProfile = async (id: string): Promise<IUserResponse> => {
  return apiManager.get(`/user.php?id=${id}`).then((res) => res.data);
};

