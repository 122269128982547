import { ServerApi } from '../core/api';
const api = new ServerApi();

export const getCategories = async (take = 1000) => {
  return await api.getCategory(take).then(e=>e.data.result);
};

export const getProductByCategoryId = async (id:string) => {
  return await api.getProductByCategoryId(id);
};

export const getShopsByCategoryId = async (id:string) => {
  return await api.getShopsByCategoryId(id);
};