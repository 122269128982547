import { useState } from 'react';
import './style.scss';
import Truncate from 'react-truncate';

interface CustomTruncateProps {
  text: string;
  lines?: number;
  ellipsis?: any;
  className?: string;
  expandable?: boolean;
}

const CustomTruncate: React.FC<CustomTruncateProps> = (props) => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    <div className={props.className}>
      <Truncate
        lines={!isExpand && (props.lines ? props.lines : 3)}
        ellipsis={
          props.ellipsis ? (
            props.ellipsis
          ) : (
            <span>
              ...{' '}
              {props.expandable && (
                <span
                  className="read-more-button"
                  onClick={() => setIsExpand(true)}
                >
                  Read More
                </span>
              )}
            </span>
          )
        }
      >
        {props.text}
      </Truncate>
    </div>
  );
};

export default CustomTruncate;
