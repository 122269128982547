import React from 'react';
import { Link } from 'react-router-dom';
import { IProductWithStore } from '../../types/ProductTypes';
import ImageWrapper from '../ImageWrapper';
import ShopHeader from '../ShopHeader';
import './style.scss';
import {TProduct} from "../../pages/CategoryPage";

const ProductCard: React.FC<IProductWithStore> = (props) => {
  return (
    <div className="product-card-container">
      <Link to={`/shop/${props.store.id}`}>
        <ShopHeader {...props.store} />
      </Link>
      <Link to={`/product/view/${props.id}`}>
        <ImageWrapper src={props.preview_src} className="product-image" />
        <p className="margin-vertical line-clamp" style={{ WebkitLineClamp: 2 }}>
          {props.name}
        </p>
        <p className="text-color-grey-lighter margin-vertical">${props.price}</p>
      </Link>
    </div>
  );
};

export default ProductCard;
