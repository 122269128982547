/* eslint-disable no-useless-escape */
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonSpinner,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  useIonViewDidLeave,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonToolbar,
  IonHeader
} from "@ionic/react";
import { Storage } from "@capacitor/storage";
import "./style.scss";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import PasswordInput from "../../components/PasswordInput";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import { message } from "antd-notifications-messages";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { chevronBackOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { getLocaleHeaders } from "../../core/baseFunction";

// in case later we need to add more conditions
const rules = [{ title: "At least 8 characters", isChecked: false }];

const ChangePwPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [isNewHidden, setIsNewHidden] = useState(true);
  const [isOldHidden, setIsOldHidden] = useState(true);
  const [isConfimHidden, setIsConfimHidden] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [conditions, setConditions] = useState(rules);
  const [loading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  useIonViewDidLeave(() => {
    setConditions(rules);
  });

  const findIndexOfCondition = (title) => {
    return conditions.findIndex((condition) => condition.title === title);
  };

  const handlePasswordOnChange = (e) => {
    setOldPassword(e.detail.value!);

    const updatedConditions = [...conditions];

    // check password
    const indexOfLenCheck = findIndexOfCondition("At least 8 characters");
    if (e.detail.value.length > 8) {
      updatedConditions[indexOfLenCheck].isChecked = true;
    } else {
      updatedConditions[indexOfLenCheck].isChecked = false;
    }
    setConditions(updatedConditions);

    if (
      Object.values(updatedConditions).every(
        (condition) => condition.isChecked === true
      )
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };
  const handleNewPasswordOnChange = (e) => {
    setNewPassword(e.detail.value!);

    const updatedConditions = [...conditions];

    // check password
    const indexOfLenCheck = findIndexOfCondition("At least 8 characters");
    if (e.detail.value.length > 8) {
      updatedConditions[indexOfLenCheck].isChecked = true;
    } else {
      updatedConditions[indexOfLenCheck].isChecked = false;
    }
    setConditions(updatedConditions);

    if (
      Object.values(updatedConditions).every(
        (condition) => condition.isChecked === true
      )
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  // TODO API reset
  const recover = (e) => {
    e.preventDefault();
    if (oldpassword?.trim().length <= 0) {
      message({
        type: "error",
        message: t("oldPasswordError"),
      });
    } else if (newpassword?.trim().length <= 0) {
      message({
        type: "error",
        message: t("newPasswordError"),
      });
    } else if (newpassword !== confirmpassword) {
      message({
        type: "error",
        message: t("passwordmatch"),
      });
    } else {
      setIsLoading(true);
      const body = {
        oldPassword: oldpassword,
        newPassword: newpassword,
      };
      axios
        .post("https://api.merchport.hk/api/auth/update-password", body, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer  ${localStorage.getItem("access_token")}`,
            local: getLocaleHeaders(),
          },
        })
        .then((res) => {
          if (res.data) {
            console.log("RESS", res.data);
            setIsLoading(false);
            message({
              type: "success",
              message: t("changePasswordSccess"),
            });
            const path = "/login";
            history.push("/owner-settings");
            // localStorage.setItem("access_token", res.data.access_token);
            // localStorage.setItem("refresh_token", res.data.refresh_token);
            // Storage.set({ key: "user", value : res.data.user });
            // Storage.set({ key: "api_token", value : res.data.access_token });
            // history.push("/owner-settings");
            // history.goBack();
          }
        })
        .catch(function (error) {
          if (error.response) {
            setIsLoading(false);
            console.log("Error", error.response.data.message);
            message({
              type: "error",
              // message: `${error.response.data.message}`,
              message: t("login_response_error", {
                Errormessage: `${error.response.data.message}`,
              }), //dynamic tranlation
            });
          } else {
            setIsLoading(false);
            message({
              type: "error",
              message: t("network_Error"),
            });
          }
        });
    }
    // alert(`new password: ${password}\n`);
    // props.history.push('/home');
  };

  return (
    <NoTabBarLayout>
      <IonPage className="auth-page">
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="owner-settings"
              icon={chevronBackOutline}
              text=""
              color="dark"
            />
          </IonButtons>
          <IonTitle>{t("chps")}</IonTitle>
        </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonGrid>
            <IonRow>
              <div className="ion-text-center">
                <p className="auth-heading">{t("resetPassword")}</p>
                <IonText color="secondary">{t("PleaseNewPassword")}</IonText>
                <form onSubmit={recover} className="reset-password">
                  <PasswordInput
                    className="ion-padding"
                    placeholder={t("oldPassword")}
                    value={oldpassword}
                    isHidden={isOldHidden}
                    onChange={handlePasswordOnChange}
                    onClick={() => setIsOldHidden(!isOldHidden)}
                  />
                  <PasswordInput
                    className="ion-padding"
                    placeholder={t("newPassword")}
                    value={newpassword}
                    isHidden={isNewHidden}
                    onChange={handleNewPasswordOnChange}
                    onClick={() => setIsNewHidden(!isNewHidden)}
                  />
                  <PasswordInput
                    className="ion-padding"
                    placeholder={t("confirmPassword")}
                    value={confirmpassword}
                    isHidden={isConfimHidden}
                    onChange={(e) => {
                      setConfirmPassword(e.detail.value);
                    }}
                    onClick={() => setIsConfimHidden(!isConfimHidden)}
                  />
                  <div className="text-color-grey-lighter ion-padding ion-text-left">
                    <div className="ion-padding-bottom">
                      {t("passwordContain")}:
                    </div>
                    {conditions.map((condition, index) => (
                      <div key={index}>
                        <IonCheckbox
                          disabled={true}
                          checked={condition.isChecked}
                          onIonChange={(e) =>
                            (condition.isChecked =
                              e.detail.value === "on" ? true : false)
                          }
                        />
                        <span
                          className={
                            condition.isChecked
                              ? "text-color-grey text-bold"
                              : ""
                          }
                        >
                          {t("leastCharacters")}
                        </span>
                      </div>
                    ))}
                  </div>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    type="submit"
                    className="auth-button ion-margin"
                    disabled={isDisabled}
                    mode="ios"
                  >
                    {t("Done")}{" "}
                    {loading && (
                      <IonSpinner
                        name="circles"
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </IonButton>
                </form>
              </div>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default ChangePwPage;
