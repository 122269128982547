import {
  IonButton,
  IonButtons,
  IonContent,
  IonInput,
  IonItem,
  IonModal,
  IonRange,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { IProductFilter } from "../../types/ProductTypes";
import IconButton from "../IconButton";
import "./style.scss";
import { useTranslation } from "react-i18next";

interface SortFilterModalProps {
  // maxPrice: number;
  show: boolean;
  filter?: IProductFilter; // change to not null later
  onSubmit: (filter: IProductFilter) => void;
  onDismiss: () => void;
  handleProducts: () => void;
}

const sortByOptions = [
  { label: "Popularity", name: "popularity" },
  { label: "Price(Ascending Order)", name: "price_asc" },
  { label: "Price (Descending Order)", name: "price_desc" },
];

// TODO: sort and filter APIs; range input validation

const SortFilterModal: React.FC<SortFilterModalProps> = (props) => {
  const { i18n, t } = useTranslation();
  const maxPrice = 100000;
  const [filter, setFilter] = useState<IProductFilter>();

  useEffect(() => {
    if (props.filter) {
      setFilter(props.filter);
    }
  }, [props.filter]);

  const handleRangeSliderOnChange = (e) => {
    const { lower, upper } = e.detail.value!;
    setFilter({ ...filter, price_min: lower, price_max: upper });
  };

  const handleRangeInputOnChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.detail.value! });
  };

  const handleReset = (e) => {
    e.preventDefault();
    props.handleProducts();
    // setFilter({ sort_by: "", price_min: 0, price_max: maxPrice });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (filter) {
      // eslint-disable-next-line prefer-const
      let { price_min, price_max } = filter;
      // for invalid price range, change the smaller to the larger value
      if (price_min && price_max && price_min > price_max) {
        price_max = price_min;
      }
      props.onSubmit(filter);
      props.onDismiss();
    }
  };

  return (
    <IonModal
      isOpen={props.show}
      cssClass="bottom-sheet"
      mode="ios"
      swipeToClose={true}
      backdropDismiss={true}
      onDidDismiss={props.onDismiss}
    >
      <IonContent className="bottom-sheet-content">
        <IonToolbar className="ion-margin-top">
          <IonTitle>{t("sortAndFilter")}</IonTitle>
          <IonButtons slot="end">
            <IconButton
              iconProps={{ icon: closeOutline }}
              color="dark"
              onClick={props.onDismiss}
            />
          </IonButtons>
        </IonToolbar>
        <div className="ion-padding-horizontal ion-padding-bottom">
          <div className="ion-padding-bottom">
            <p className="text-color-grey-title subtitle-medium-14">
              {" "}
              {t("sortBy")}
            </p>
            {sortByOptions.map((option, index) => (
              <IonButton
                key={index}
                color={option.name === filter?.sort_by ? "primary" : "medium"}
                size="small"
                className="ion-text-capitalize"
                mode="ios"
                onClick={() => setFilter({ ...filter, sort_by: option.name })}
              >
                {option.label}
              </IonButton>
            ))}
          </div>
          <p className="text-color-grey-title subtitle-medium-14">
            {t("priceRange")}
          </p>
          <IonRange
            dual-knobs
            pin
            color="primary"
            max={maxPrice ?? 1000}
            step={10}
            value={{
              lower: filter?.price_min ?? 0,
              upper: filter?.price_max ?? 0,
            }}
            onIonChange={handleRangeSliderOnChange}
            className="range-slider"
          ></IonRange>
          <div className="text-color-grey price-range">
            <IonItem lines="none">
              <span className="ion-padding-end">$</span>
              <IonInput
                className="range-input ion-margin-end"
                name="price_min"
                value={filter?.price_min}
                onIonChange={handleRangeInputOnChange}
                inputmode="numeric"
                type="number"
                min="0"
              ></IonInput>
              -
            </IonItem>
            <IonItem lines="none">
              <IonInput
                className="range-input"
                name="price_max"
                value={filter?.price_max}
                onIonChange={handleRangeInputOnChange}
                inputmode="numeric"
                type="number"
                max={`${maxPrice}`}
              ></IonInput>
            </IonItem>
          </div>
        </div>

        <div className="ion-text-center ion-padding-top test">
          <IonButton
            color="primary"
            fill="outline"
            mode="ios"
            onClick={handleSubmit}
          >
            {t("save")}
          </IonButton>
          <IonButton
            color="medium"
            fill="clear"
            mode="ios"
            onClick={handleReset}
          >
            {t("reset")}
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SortFilterModal;
