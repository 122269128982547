import { AxiosResponse } from "axios";
import { store } from "../redux/store";
import { resetUser } from "../redux/actions/userActions";

export class BaseApi {
  protected handleResponseCount(response: AxiosResponse<any, any>) {
    if (response.status == 200) {
      return {
        haveError: false,
        data: response.data.result,
        count: response.data.count,
        error: null,
      };
    }
    return {
      haveError: true,
      data: [],
      error: response?.data,
    };
  }
  protected handleResponse(response: AxiosResponse<any, any>) {
    if (response.status == 200 || response.status === 201) {
      return {
        haveError: false,
        data: response.data,
        error: null,
      };
    }
    if (response.status == 429) {
      return {
        haveError: true,
        data: [],
        error: "The server is not responding, please try after some time",
      };
    }
    return {
      haveError: true,
      data: [],
      error: response?.data.message,
    };
  }
  protected handleResponsewithRedirect(response) {
    if (
      response?.response?.status == 200 ||
      response?.response?.status === 201
    ) {
      return {
        haveError: false,
        data: response.data,
        error: null,
      };
    }
    if (response?.response?.status == 401) {
      store.dispatch(resetUser());
      localStorage.clear();
      window.location.href = "/login";
    }
    return {
      haveError: true,
      data: [],
      error: response?.response.data?.message,
    };
  }
  protected handleErrorResponse(response) {
    return {
      haveError: true,
      data: [],
      error: Array.isArray(response?.response?.data?.message)
        ? response?.response?.data?.message
        : [response?.response?.data?.message],
    };
  }
  protected getToken() {
    const token = localStorage.getItem("access_token");
    return `Bearer ${token}`;
  }
  protected getLocale() {
    const language = localStorage.getItem("language");
    if (language !== null && language !== undefined) {
      if (language === "en") {
        return "en-US";
      } else {
        return "zh-Hant-HK";
      }
    } else return "en-US";
  }
  protected getGetAuthHeader() {
    return {
      authorization: this.getToken(),
      local: this.getLocale(),
    };
  }
  protected getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
}
