import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillEnter,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import DetailedReviewItem from "../../components/DetailedReviewItem";
import { useTranslation } from "react-i18next";

import "./style.scss";
import {
  getProductReviewsById,
  getShopProductReviewByShopId,
} from "../../apis/productApis";
import React, { useState } from "react";
import { SampleReviewType } from "../ProductPage";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Link } from "react-router-dom";

// eslint-disable-next-line prefer-const
interface MatchParams {
  username: string;
}
type Props = RouteComponentProps<MatchParams>;
const ShopReviewsPage: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();
  const id = props.match.params.username;
  const [reviews, setReviews] = useState<SampleReviewType[]>([]);
  // const [ limit, setlimit ] = useState(0);
  // const [ isLoad, setIsLoad ] = useState(false);
  const user = useSelector((s: RootState) => s.user);
  const role = user.user?.account_type;
  useIonViewWillEnter(() => {
    getProductReviewsById(id).then((e) => {
      if (!e.haveError && Array.isArray(e.data)) {
        setReviews(e.data);
      }
    });
  });
  // const getDatanext=(ev)=> {
  //   getProductReviewsById(id || '', 10, limit + 10).then(e => {
  //     ev.target.complete();
  //     if (!e.haveError) {
  //       setReviews([...reviews, ...e.data]);
  //       if (e.data.length < 10) {
  //         setIsLoad(true);
  //       } else {
  //         setlimit(limit + 10);
  //       }
  //     }
  //   });
  // };
  return (
    <IonPage className="shop-reviews-page">
      <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            defaultHref="/"
            color="dark"
            text=""
            icon={chevronBackOutline}
            mode="ios"
          />
        </IonButtons>
        <IonTitle>
          {t("riviews")}({reviews.length})
        </IonTitle>
      </IonToolbar>
      </IonHeader>

      <IonContent className="center-container">
        {reviews.length > 0 ? (
          <>
            {reviews.map((review, index) => (
              <DetailedReviewItem key={index} review={review} />
            ))}
            {/*  <IonInfiniteScroll*/}
            {/*    onIonInfinite={(ev: any)=>{*/}
            {/*      getDatanext(ev);*/}
            {/*    }}*/}
            {/*    threshold="100px"*/}
            {/*    disabled={isLoad}*/}
            {/*  >*/}
            {/*    <IonInfiniteScrollContent*/}
            {/*      loadingSpinner="bubbles"*/}
            {/*      loadingText="Loading more data..."*/}
            {/*    ></IonInfiniteScrollContent>*/}
            {/*  </IonInfiniteScroll>*/}
          </>
        ) : (
          <div className="center-element ion-text-center">
            {t("noReviewsYet")}
          </div>
        )}
      </IonContent>
      {role != "shop" ? (
        <Link
          className="ion-margin position-bottom-right ion-float-end"
          to={"/review/create?id=" + id}
        >
          {" "}
          <IonButton
            color="primary"
            fill="solid"
            mode="ios"
            style={{ width: 120, marginBottom: 10 }}
          >
            {t("addReview")}
          </IonButton>
        </Link>
      ) : (
        <></>
      )}
    </IonPage>
  );
};

export default ShopReviewsPage;
