import { IonAvatar, IonItem } from '@ionic/react';
import React from 'react';
import { ReviewDataType } from '../../types/DataTypes';
import './style.scss';
import moment from 'moment';
import RatingStars from '../RatingStars';
import CustomTruncate from '../CustomTruncate';
import {SampleReviewType} from "../../pages/ProductPage";

interface DetailedReviewItemProps {
	review: SampleReviewType;
}

const DetailedReviewItem: React.FC<DetailedReviewItemProps> = (props) => {
	return (
		<div className="detailed-review-item-container">
			<div className="detailed-review-item-header">
				<IonItem
					lines="none"
					className="avatar-name-container ion-text-capitalize"
				>
					<IonAvatar className="small-avatar">
						<img
							src={
								props.review?.user?.profile?.avatar?.location || 'https://via.placeholder.com/150'
							}
						/>
					</IonAvatar>
					{ props.review?.user?.profile?.name || "UnKnown"}
				</IonItem>
				<p className=" text-color-grey-lightest date ion-margin-end" slot="end">
					{moment(props.review.createdAt).format('DD/MM/YYYY')}
				</p>
			</div>
			<div className="review-item-body text-color-grey-lighter">
				<RatingStars rating={props.review.rating} />
				<CustomTruncate
					className="text-color-grey-lighter review-content"
					text={props.review.content}
				/>
			</div>
		</div>
	);
};

export default DetailedReviewItem;
