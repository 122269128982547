import {
  IonPage,
  IonContent,
  IonAvatar,
  IonItem,
  IonLabel,
  IonIcon,
  IonButtons,
  IonBackButton,
  IonToolbar,
  IonToggle,
  IonSegment,
  IonSegmentButton,
  useIonViewWillEnter,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import "./style.scss";
import { Storage } from "@capacitor/storage";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import { resetUser, setUser } from "../../redux/actions/userActions";
import { rootReducer, RootState } from "../../redux/reducers";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useState } from "react";
import { setUserSetting } from "../../apis/upload";
import { errorMessage } from "../../core/popups";
import { useHistory } from "react-router-dom";
import { changeLanguage } from "../../redux/actions/languageAction";
const mapStateToProps = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatch = {
  resetUser: resetUser,
};

const connector = connect(mapStateToProps, mapDispatch);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const OwnerSettingsPage: React.FC<PropsType> = (props) => {
  const [notification, setNotification] = useState(true);
  const { i18n, t } = useTranslation();
  const [locale, setLocale] = useState("en");
  const history = useHistory();
  const saveSetting = (local, notification) => {
    console.log("LANGUAGE", local);
    setLocale(locale);
    localStorage.setItem("language", locale);
    store.dispatch(changeLanguage(local));
    i18n.changeLanguage(local);
    // setUserSetting(
    //   local || "en-US",
    //   notification,
    //   props?.user?.profile?.bio || "",
    //   props?.user?.profile?.avatarFileId || ""
    // ).then((e) => {
    //   if (!e.haveError) {
    //     store.dispatch(setUser(e.data));
    //   } else {
    //     errorMessage(e.error);
    //   }
    // });
  };
  const handleLogout = async () => {
    await Storage.remove({ key: "user" });
    await Storage.remove({ key: "api_token" });
    store.dispatch(resetUser());
    const path = "/login";
    history.replace(path);
  };
  return (
    <IonPage className="settings-page">
      <IonHeader>
      <IonToolbar>
        <IonButtons slot="start" className="ion-margin">
          <IonBackButton
            defaultHref="profile"
            icon={chevronBackOutline}
            color="medium"
            text=""
            mode="ios"
          />
        </IonButtons>
      </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem lines="none" className="ion-padding-bottom">
          <IonAvatar slot="start" className="settings-avatar">
            <img
              onError={(e) => {
                e.currentTarget.src =
                  "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y";
              }}
              src={
                props?.user?.profile?.avatar?.location ||
                "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
              }
            />
          </IonAvatar>
          <IonLabel>
            <h1>{props?.user?.profile?.displayName || "No Name"}</h1>
            <h3 className="text-color-grey-lighter">
              @{props?.user?.account?.username}
            </h3>
          </IonLabel>
        </IonItem>

        <p className="text-color-grey settings-subtitle ion-padding-horizontal">
          {t("acc")}
        </p>
        <Link to="/change-password">
          <IonItem lines="none">
            {t("chps")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </Link>

        <p className="text-color-grey settings-subtitle ion-padding-horizontal">
          {t("pref")}
        </p>
        <IonItem lines="none">
          {t("lan")}
          <IonSegment
            // value={props?.user?.preference?.locale || "en-US"}
            className={"size-med"}
            onClick={(e) => {
              saveSetting(
                e.currentTarget.value,
                props?.user?.preference?.receiveNotification
              );
            }}
            slot="end"
          >
            <IonSegmentButton value={"en"}>
              <IonLabel>English</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={"ch"}>
              <IonLabel>Cantonese</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonItem>
        <span>
          <IonItem lines="none">
            {t("Notf")}
            <IonToggle
              slot="end"
              onClick={() => {
                saveSetting(
                  props?.user?.preference?.locale,
                  !(props?.user?.preference?.receiveNotification || true)
                );
                return 0;
              }}
              value={"notification"}
              checked={props?.user?.preference?.receiveNotification}
              color="primary"
            />
          </IonItem>
        </span>
        <p className="text-color-grey settings-subtitle ion-padding-horizontal">
          {"other"}
        </p>
        <Link to="/terms-conditions">
          <IonItem lines="none">
            {t("tc")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </Link>
        <span
          onClick={(e) => {
            window.open("https://merchport.hk/support", "_blank");
          }}
        >
          <IonItem lines="none">
            {t("hs")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </span>
        <IonItem lines="none" onClick={() => handleLogout()}>
          {t("log")}
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default connector(OwnerSettingsPage);
