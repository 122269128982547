import { IonIcon } from '@ionic/react';
import { star, starOutline } from 'ionicons/icons';
import React, { MouseEventHandler } from 'react';
import './style.scss';

interface RatingStarsProps {
  rating: number;
  className?: string;
  onMouseOver?: (params) => MouseEventHandler<HTMLIonIconElement>;
  onClick?: (params) => MouseEventHandler<HTMLIonIconElement>;
  onMouseOut?: (params) => void;
}

const RatingStars: React.FC<RatingStarsProps> = (props) => {
  const renderStars = () => {
    const stars: any = [];

    const renderStarIcon = (index, rating) => {
      if (index <= rating) {
        return star;
      } else {
        return starOutline;
      }
    };

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <IonIcon
          key={i}
          color="primary"
          slot="end"
          icon={renderStarIcon(i, props.rating)}
          size="small"
          className={`star ${props.className}`}
          onMouseOver={props.onMouseOver ? props.onMouseOver(i) : undefined}
          onClick={props.onClick ? props.onClick(i) : undefined}
          onMouseOut={props.onMouseOut}
        ></IonIcon>
      );
    }
    return stars;
  };

  return <span>{renderStars()}</span>;
};

export default RatingStars;
