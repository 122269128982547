import {
  IonButton,
  useIonViewWillEnter,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
} from "@ionic/react";

import {
  closeCircle,
  grid,
  optionsOutline,
  searchCircle,
  searchOutline,
} from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import SortFilterModal from "../SortFilterModal";

import "./style.scss";
import Searchbar from "../Searchbar";
import IconButton from "../IconButton";
import UIContext from "../../utils/UIContext";
import EditFavFooter from "../EditFavFooter";
import SmallProductCard from "../SmallProductCard";
import { TProduct } from "../../pages/CategoryPage";
import { deleteShopProduct } from "../../apis/productApis";
import { errorMessage, successMessage } from "../../core/popups";
import { useTranslation } from "react-i18next";

interface ShopProductsTabProps {
  products: TProduct[];
  role: string;
  onFilter: (e) => void;
  id: string;
  onQuery: (e: string) => void;
  getDatanext: (e) => void;
  getData: () => void;
  isLoad: boolean;
  setFilter: (e) => void;
  filter: any;
  searchValue: string;
  setSearchValue: (e) => void;
  setIsSearch: (e) => void;
  isSearch: boolean;
}

const ShopProductsTab: React.FC<ShopProductsTabProps> = (props) => {
  const { i18n, t } = useTranslation();
  // console.log("FUNCTION", props.getData());
  const [products, setProducts] = useState<TProduct[]>(props.products);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [maxPrice, setMaxPrice] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const { showTabBar, setShowTabBar } = useContext(UIContext);

  useEffect(() => {
    if (isEdit) {
      setShowTabBar(false);
      setSelectedProducts([]);
    } else {
      setShowTabBar(true);
    }
  }, [isEdit]);

  useIonViewWillEnter(() => {
    setProducts(props.products);
    const prices = products.map((product) => parseInt(product.price));
    setMaxPrice(Math.max(...prices));
    // reset states
    setIsEdit(false);

    props.setSearchValue("");
  });

  // const handleSearchOnChange = (e) => {
  //   const value = e.detail.value!;
  //   // props.onQuery(value);
  //   if (value?.trim().length > 0) {
  //     props.onQuery(value);
  //   } else {
  //     props.getData();
  //   }
  // };
  const handleProductSearch = () => {
    if (search?.trim().length > 0) {
      props.onQuery(search);
    } else {
      props.getData();
    }
  };
  const handleProductOnSelect = (productId) => {
    if (!selectedProducts.includes(productId)) {
      setSelectedProducts([...selectedProducts, productId]);
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    }
  };

  const handleProductsOnDelete = () => {
    const procc = selectedProducts.map((e) => deleteShopProduct(props.id, e));
    Promise.all(procc)
      .then((e) => {
        if (Array.isArray(e)) {
          e.forEach((pr) => {
            if (pr.haveError) {
              errorMessage(pr.error);
            } else {
              successMessage("Product removed");
            }
          });
        }
        props.getData();
        // props.onFilter(props.filter);
      })
      .catch((e) => {
        errorMessage("Server Error");
      });
    setIsEdit(false);
  };
  const handleProducts = () => {
    props.getData();
  };
  return (
    <div className="shop-products-tab">
      <div className="toolbar">
        {props.isSearch ? (
          <form style={{ width: "100%" }} onSubmit={handleProductSearch}>
            <IonSearchbar
              className="searchbarios tab-searchbar"
              style={{ backgroundColor: "white" }}
              placeholder={t("search")}
              value={search}
              // showCancelButton="always"
              // showClearButton={"never"}
              onIonChange={(e) => {
                setSearch(e.detail.value!);
                // handleSearchOnChange(e);
              }}
              // onIonCancel={() => {
              //   console.log("SEARCH");
              // }}
            >
              {" "}
            </IonSearchbar>
            {/* <IconButton
              iconProps={{ icon: searchOutline }}
              size="small"
              onClick={() => {
                handleProductSearch();
              }}
              className="ion-margin-start"
              // color="light"
            /> */}
          </form>
        ) : (
          // <Searchbar
          //   className="tab-searchbar"
          //   placeholder="Search product"
          //   value={props.searchValue}
          //   onChange={handleSearchOnChange}
          // ></Searchbar>
          <IconButton
            size="small"
            color="medium"
            fill="clear"
            onClick={() => props.setIsSearch(true)}
            iconProps={{
              icon: searchOutline,
              className: "toolbar-button",
              size: "small",
              slot: "start",
            }}
            title={t("search")}
            className="ion-margin-start"
          />
        )}

        {props.isSearch ? (
          <IonButton
            slot="end"
            size="small"
            color="medium"
            fill="clear"
            mode="ios"
            onClick={() => props.setIsSearch(false)}
          >
            {t("cancel")}
          </IonButton>
        ) : (
          <IconButton
            slot={props.role !== "shop" ? "end" : ""}
            size="small"
            color="medium"
            fill="clear"
            onClick={() => setShowModal(true)}
            iconProps={{
              icon: optionsOutline,
              className: "toolbar-button",
              size: "small",
              slot: "start",
            }}
            title={t("filter")}
          />
        )}

        {props.role === "shop" && !props.isSearch && (
          <>
            {isEdit ? (
              <IconButton
                size="small"
                color="medium"
                fill="clear"
                onClick={() => setIsEdit(false)}
                iconProps={{
                  icon: closeCircle,
                  className: "toolbar-button",
                  size: "small",
                  slot: "start",
                }}
                title={t("cancel")}
                className="ion-margin-end"
              />
            ) : (
              <IconButton
                size="small"
                color="medium"
                fill="clear"
                onClick={() => setIsEdit(true)}
                iconProps={{
                  icon: grid,
                  className: "toolbar-button",
                  size: "small",
                  slot: "start",
                }}
                title={t("edit")}
                className="ion-margin-end"
              />
            )}
          </>
        )}
      </div>

      {products.length > 0 ? (
        <>
          <IonGrid className="small-product-card-grid ion-justify-content-center p-10">
            {products.map((product, index) => (
              <SmallProductCard
                title={product.name}
                {...product}
                key={index}
                isEdit={isEdit}
                onSelect={handleProductOnSelect}
              />
            ))}
          </IonGrid>
          <IonInfiniteScroll
            onIonInfinite={(ev: any) => {
              props.getDatanext(ev);
            }}
            threshold="100px"
            disabled={props.isLoad}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText={t("Loading")}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
      ) : (
        <p className="ion-text-center">
          {t("no")} {props.searchValue && "matched"} {t("product")}
        </p>
      )}

      {showModal && (
        <SortFilterModal
          filter={props.filter}
          show={showModal}
          onDismiss={() => setShowModal(false)}
          onSubmit={(filter) => {
            props.setFilter(filter);
            props.onFilter(filter);
          }}
          handleProducts={handleProducts}
        />
      )}

      {isEdit && !showTabBar && (
        <EditFavFooter
          count={selectedProducts.length}
          itemName={t("product")}
          onDelete={handleProductsOnDelete}
        />
      )}
    </div>
  );
};

export default ShopProductsTab;
