import { ServerApi } from "../core/api";
import {
  GetProductsQuery,
  IProduct,
  IProductWithStore,
} from "../types/ProductTypes";
const api = new ServerApi();

export const getProductsByStoreId = async (
  id: string,
  skip: number,
  take = 10
) => {
  return await api.getProductsByStoreId(id, skip, take);
};

export const searchProducts = async (query: GetProductsQuery) => {
  // console.log("QUERY MIDD", query);
  const { startFrom, categoryId, q, filter, scope, storeId } = query;
  return await api.searchProducts(
    q ?? "",
    scope,
    storeId,
    categoryId,
    filter,
    startFrom
  );
};
export const getProductById = async (id: string) => {
  return await api.getProductById(id);
};
export const getProductReviewsById = async (
  id: string,
  take = 10,
  skip = 0
) => {
  return await api.getProductReviewsById(id, take, skip);
};
export const getShopProductReviewByShopId = async (
  id: string,
  take = 10,
  skip = 0
) => {
  return await api.getShopProductReviewByShopId(id, take, skip);
};
export const getTrendingProduct = async (take: number, skip: number) => {
  return await api.getTrendingProduct(take, skip);
};

export const postShop = async (data: any) => {
  return await api.postShop(data);
};

export const addProductInShop = async (shopId: string, productId: string) => {
  return await api.AddProductInShop(shopId, productId);
};

export const updateProductById = async (productId: string, product: any) => {
  return await api.updateProductById(productId, product);
};

export const postProduct = async (data: any) => {
  return await api.postProduct(data);
};
export const deleteShopProduct = async (shopId: string, productid: string) => {
  return await api.deleteShopProduct(shopId, productid);
};

export const getRecommendedProduct = async (take: number, skip: number) => {
  return await api.getRecommendedProduct(take, skip);
};
