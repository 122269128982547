/* eslint-disable no-useless-escape */
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonSpinner,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  useIonViewDidLeave,
} from "@ionic/react";
import "./style.scss";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import PasswordInput from "../../components/PasswordInput";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import { message } from "antd-notifications-messages";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ArrayLength, getLocaleHeaders } from "../../core/baseFunction";
import { useTranslation } from "react-i18next";

// in case later we need to add more conditions
const rules = [{ title: "At least 8 characters", isChecked: false }];
interface MatchParams {
  name: string;
}
type Props = RouteComponentProps<MatchParams>;
const ResetPwPage: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();
  const [password, setPassword] = useState("");
  const [isHidden, setIsHidden] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [conditions, setConditions] = useState(rules);
  const [loading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  useIonViewDidLeave(() => {
    setPassword("");
    setIsHidden(true);
    setIsDisabled(true);
    setConditions(rules);
  });

  const findIndexOfCondition = (title) => {
    return conditions.findIndex((condition) => condition.title === title);
  };

  const handlePasswordOnChange = (e) => {
    setPassword(e.detail.value!);

    const updatedConditions = [...conditions];

    // check password
    const indexOfLenCheck = findIndexOfCondition("At least 8 characters");
    if (e.detail.value.length > 8) {
      updatedConditions[indexOfLenCheck].isChecked = true;
    } else {
      updatedConditions[indexOfLenCheck].isChecked = false;
    }
    setConditions(updatedConditions);

    if (
      Object.values(updatedConditions).every(
        (condition) => condition.isChecked === true
      )
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  // TODO API reset
  const recover = (e) => {
    e.preventDefault();
    if (password?.trim().length <= 0) {
      message({
        type: "error",
        message: t("PleaseNewPassword"),
      });
    } else {
      setIsLoading(true);
      const body = {
        key: props.match.params.name,
        password: password,
      };
      axios
        .post("https://api.merchport.hk/api/auth/reset-password", body, {
          headers: {
            "Content-Type": "application/json",
            local: getLocaleHeaders(),
          },
        })
        .then((res) => {
          if (res.data) {
            setIsLoading(false);
            message({
              type: "success",
              message: t("changePasswordSccess"),
            });
            const path = "/login";
            history.push(path);
          }
        })
        .catch(function (error) {
          if (error.response) {
            setIsLoading(false);
            message({
              type: "error",
              // message: `${error.response.data.message}`,
              message: t("login_response_error", {
                Errormessage: `${
                  ArrayLength(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message
                }`,
              }), //dynamic tranlation
            });
            // message({
            //   type: "error",
            //   style: {
            //     width: "120%",
            //   },
            //   message: `${
            //     ArrayLength(error.response.data.message)
            //       ? error.response.data.message[0]
            //       : error.response.data.message
            //   }`,
            // });
          } else {
            setIsLoading(false);
            message({
              type: "error",
              message: t("network_Error"),
            });
          }
        });
    }
    // alert(`new password: ${password}\n`);
    // props.history.push('/home');
  };

  return (
    <NoTabBarLayout>
      <IonPage className="auth-page">
        <IonContent fullscreen>
          <IonGrid>
            <IonRow>
              <div className="ion-text-center">
                <p className="auth-heading">{t("resetPassword")}</p>
                <IonText color="secondary">{t("PleaseNewPassword")}</IonText>
                <form onSubmit={recover} className="reset-password">
                  <PasswordInput
                    className="ion-padding"
                    placeholder={t("pass")}
                    value={password}
                    isHidden={isHidden}
                    onChange={handlePasswordOnChange}
                    onClick={() => setIsHidden(!isHidden)}
                  />
                  <div className="text-color-grey-lighter ion-padding ion-text-left">
                    <div className="ion-padding-bottom">
                      {t("passwordContain")}
                    </div>
                    {conditions.map((condition, index) => (
                      <div key={index}>
                        <IonCheckbox
                          disabled={true}
                          checked={condition.isChecked}
                          onIonChange={(e) =>
                            (condition.isChecked =
                              e.detail.value === "on" ? true : false)
                          }
                        />
                        <span
                          className={
                            condition.isChecked
                              ? "text-color-grey text-bold"
                              : ""
                          }
                        >
                          {t("leastCharacters")}
                        </span>
                      </div>
                    ))}
                  </div>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    type="submit"
                    className="auth-button ion-margin"
                    disabled={isDisabled}
                    mode="ios"
                  >
                    {t("Done")}{" "}
                    {loading && (
                      <IonSpinner
                        name="circles"
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </IonButton>
                </form>
              </div>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default ResetPwPage;
