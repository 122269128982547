import React from "react";
import "./style.scss";

interface SectionTabProps {
  children: any;
  label: string;
}

const SectionTab: React.FC<SectionTabProps> = (props) => {
  return <div className="section-tab">{props.children}</div>;
};

export default SectionTab;
