import React from 'react';
import { Link } from 'react-router-dom';
import { ICategory } from '../../types/CategoryTypes';
import './style.scss';
import { useTranslation } from "react-i18next";


interface CategoryProps {
  className?: string;
}

type PropsType = CategoryProps & ICategory;

const CategoryBox: React.FC<PropsType> = (props) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={`category-box-container ${props.className}`}>
      <Link to={`/category/${props.name }?id=${props.id}`}  >
        <div
          className="category-title category-op"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${props?.backgroundImage?.location || ''})`,
          }}
        >
          <span className="ion-text-capitalize">{t(props.name)}</span>
        </div>
      </Link>
    </div>
  );
};

export default CategoryBox;
