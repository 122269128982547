import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  
} from "@ionic/react";
import {
  bagHandleOutline,
  chevronBackOutline,
  closeOutline,
  ellipse,
  imageOutline,
  send,
} from "ionicons/icons";
import "./style.scss";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Searchbar from "../../components/Searchbar";
import moment from "moment";
import CustomTruncate from "../../components/CustomTruncate";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import IconButton from "../../components/IconButton";
import Textarea from "../../components/Textarea";
import ImageWrapper from "../../components/ImageWrapper";
import { menuController } from "@ionic/core";
import { useTranslation } from "react-i18next";

// fake data
const fakeChat = {
  shop: {
    name: "original shop",
    avatar:
      "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    lastOnline: moment().subtract(1, "minutes"),
  },
  messages: [
    {
      text: "hello world",
      date: moment().subtract(1, "days"),
      sender: { id: 1, username: "testuser" },
      receiver: { id: 2, username: "testshopper" },
    },
    {
      text: "hello world",
      date: new Date(),
      sender: { id: 2, username: "testshopper" },
      receiver: { id: 1, username: "testuser" },
    },
    {
      text: "bye world",
      date: new Date(),
      sender: { id: 1, username: "testuser" },
      receiver: { id: 2, username: "testshopper" },
    },
  ],
  products: [
    {
      id: 1,
      name: "productnanme",
      image: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/shoes.jpg",
      price: 100,
      status: "no order",
    },
    {
      id: 2,
      name: "productnanme",
      image: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/shoes.jpg",
      price: 100,
      status: "order completed",
    },
    {
      id: 3,
      name: "productnanme",
      image: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/shoes.jpg",
      price: 100,
      status: "reviewed",
    },
    {
      id: 3,
      name: "productnanme",
      image: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/shoes.jpg",
      price: 100,
      status: "order not completed",
    },
  ],
  isSeenAt: moment().subtract(1, "minutes"),
};

// TODO: finetune UI

/*************************************************************************
 * Product Component
 ************************************************************************/

const ProductItem = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <IonGrid className="product-item">
      <IonRow className="ion-align-items-center">
        <div className="product-image-container">
          <ImageWrapper src={props.product.image} className="product-image" />
        </div>
        <IonCol>
          <p className="product-name ion-no-margin">{props.product.name}</p>
          <p className="product-price ion-no-margin">${props.product.price}</p>
        </IonCol>
        <IonCol className="ion-text-right">
          <IconButton
            size="small"
            fill="clear"
            iconProps={{ icon: closeOutline, color: "dark" }}
            onClick={() => props.onClose()}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

/*************************************************************************
 * Clicked Product Component
 ************************************************************************/

const ClickedProductItem = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <IonGrid className="clicked-product-item-container">
      <IonRow>
        {/* <IonCol size="1"></IonCol> */}
        <IonCol>
          <IonGrid className="clicked-product-item">
            <IonRow className="ion-align-items-center">
              <div className="left-border"></div>
              <div className="product-image-container">
                <ImageWrapper
                  src={props.product.image}
                  className="product-image"
                />
              </div>
              <IonCol>
                <p className="product-name ion-no-margin">
                  {props.product.name}
                </p>
                <p className="product-price ion-no-margin">
                  ${props.product.price}
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

/*************************************************************************
 * Mentioned Product Item
 ************************************************************************/

const MentionedProductItem = (props) => {
  const { i18n, t } = useTranslation();
  const renderButton = (status) => {
    switch (status) {
      case "no order":
        return (
          <IonButton color="primary" mode="ios" size="small">
            {t("order")}
          </IonButton>
        );
      case "order not completed":
        return (
          <IonButton color="primary" mode="ios" size="small">
            {t("completeOrder")}
          </IonButton>
        );
      case "order completed":
        return (
          <IonButton color="primary" mode="ios" size="small">
            {t("orderCompleted")}
          </IonButton>
        );
      case "reviewed":
        return (
          <IonButton color="light" mode="ios" size="small">
            {t("reviewed")}
          </IonButton>
        );
    }
  };

  return (
    <IonGrid>
      <IonRow className="ion-align-items-center mentioned-product-item">
        <div className="product-image-container">
          <ImageWrapper src={props.product.image} className="product-image" />
        </div>
        <IonCol>
          <p className="ion-no-margin">{props.product.name}</p>
          <p className="ion-no-margin">${props.product.price}</p>
          {renderButton(props.product.status)}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

/*************************************************************************
 * Products Drawer
 ************************************************************************/

const ProductsDrawer = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <IonMenu side="end" contentId="chat-content" className="chatroom-drawer">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IconButton
              fill="clear"
              iconProps={{ icon: closeOutline, color: "dark" }}
              onClick={() => menuController.close()}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {props.products.length > 0 ? (
          <>
            <p className="subtitle-medium-14 ion-padding-start">
              {t("productsMentioned")}
            </p>
            <IonList className="ion-no-padding">
              {props.products.map((product, index) => (
                <IonItem
                  lines="none"
                  key={index}
                  className="mentioned-product-item-container"
                >
                  <MentionedProductItem product={product} />
                </IonItem>
              ))}
            </IonList>
          </>
        ) : (
          <p className="subtitle-medium-14 ion-padding-start">
            {t("noProductMentioned")}
          </p>
        )}
      </IonContent>
    </IonMenu>
  );
};

/*************************************************************************
 * Special Message
 ************************************************************************/

const SpecialMessage = ({ children }) => {
  return (
    <IonGrid className="ion-margin-bottom">
      <IonRow className=" ion-justify-content-end">
        <div className="special-message">{children}</div>
      </IonRow>
    </IonGrid>
  );
};

/*************************************************************************
 * Chatroom Page
 ************************************************************************/

const ChatroomPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  // DEBUG only
  const uid = 1;
  const hasProduct = true;

  const [chat, setChat] = useState(fakeChat);
  const [message, setMessage] = useState<string>("");
  const [isProductItemShown, setIsProductItemShown] = useState(true);

  const checkTimeDiff = (prevTime, currentTime) => {
    const prevTimeMoment = moment(prevTime);
    const currentTimeMoment = moment(currentTime);
    const duration = moment.duration(currentTimeMoment.diff(prevTimeMoment));
    const minutes = duration.asMinutes();
    return minutes > 5;
  };

  return (
    <NoTabBarLayout>
      <ProductsDrawer products={chat.products} />

      <IonPage className="chatroom-page" id="chat-content">
      <IonHeader>
        <IonToolbar mode="ios" className="header">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="owner-settings"
              icon={chevronBackOutline}
              text=""
              color="dark"
            />
          </IonButtons>
          <IonAvatar slot="start">
            {chat.shop.avatar ? (
              <img src={chat.shop.avatar} />
            ) : (
              <div className="no-image"></div>
            )}
          </IonAvatar>
          <p className="ion-no-margin shop-name">{chat.shop.name}</p>
          <p className="ion-no-margin online-time">
            {t("online")}{" "}
            {moment(chat.shop.lastOnline).startOf("minutes").fromNow()}
          </p>
          <IconButton
            slot="end"
            fill="clear"
            color="secondary"
            mode="md"
            iconProps={{
              icon: bagHandleOutline,
              color: "secondary",
            }}
            onClick={() => menuController.open()}
          />
        </IonToolbar>
        </IonHeader>
        <IonContent>
          <div id="message-window" className="message-window">
            {chat.messages.map((message, index) => {
              return (
                <div key={index}>
                  {(checkTimeDiff(
                    chat.messages[index - 1]?.date,
                    message.date
                  ) ||
                    index === 0) && (
                    <p className="message-time">
                      {moment(message.date).calendar()}
                    </p>
                  )}
                  <div
                    className={
                      message.sender.id === uid
                        ? "sent-message"
                        : "received-message"
                    }
                  >
                    <p>{message.text}</p>
                  </div>
                  {/* hard-code first */}
                  {index === 0 && (
                    <ClickedProductItem product={chat.products[0]} />
                  )}
                  {index === chat.messages.length - 1 && (
                    <p className="message-time ion-text-right ion-no-margin seen">
                      {t("seenAt")} 17:00
                    </p>
                  )}
                  <SpecialMessage>
                    <ClickedProductItem product={chat.products[0]} />
                    <p style={{ margin: "0 10px 5px 10px" }}>
                      {t("orderedThisItem")}
                    </p>
                  </SpecialMessage>
                </div>
              );
            })}
          </div>
        </IonContent>

        <IonFooter>
          {hasProduct && isProductItemShown && (
            <ProductItem
              product={chat.products[0]}
              onClose={() => setIsProductItemShown(false)}
            />
          )}
          <IonGrid>
            <IonRow className="ion-no-padding">
              <IonCol size="1">
                <IconButton
                  size="small"
                  iconProps={{ icon: imageOutline }}
                  fill="solid"
                  color="medium"
                />
              </IonCol>
              <IonCol>
                <IonTextarea
                  autoGrow
                  placeholder={t("typeYourMessage")}
                  value={message}
                  onIonChange={(e: CustomEvent) => setMessage(e.detail.value)}
                />
              </IonCol>
              <IonCol size="2" className="ion-text-center ion-no-padding">
                {message && (
                  <IonButton
                    color="primary"
                    className="ion-text-capitalize"
                    fill="clear"
                    size="small"
                  >
                    {t("send")}
                  </IonButton>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default ChatroomPage;
