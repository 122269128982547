import React from 'react';
import { Link } from 'react-router-dom';
import { ReviewDataType } from '../../types/DataTypes';
import './style.scss';
import moment from 'moment';
import RatingStars from '../RatingStars';
import CustomTruncate from '../CustomTruncate';
import { IReview } from '../../types/ReviewTypes';
import {SampleReviewType} from "../../pages/ProductPage";
import {TReviewWithProduct} from "../ShopReviewsTab";

const ReviewItem: React.FC<TReviewWithProduct> = (props) => {
  return (
    <div className="review-item-container">
      <div className="review-item-header">
        <Link to={`/product/view/${props.productId}`}>
          <div className="avatar-name-container">
            <img className="small-avatar" src={props.product?.images[0]?.location || ''} />
            <div className="shop-name text-color-grey-title line-clamp" style={{ WebkitLineClamp: 1 }}>
              {props.product.name}
            </div>
          </div>
        </Link>
      </div>
      <div className="review-item-body">
        <RatingStars rating={props.rating} />
        <CustomTruncate className="text-color-grey-lighter review-content" text={props.content} />
        <p className="text-color-grey-title user-date-container">
          {props.user?.profile?.name || "Anonymous"} | {moment(props.createdAt).format('DD/MM/YYYY')}
        </p>
      </div>
    </div>
  );
};

export default ReviewItem;
