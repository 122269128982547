import { IonCol, IonFooter, IonGrid, IonRow, IonToolbar } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import IconButton from "../IconButton";
import "./style.scss";
import { useTranslation } from "react-i18next";

interface EditFavFooterProps {
  count: number;
  itemName: string;
  onDelete: () => void;
}

const EditFavFooter: React.FC<EditFavFooterProps> = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <IonFooter className="bottom-bar fixed">
      <IonToolbar>
        <IonGrid className="ion-margin-horizontal ion-no-padding">
          <IonRow className="ion-no-padding ion-align-items-center ion-text-capitalize">
            <IonCol>
              <span>
                {props.count > 0
                  ? props.count > 1
                    ? `${t("selected")} ${props.count} ${props.itemName}`
                    : `${t("selected")} ${props.count} ${props.itemName}`
                  : t("SelectProduct")}
              </span>
            </IonCol>
            <IonCol>
              <IconButton
                fill="clear"
                className="round"
                color={props.count > 0 ? "primary" : "medium"}
                iconProps={{
                  icon: trashOutline,
                  size: "medium",
                }}
                onClick={props.onDelete}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonFooter>
  );
};

export default EditFavFooter;
