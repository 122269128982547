import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import "./style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { RouteComponentProps, useHistory } from "react-router-dom";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import Textarea from "../../components/Textarea";
import IconButton from "../../components/IconButton";
import ShopHeader from "../../components/ShopHeader";
import RatingStars from "../../components/RatingStars";
import { SampleData } from "../ProductPage";
import { getProductById } from "../../apis/productApis";
import ImageUploadGrid from "../../components/ImageUploadGrid";
import { PostProductReview } from "../../apis/reviewApis";
import { errorMessage, successMessage } from "../../core/popups";
import { message } from "antd-notifications-messages";

interface INewReview {
  tmpRating: number;
  rating: number;
  content: string;
  images: string[];
}
interface MatchParams {
  id: string;
}
type Props = RouteComponentProps<MatchParams>;
const AddReviewPage: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation();
  const [photos, setPhotos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const id = (props?.location?.search || "").replace("?id=", "");
  const [data, setData] = useState(SampleData);
  const [review, setReview] = useState<INewReview>({
    tmpRating: 0,
    rating: 0,
    content: "",
    images: [],
  });
  useIonViewWillEnter(() => {
    getProductById(id).then((e) => {
      if (!e.haveError) {
        setData(e.data);
      }
    });
  });
  useEffect(() => setReview({ ...review, images: photos }), [photos]);
  const handleInputOnChange = (e) => {
    setReview({ ...review, [e.target.name]: e.target.value });
  };
  const handleRatingOnMouseOver = (currentRating) => (e) => {
    setReview((prev) => ({
      ...review,
      rating: currentRating,
      tmpRating: prev.rating,
    }));
  };

  const handleRatingOnMouseOut = () => {
    setReview((prev) => ({ ...review, rating: prev.tmpRating }));
  };

  const handleRatingOnClick = (currentRating) => (e) => {
    setReview({ ...review, rating: currentRating, tmpRating: currentRating });
  };
  const updatePhotos = (ids: string[], images: any[]) => {
    console.log(ids, "aaaaaaaaaaa", images);
    setReview({ ...review, images: ids });
    setPhotos(images);
  };

  const handleReviewOnSubmit = async () => {
    PostProductReview(id, {
      rating: review.rating,
      content: review.content,
      additionalPhotos: review.images,
    }).then((e) => {
      if (!e?.haveError) {
        successMessage(t("review_sucess"));
        history.goBack();
      } else {
        message({
          type: "error",
          // message: `${error.response.data.message}`,
          message: t("global_error", {
            Errormessage: `${e.error}`,
          }), //dynamic tranlation
        });
        // errorMessage(e.error);
      }
    });
  };

  return (
    <NoTabBarLayout>
      <IonPage className="add-review-page">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IconButton
              onClick={() => history.goBack()}
              iconProps={{ icon: closeOutline, color: "dark" }}
            />
          </IonButtons>
          <IonTitle>{t("writeReview")}</IonTitle>
        </IonToolbar>

        <IonContent>
          <p className="subtitle-medium-14">{t("productReviewing")}</p>
          <div className="product-container">
            <div
              className="ion-padding ion-text-center ion-justify-content-center text-color-grey-lighter product-image"
              style={
                Array.isArray(data.images) && data.images.length > 0
                  ? {
                      backgroundImage: `url(${data.images[0].location})`,
                      backgroundSize: "cover",
                      backgroundPosition: "50%",
                    }
                  : { boxShadow: "0 0 3px var(--ion-color-grey-lightest)" }
              }
            >
              {data.images.length === 0 && t("noproductImage")}
            </div>
            <div className="ion-padding-start">
              <p className="product-name">{data.name}</p>
              <ShopHeader
                verified={true}
                className="shop-name"
                {...data?.shops[0]?.shop}
              />
            </div>
          </div>

          <p className="subtitle-medium-14">{t("rating")}</p>
          <RatingStars
            rating={review.rating}
            className="rating-star"
            onMouseOver={handleRatingOnMouseOver}
            onClick={handleRatingOnClick}
            onMouseOut={handleRatingOnMouseOut}
          />

          <p className="subtitle-medium-14">{t("riviews")}</p>
          <Textarea
            name="content"
            placeholder={t("leaveComments")}
            value={review.content}
            onChange={handleInputOnChange}
          ></Textarea>

          <p className="subtitle-medium-14">{t("additionalPhotos")}</p>
          <ImageUploadGrid
            onUpdate={updatePhotos}
            setLoading={setLoading}
            ids={review.images}
            images={photos}
          />
        </IonContent>

        <IonFooter className="ion-no-border footer">
          <IonToolbar>
            <IonButton
              disabled={loading}
              color="primary"
              fill="solid"
              expand="block"
              mode="ios"
              className="ion-margin"
              onClick={handleReviewOnSubmit}
            >
              {t("submit")}
              {loading && (
                <IonSpinner name="circles" style={{ marginLeft: "5px" }} />
              )}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default AddReviewPage;
