import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline, ellipse } from "ionicons/icons";
import "./style.scss";
import { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Searchbar from "../../components/Searchbar";
import moment from "moment";
import CustomTruncate from "../../components/CustomTruncate";
import { useTranslation } from "react-i18next";

// fake data
const fakeChats = [
  {
    id: 1,
    shop: {
      name: "original shop",
      avatar: "",
    },
    messages: [{ text: "hello world", date: new Date() }],
    isRead: true,
  },
  {
    id: 2,
    shop: {
      name: "original shop",
      avatar:
        "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    },
    messages: [{ text: "hello world", date: new Date() }],
    isRead: false,
  },
  {
    id: 3,
    shop: {
      name: "original shop",
      avatar:
        "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    },
    messages: [
      {
        text: "This is the message preview. If it is too long, it will be separated to two line hahahahahhahaha dsnfksdnfkdjs",
        date: new Date(),
      },
    ],
    isRead: false,
  },
];

/*************************************************************************
	Chat Item
	************************************************************************/

const ChatItem = (props) => {
  const { i18n, t } = useTranslation();
  return (
    <Link to={`/chat/${props.id}`}>
      <IonItem className="chat-item-container" lines="none">
        <IonAvatar slot="start">
          {props.shop.avatar ? (
            <img src={props.shop.avatar} />
          ) : (
            <div className="no-image"></div>
          )}
        </IonAvatar>
        <IonLabel>
          <div className="shop-name-date-container">
            <h2>{props.shop.name}</h2>
            <span className="text-color-grey-lighter">
              {moment(props.messages[0].date).format("DD MMM")}
            </span>
          </div>

          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol size="11">
                <CustomTruncate
                  text={props.messages[0].text}
                  lines={2}
                  expandable={false}
                />
              </IonCol>
              {!props.isRead && (
                <IonCol className="ion-text-right">
                  <IonIcon icon={ellipse} color="primary" />
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonLabel>
      </IonItem>
    </Link>
  );
};

/*************************************************************************
	Chats Page
	************************************************************************/

const ChatsPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [chats, setChats] = useState(fakeChats);

  const handleSearchOnChange = (e) => {
    const value = e.detail.value!;
    setSearchValue(value);
    if (!value) {
      setChats(fakeChats);
    } else {
      setChats(chats.filter((chat) => chat.shop.name.includes(value)));
    }
  };

  return (
    <IonPage className="chats-page">
      <IonHeader>
      <IonToolbar mode="ios">
        <IonButtons slot="start">
          <IonBackButton
            defaultHref="owner-settings"
            icon={chevronBackOutline}
            text=""
            color="dark"
          />
        </IonButtons>
        <IonTitle>{t("chatroom")}</IonTitle>
      </IonToolbar>
      </IonHeader>
      <IonContent>
        <Searchbar
          value={searchValue}
          onChange={handleSearchOnChange}
          placeholder={t("search")}
        ></Searchbar>
        <IonList>
          {chats.map((chat, index) => (
            <ChatItem key={index} {...chat} />
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ChatsPage;
