import { IonButtons, IonToolbar, IonGrid } from "@ionic/react";
import { closeCircle, grid } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../utils/UIContext";
import EditFavFooter from "../EditFavFooter";
import IconButton from "../IconButton";
import Searchbar from "../Searchbar";
import SmallProductCard from "../SmallProductCard";
import "./style.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { IProductLike } from "../../redux/reducers/likeReducer";
import { useHistory } from "react-router-dom";
import { getMyLikedProduct, removeLikeProduct } from "../../apis/LikeApi";
import { store } from "../../redux/store";
import { setLikes } from "../../redux/actions/likeActions";
import { errorMessage } from "../../core/popups";
import { useTranslation } from "react-i18next";

// fake data
const favProducts = [
  {
    id: 1,
    title: "shoes shoesshoesshoes sdfdsf",
    image: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/shoes.jpg",
    isCheckmarked: false,
    price: 50,
  },
  {
    id: 10,
    title: "productttttt",
    image: "",
    isCheckmarked: true,
    price: 504,
  },
  {
    id: 100,
    title: "productttttt",
    image: "",
    isCheckmarked: true,
    price: 504,
  },
  {
    id: 17,
    title: "perfumee 2.0",
    image: "https://via.placeholder.com/150",
    isCheckmarked: false,
    price: 10000,
  },
  {
    id: 18,
    title: "perfumee 2.0",
    image: "https://via.placeholder.com/150",
    isCheckmarked: false,
    price: 10000,
  },
  {
    id: 15,
    title: "perfumee 2.0",
    image: "https://via.placeholder.com/150",
    isCheckmarked: false,
    price: 10000,
  },
  {
    id: 14,
    title: "perfumee 2.0",
    image: "https://via.placeholder.com/150",
    isCheckmarked: false,
    price: 10000,
  },
  {
    id: 13,
    title: "perfumee 2.0",
    image: "https://via.placeholder.com/150",
    isCheckmarked: false,
    price: 10000,
  },
  {
    id: 12,
    title: "perfumee 2.0",
    image: "https://via.placeholder.com/150",
    isCheckmarked: false,
    price: 10000,
  },
  {
    id: 10000,
    title: "productttttt",
    image: "",
    isCheckmarked: true,
    price: 504,
  },
  {
    id: 154,
    title: "perfumee 2.0",
    image: "https://via.placeholder.com/150",
    isCheckmarked: false,
    price: 10000,
  },
  {
    id: 176,
    title: "perfumee 2.0",
    image: "https://via.placeholder.com/150",
    isCheckmarked: false,
    price: 10000,
  },
];

// TODO: search API; edit function; refactor datatype

const FavProductsTab: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [products, setProducts] = useState<any[]>(favProducts);
  const like = useSelector((x: RootState) => x.like.product);
  const [searchValue, setSearchValue] = useState("");
  const histoy = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const { showTabBar, setShowTabBar } = useContext(UIContext);
  useEffect(() => {
    if (isEdit) {
      setShowTabBar(false);
      setSelectedProducts([]);
    } else {
      setShowTabBar(true);
    }
  }, [isEdit]);

  const handleSearchOnChange = (e) => {
    const value = e.detail.value!;
    setSearchValue(value);
    if (!value) {
      setProducts(favProducts);
    } else {
      setProducts(
        like.filter((product: IProductLike) =>
          product.product.name.includes(value)
        )
      );
    }
  };

  const handleProductOnSelect = (productId) => {
    if (!selectedProducts.includes(productId)) {
      setSelectedProducts([...selectedProducts, productId]);
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    }
  };

  const handleProductsOnDelete = () => {
    const deletelist = selectedProducts.map((e) => {
      return removeLikeProduct(e);
    });
    Promise.allSettled(deletelist)
      .then((e) => {
        getMyLikedProduct().then((e) => {
          if (!e.haveError) {
            store.dispatch(setLikes(e.data));
          } else {
            errorMessage(t("network_Error"));
          }
        });
      })
      .catch((e) => {
        errorMessage(t("network_Error"));
        getMyLikedProduct().then((e) => {
          if (!e.haveError) {
            store.dispatch(setLikes(e.data));
          }
        });
      });
    setProducts(
      like.filter(
        (product: IProductLike) =>
          !selectedProducts.includes(product.product.id)
      )
    );
    setIsEdit(false);
  };

  return (
    <>
      <IonToolbar className="fixed">
        {!isEdit && (
          <Searchbar
            value={searchValue}
            onChange={handleSearchOnChange}
            placeholder={t("searchProduct")}
          ></Searchbar>
        )}
        <IonButtons slot="end">
          {isEdit ? (
            <IconButton
              color="medium"
              onClick={() => setIsEdit(false)}
              iconProps={{
                icon: closeCircle,
                className: "toolbar-button",
                size: "small",
                slot: "start",
              }}
              title={t("cancel")}
            />
          ) : (
            <IconButton
              color="medium"
              onClick={() => setIsEdit(true)}
              iconProps={{
                icon: grid,
                className: "toolbar-button",
                size: "small",
                slot: "start",
              }}
              title={t("edit")}
            />
          )}
        </IonButtons>
      </IonToolbar>

      {like.length > 0 ? (
        <IonGrid className="small-product-card-grid">
          {searchValue && searchValue.length > 0
            ? like
                .filter((product: IProductLike) =>
                  product.product.name.includes(searchValue)
                )
                .map((product: IProductLike, index) => (
                  <SmallProductCard
                    title={product.product.name}
                    images={product.product.images}
                    price={product.product.price}
                    {...product}
                    key={index}
                    id={product.product.id}
                    isEdit={isEdit}
                    onSelect={(e) => handleProductOnSelect(product.product.id)}
                  />
                ))
            : like.map((product: IProductLike, index) => (
                <SmallProductCard
                  title={product.product.name}
                  images={product.product.images}
                  price={product.product.price}
                  {...product}
                  key={index}
                  isEdit={isEdit}
                  id={product.product.id}
                  onSelect={(e) => handleProductOnSelect(product.product.id)}
                />
              ))}
        </IonGrid>
      ) : (
        <p className="ion-text-center">
          {t("no")} {searchValue ? t("matched") : t("favourite")}{" "}
          {t("products")}
        </p>
      )}

      {isEdit && !showTabBar && (
        <EditFavFooter
          count={selectedProducts.length}
          itemName="product"
          onDelete={handleProductsOnDelete}
        />
      )}
    </>
  );
};

export default FavProductsTab;
