/* eslint-disable no-useless-escape */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonToast,
  useIonViewDidEnter,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline, closeOutline } from "ionicons/icons";
import "./style.scss";
import { useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import TextInput from "../../components/TextInput";
import AvatarUpload from "../../components/AvatarUpload";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import Textarea from "../../components/Textarea";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { usePhotoGallery } from "../../native/usePhotoGallery";
import {
  setShopProfile,
  setUserProfile,
  uploadImageBolb,
} from "../../apis/upload";
import { store } from "../../redux/store";
import { setProfile } from "../../redux/actions/userActions";
import ImageUploadGrid from "../../components/ImageUploadGrid";
import { getMyShop } from "../../apis/shopApi";
import { errorMessage, successMessage } from "../../core/popups";
import { ArrayLength } from "../../core/baseFunction";
import { useTranslation } from "react-i18next";
import { message } from "antd-notifications-messages";

const shopAccount = {
  name: "johnnsss",
  warranty: "",
  description: "hello world",
  email: "john@email.com",
  phone: "12345678",
  icon: "",
  iconID: "",
  shopId: "",
};

const MAX_LEN = 140;
const EditProfilePage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const user = useSelector((e: RootState) => e.user);
  const [images, setImages] = useState<any[]>([]);
  const [oldimages, setOldImages] = useState<any[]>([]);
  const [imagesDelete, setImagesDelete] = useState<string[]>([]);
  const [imagesIds, setImagesIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [present] = useIonToast();
  const [imageId, setImageId] = useState<any>("");
  const [iconId, setIconId] = useState<any>("");
  const [account, setAccount] = useState<any>({});
  const { photos, takePhoto } = usePhotoGallery();
  const { photos: shopPhotos, takePhoto: takeShopPhoto } = usePhotoGallery();
  const role = user.user?.account_type || "customer";
  const [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    bio: "",
    displayName: "",
  });
  useIonViewDidEnter(() => {
    if (role === "shop") {
      getMyShop().then((e) => {
        if (!e.haveError) {
          setAccount({
            ...shopAccount,
            name: user.user?.account?.username ?? "",
            displayName: user.user?.profile?.displayName ?? "",
            email: user.user?.account?.email ?? "",
            phone: user.user?.account?.phoneNumber ?? "",
            bio: user.user?.profile?.bio ?? "",
            description: ArrayLength(e.data) ? e.data[0].description : "",
            warranty: ArrayLength(e.data) ? e.data[0].warranty : "",
            icon: ArrayLength(e.data) ? e.data[0]?.icon?.location : "",
            iconId: ArrayLength(e.data) ? e.data[0]?.iconFileId : "",
            shopId: ArrayLength(e.data) ? e.data[0]?.id : "",
          });
          const img =
            ArrayLength(e.data) && ArrayLength(e.data[0].productPhotos)
              ? e.data[0].productPhotos.map((pho) => ({
                  webviewPath: pho.location,
                  id: pho.id,
                }))
              : [];
          setImages(img);
          const photoIds = img.map((i) => i.id);
          setOldImages([...photoIds]);
          setImagesIds([...photoIds]);
        } else {
          message({
            type: "error",
            // message: st,
            message: t("global_error", {
              Errormessage: `${e.error}`,
            }),
          });
          // errorMessage(e.error);
        }
      });
    } else {
      setAccount({
        name: user.user?.account?.username ?? "",
        email: user.user?.account?.email ?? "",
        phone: user.user?.account?.phoneNumber ?? "",
        bio: user.user?.profile?.bio ?? "",
        displayName: user.user?.profile?.displayName ?? "",
      });
    }
  });

  const handleAccountOnChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.detail.value! });
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    let error = false;
    let img = null;
    const data = {
      name: "",
      bio: "",
      displayName: "",
    };
    const tempError = {
      name: "",
      email: "",
      phone: "",
      bio: "",
      displayName: "",
    };
    // check bio
    if (!account.displayName) {
      error = true;
      tempError.displayName = t("err1");
    } else {
      data.displayName = account.displayName;
    }
    if (!account.bio) {
      error = true;
      tempError.bio = t("err2");
    } else {
      data.bio = account.bio;
    }
    if (imageId != "") {
      img = imageId;
    }
    if (error) {
      setError(tempError);
      return 0;
    }
    setUserProfile(img, data.bio, data.displayName)
      .then((e) => {
        if (!e.haveError) {
          store.dispatch(setProfile(e.data.profile));
          successMessage(t("sucess"));
          history.goBack();
        } else {
          message({
            type: "error",
            // message: st,
            message: t("global_error", {
              Errormessage: `${e.error}`,
            }),
          });
          // errorMessage(e.error);
        }
      })
      .catch((e) => {
        setError(tempError);
      });
  };
  const handleConfirmShop = (e) => {
    e.preventDefault();
    let error = false;
    let img = null;
    const data = {
      name: "",
      bio: "",
      description: "",
      warranty: "",
      displayName: "",
    };
    const tempError = {
      name: "",
      email: "",
      phone: "",
      bio: "",
      description: "",
      warranty: "",
      displayName: "",
    };
    // check bio
    if (!account.bio) {
      error = true;
      tempError.bio = t("err2");
    } else {
      data.bio = account.bio;
    }
    if (!account.description) {
      error = true;
      tempError.description = t("err4");
    } else {
      data.description = account.description;
    }
    if (!account.displayName) {
      error = true;
      tempError.displayName = t("err1");
    } else {
      data.displayName = account.displayName;
    }
    if (!account.warranty) {
      error = true;
      tempError.warranty = t("err3");
      errorMessage(t("err3"));
    } else {
      data.warranty = account.warranty;
    }
    if (imageId != "") {
      img = imageId;
    }
    if (error) {
      setError(tempError);
      return 0;
    }

    setUserProfile(img, data.bio, data.displayName)
      .then((e) => {
        if (!e.haveError) {
          store.dispatch(setProfile(e.data.profile));
          successMessage(t("succes"));
        } else {
          message({
            type: "error",
            // message: st,
            message: t("global_error", {
              Errormessage: `${e.error}`,
            }),
          });
          // errorMessage(e.error);
        }
      })
      .catch((e) => {
        errorMessage("Something went wrong.");
      });
    const dataImag = imagesIds.filter((old) => {
      return !oldimages.find((com) => com === old);
    });
    const dataa: any = {
      description: data.description,
      warranty: data.warranty,
      productPhotos: {
        connect: dataImag,
        disconnect: imagesDelete,
      },
    };
    if (iconId != "") {
      dataa.icon = iconId;
    }
    setShopProfile(account.shopId, dataa)
      .then((e) => {
        if (!e.haveError) {
          successMessage(t("succes"));
          history.goBack();
        } else {
          message({
            type: "error",
            // message: st,
            message: t("global_error", {
              Errormessage: `${e.error}`,
            }),
          });
          // errorMessage(e.error);
        }
      })
      .catch((e) => {
        errorMessage("Something went wrong.");
      });
  };

  // const handleMethodOnSelect = (e, index) => {
  //   const currentContact = [...account.contact];
  //   currentContact[index].method = e.detail.value;
  //   setAccount({
  //     ...account,
  //     contact: currentContact,
  //   });
  // };
  //
  // const handleContactOnEdit = (e, index) => {
  //   const currentContact = [...account.contact];
  //   currentContact[index].contact = e.detail.value;
  //   setAccount({
  //     ...account,
  //     contact: currentContact,
  //   });
  // };
  //
  // const handleContactOnAdd = () => {
  //   const currentContact = [...account.contact];
  //   currentContact.push({ method: '', contact: '' });
  //   setAccount({
  //     ...account,
  //     contact: currentContact,
  //   });
  // };
  //
  // const handleContactOnDelete = (index) => {
  //   const currentContact = [...account.contact];
  //   currentContact.splice(index, 1);
  //   setAccount({
  //     ...account,
  //     contact: currentContact,
  //   });
  // };

  return (
    <NoTabBarLayout>
      <IonPage className="edit-profile-page">
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="login"
              icon={chevronBackOutline}
              text=""
              color="dark"
              mode="ios"
            />
          </IonButtons>
          <IonTitle>{t("editprofile")}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              fill="clear"
              color="primary"
              mode="ios"
              onClick={role != "shop" ? handleConfirm : handleConfirmShop}
            >
              {t("confirm")}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        </IonHeader>
        <IonContent>
          {user.user && (
            <IonGrid>
              <IonRow className="ion-align-items-start">
                <form>
                  {role === "shop" && (
                    <div className="ion-text-center">
                      <AvatarUpload
                        image={shopPhotos[0]?.webviewPath || account?.icon}
                        onClick={() => {
                          takeShopPhoto().then((a) => {
                            if (Array.isArray(a) && a.length > 0) {
                              uploadImageBolb(a[0]).then((e) => {
                                if (!e.haveError) {
                                  setIconId(e.data.id);
                                }
                              });
                            }
                          });
                        }}
                      />
                    </div>
                  )}
                  {role === "shop" && (
                    <p className="subtitle-medium-14 ion-no-margin text-color-grey-lighter">
                      {t("abyoushop")}
                    </p>
                  )}

                  {role === "shop" && (
                    <>
                      {/*<p className="subtitle-medium-14">Categories</p>*/}
                      {/*<IonItem className="bordered-ion-item" lines="none">*/}
                      {/*  <IonSelect*/}
                      {/*    placeholder="Select category"*/}
                      {/*    name="categories"*/}
                      {/*    value={account.categories}*/}
                      {/*    multiple*/}
                      {/*    cancelText="Cancel"*/}
                      {/*    okText="OK"*/}
                      {/*    onIonChange={handleAccountOnChange}*/}
                      {/*    interfaceOptions={{ header: 'Category' }}*/}
                      {/*  >*/}
                      {/*    {categories.map((category, index) => (*/}
                      {/*      <IonSelectOption key={index} value={category.id} className="ion-text-capitalize">*/}
                      {/*        {category.name}*/}
                      {/*      </IonSelectOption>*/}
                      {/*    ))}*/}
                      {/*  </IonSelect>*/}
                      {/*</IonItem>*/}

                      {/*<div className="contact-header">*/}
                      {/*  <p className="subtitle-medium-14">Contact Methods</p>*/}
                      {/*  <IconButton*/}
                      {/*    slot="end"*/}
                      {/*    size="small"*/}
                      {/*    color="medium"*/}
                      {/*    fill="clear"*/}
                      {/*    onClick={handleContactOnAdd}*/}
                      {/*    iconProps={{*/}
                      {/*      icon: addCircleOutline,*/}
                      {/*      size: 'small',*/}
                      {/*      slot: 'start',*/}
                      {/*    }}*/}
                      {/*    title="Add"*/}
                      {/*  />*/}
                      {/*</div>*/}
                      {/*<IonGrid className="contact-container">*/}
                      {/*  {account.contact?.map((item, index) => (*/}
                      {/*    <IonRow key={index}>*/}
                      {/*      <IonCol className="select-menu-container">*/}
                      {/*        <IonItem className="bordered-ion-item" lines="none">*/}
                      {/*          <IonSelect*/}
                      {/*            placeholder="Method"*/}
                      {/*            name="method"*/}
                      {/*            value={item.method}*/}
                      {/*            cancelText="Cancel"*/}
                      {/*            okText="OK"*/}
                      {/*            interface="popover"*/}
                      {/*            onIonChange={(e) => handleMethodOnSelect(e, index)}*/}
                      {/*          >*/}
                      {/*            {contactMethods.map((method, index) => (*/}
                      {/*              <IonSelectOption key={index} value={method} className="ion-text-capitalize">*/}
                      {/*                {method}*/}
                      {/*              </IonSelectOption>*/}
                      {/*            ))}*/}
                      {/*          </IonSelect>*/}
                      {/*        </IonItem>*/}
                      {/*      </IonCol>*/}
                      {/*      <IonCol>*/}
                      {/*        <TextInput*/}
                      {/*          placeholder="Contact Info"*/}
                      {/*          className="contact-input"*/}
                      {/*          name="name"*/}
                      {/*          value={item.contact}*/}
                      {/*          onChange={(e) => handleContactOnEdit(e, index)}*/}
                      {/*        />*/}
                      {/*      </IonCol>*/}
                      {/*      <IonCol size="1" className="remove-btn-container">*/}
                      {/*        <div className="remove-btn-innercontainer">*/}
                      {/*          <IconButton*/}
                      {/*            iconProps={{ icon: closeOutline }}*/}
                      {/*            fill="clear"*/}
                      {/*            color="medium"*/}
                      {/*            size="small"*/}
                      {/*            onClick={() => handleContactOnDelete(index)}*/}
                      {/*          />*/}
                      {/*        </div>*/}
                      {/*      </IonCol>*/}
                      {/*    </IonRow>*/}
                      {/*  ))}*/}
                      {/*</IonGrid>*/}

                      <p className="subtitle-medium-14">{t("shopdesc")}</p>
                      <Textarea
                        className="terms-cond-input"
                        name="description"
                        value={account.description || ""}
                        onChange={handleAccountOnChange}
                        maxlength={MAX_LEN}
                      ></Textarea>
                      <p className="subtitle-medium-14">{t("warranty")}</p>
                      <Textarea
                        className="terms-cond-input"
                        name="warranty"
                        value={account.warranty || ""}
                        onChange={handleAccountOnChange}
                        maxlength={MAX_LEN}
                      ></Textarea>
                      {/* <p className="subtitle-medium-14">Images</p>
                      <ImageUploadGrid
                        images={images}
                        ids={imagesIds} onUpdate={(id, im, del)=>{
                          setImages(im);
                          setImagesIds(id);
                          if(del){
                            const fildOld = oldimages.find(e=> e === del);
                            if(fildOld){
                              const imgdel = imagesDelete;
                              imgdel.push(del);
                              setImagesDelete(imgdel);
                            }
                          }
                      }} setLoading={(e)=>setLoading(e)}/> */}
                    </>
                  )}
                  {/* <div className="ion-text-center">
                    <AvatarUpload
                      image={
                        photos[0]?.webviewPath ||
                        user.user?.profile?.avatar?.location
                      }
                      onClick={() => {
                        takePhoto().then((a) => {
                          if (Array.isArray(a) && a.length > 0) {
                            uploadImageBolb(a[0]).then((e) => {
                              if (!e.haveError) {
                                setImageId(e.data.id);
                              }
                            });
                          }
                        });
                      }}
                    />
                  </div> */}
                  {role === "shop" && (
                    <p className="subtitle-medium-14 ion-no-margin text-color-grey-lighter">
                      {t("aboyouself")}
                    </p>
                  )}
                  <p
                    className={`subtitle-medium-14 ion-no-margin ${
                      role === "shop" && "special-subtitle"
                    }`}
                  >
                    {t("eruserName")}
                  </p>
                  <TextInput
                    disabled={true}
                    name="name"
                    value={user.user?.account.username || ""}
                    error={error.name}
                    onChange={handleAccountOnChange}
                    inputmode="text"
                  />
                  <p
                    className={`subtitle-medium-14 ion-no-margin ${
                      role === "shop" && "special-subtitle"
                    }`}
                  >
                    {t("dipslayname")}
                  </p>
                  <TextInput
                    name="displayName"
                    value={account.displayName || ""}
                    error={error.displayName}
                    onChange={handleAccountOnChange}
                    inputmode="text"
                  />
                  <p
                    className={`subtitle-medium-14 ion-no-margin ${
                      role === "shop" && "special-subtitle"
                    }`}
                  >
                    {t("emailsig")}
                  </p>
                  <TextInput
                    disabled={true}
                    name="email"
                    value={account.email || ""}
                    error={error.email}
                    onChange={handleAccountOnChange}
                    inputmode="email"
                  />
                  <p className="subtitle-medium-14 ion-no-margin">
                    {t("phone")}
                  </p>
                  <TextInput
                    disabled={true}
                    name="phone"
                    value={account.phone || ""}
                    error={error.phone}
                    onChange={handleAccountOnChange}
                    inputmode="tel"
                  />
                  <p className="subtitle-medium-14 ion-no-margin">{t("bio")}</p>
                  <Textarea
                    className="terms-cond-input"
                    name="bio"
                    error={error.bio}
                    value={account.bio || ""}
                    onChange={handleAccountOnChange}
                    maxlength={120}
                  ></Textarea>
                </form>
              </IonRow>
            </IonGrid>
          )}
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default EditProfilePage;
