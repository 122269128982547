import { IonCard, IonCheckbox } from '@ionic/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { IProductWithStore } from '../../types/ProductTypes';
import ImageWrapper from '../ImageWrapper';
import ShopHeader from '../ShopHeader';
import './style.scss';
import {getProductsByStoreId} from "../../apis/productApis";

interface FavShopCardProps {
  products?: { id: number; src?: string }[];
  isEdit: boolean;
  shop: any,
  onSelect: (id: any) => void;
}

type PropsType = FavShopCardProps & IProductWithStore;

const FavShopCard: React.FC<PropsType> = (props) => {
  const [isSelected, setIsSelected] = useState(false);
  const [product, setProduct] = useState<any>([]);
  useEffect(() => {
    if (props.isEdit) {
      setIsSelected(false);
    }
  }, [props.isEdit]);
  useEffect(()=>{
    getProductsByStoreId(props?.shop?.id, 0, 4).then((e)=>{
      if( !e.haveError ){
        setProduct(e.data);
      }
    });
  },[]);
  const handleOnSelect = () => {
    props.onSelect(props?.shop?.id);
    setIsSelected(!isSelected);
  };

  return (
    <div className={`fav-shop-card-container ${props.isEdit ? '' : 'slide-back'}`} onClick={handleOnSelect}>
      {props.isEdit && <IonCheckbox mode="ios" className="shop-circle" checked={isSelected}></IonCheckbox>}

      <IonCard className={`${props.isEdit && 'slide'}`}>
        <ShopHeader className="ion-padding" {...props.shop} />

        {product && product.length > 0 ? (
          <div className={`fav-shop-images`}>
            {product.map((e, index) => {
              return (
                <ImageWrapper src={ Array.isArray(e?.images) && e.images.length > 0? e.images[0].location: ''} className="shop-product-image" key={index} />
              );
            })}
          </div>
        ) : (
          <p className="ion-text-center ion-padding">No products yet</p>
        )}
      </IonCard>
    </div>
  );
};

export default FavShopCard;
