import { IonItem, IonTextarea } from '@ionic/react';
import ErrorNote from '../ErrorNote';
import './style.scss';

interface TextAreaProps {
  name?: string;
  placeholder?: string;
  value: string;
  clearInput?: boolean;
  error?: string;
  className?: string;
  maxlength?: number | undefined;
  inputmode?:
    | 'decimal'
    | 'email'
    | 'none'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url'
    | undefined;
  onChange?: (event: CustomEvent) => void;
}

const Textarea: React.FC<TextAreaProps> = (props) => {
  return (
    <>
      <div className={`textarea-container ${props.className}`}>
        <IonItem className="input" lines="none">
          <IonTextarea
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            onIonChange={props.onChange}
            maxlength={props.maxlength}
            inputmode={props.inputmode}
            autoGrow
            mode="md"
          ></IonTextarea>
        </IonItem>
        {props.error && <ErrorNote error={props.error} />}
      </div>
      {props.maxlength && (
        <p className="word-count ion-text-right text-color-grey-lightest">
          {props.maxlength - props.value.length} characters left
        </p>
      )}
    </>
  );
};

export default Textarea;
