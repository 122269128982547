import { IonIcon } from '@ionic/react';
import { imageOutline, pencilSharp } from 'ionicons/icons';
import IconButton from '../IconButton';
import './style.scss';

interface ImageUploadProps {
  image?: string;
  onEdit?: () => void;
  onUpload?: () => void;
  onUploaded?: () => void;
}

const ImageUpload: React.FC<ImageUploadProps> = (props) => {
  return (
    <div className="image-upload-container">
      {props.image ? (
        <div
          className="image-container"
          onClick={props.onEdit}
          style={{
            backgroundImage: `url(${props.image})`,
            backgroundSize: 'cover',
            backgroundPosition: '50%',
          }}
        >
          <IconButton
            color="light"
            iconProps={{ icon: pencilSharp }}
            className="upload-button"
            size="small"
          />
        </div>
      ) : (
        <div className="add-image-btn" onClick={props.onUpload}>
          <IonIcon icon={imageOutline} size="small"></IonIcon>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
