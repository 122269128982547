import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonHeader
} from "@ionic/react";
import {
  addCircleOutline,
  calendarOutline,
  chevronBackOutline,
  closeOutline,
} from "ionicons/icons";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { Link, useHistory } from "react-router-dom";
import IconButton from "../../components/IconButton";
import Textarea from "../../components/Textarea";
import TextInput from "../../components/TextInput";
import AvatarUpload from "../../components/AvatarUpload";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import ImageUploadGrid from "../../components/ImageUploadGrid";

import "./style.scss";
import { usePhotoGallery } from "../../native/usePhotoGallery";
import { uploadImageBolb } from "../../apis/upload";
import { getCategories } from "../../apis/categoryApis";
import { message } from "antd-notifications-messages";
import { postShop } from "../../apis/productApis";
import { useTranslation } from "react-i18next";

// fake data
const categories = [
  "Sports",
  "Accessories",
  "Beauty",
  "Clothes",
  "Instruments",
  "Electronics",
  "Arts",
];

const contactMethods = [
  "Instagram",
  "Facebook",
  "MeWe",
  "Whatsapp",
  "Signal",
  "Line",
  "Twitter",
];

/***************************************************************************
 * First Page
 **************************************************************************/
const FirstPage = (props: any) => {
  const { i18n, t } = useTranslation();
  return (
    <IonContent>
      <p className="text-color-grey-lighter ion-text-center form-description-14">
        {t("fillThisForm")}
      </p>
      <IonGrid>
        <IonRow className="ion-align-items-start">
          <form>
            <div className="ion-text-center">
              <AvatarUpload
                image={props.icon[0]?.webviewPath}
                onClick={props.handleAvatarChange}
              />
            </div>

            <p className="subtitle-medium-14 ion-no-margin">{t("shopName")}</p>
            <TextInput
              name="name"
              value={props.shop.name}
              onChange={props.handleInputOnChange}
            />

            <p className="subtitle-medium-14">{t("foundDate")}</p>
            <IonItem className="bordered-ion-item" lines="none">
              <IonDatetime
                name="foundDate"
                placeholder={t("selectDate")}
                value={props.shop.foundDate}
                onIonChange={props.handleInputOnChange}
              ></IonDatetime>
              <IonIcon
                slot="end"
                icon={calendarOutline}
                color="medium"
              ></IonIcon>
            </IonItem>

            <p className="subtitle-medium-14">{t("cat")}</p>
            <IonItem className="bordered-ion-item" lines="none">
              <IonSelect
                placeholder={t("whatSell")}
                name="categories"
                value={props.shop.categories}
                multiple
                cancelText="Cancel"
                okText="OK"
                onIonChange={props.handleInputOnChange}
                interfaceOptions={{ header: "Category" }}
              >
                {props.categries.map((category, index) => (
                  <IonSelectOption
                    key={index}
                    value={category.id}
                    className="ion-text-capitalize"
                  >
                   {t(category.name.toLowerCase())}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            <div className="contact-header">
              <p className="subtitle-medium-14">{t("contactMethods")}</p>
              <IconButton
                slot="end"
                size="small"
                color="medium"
                fill="clear"
                onClick={props.handleContactOnAdd}
                iconProps={{
                  icon: addCircleOutline,
                  size: "small",
                  slot: "start",
                }}
                title={t("add")}
              />
            </div>
            <p className="text-color-grey-lighter description ion-no-margin">
              {t("oneContactMethod")}
            </p>
            <IonGrid className="contact-container">
              {props.shop.contactMethods.map((item, index) => (
                <IonRow key={index}>
                  <IonCol className="select-menu-container">
                    <IonItem className="bordered-ion-item" lines="none">
                      <IonSelect
                        placeholder={t("method")}
                        name="method"
                        value={item.key}
                        cancelText={t("cancel")}
                        okText="OK"
                        interfaceOptions={{ header: "Category" }}
                        // interface="popover"
                        onIonChange={(e) =>
                          props.handleMethodOnSelect(e, index)
                        }
                      >
                        {contactMethods.map((method, index) => (
                          <IonSelectOption
                            key={index}
                            value={method}
                            className="ion-text-capitalize"
                          >
                            {method}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <TextInput
                      placeholder={t("contactInfo")}
                      className="contact-input"
                      name="name"
                      value={item.value}
                      onChange={(e) => props.handleContactOnEdit(e, index)}
                    />
                  </IonCol>
                  <IonCol size="1" className="remove-btn-container">
                    <div className="remove-btn-innercontainer">
                      <IconButton
                        iconProps={{ icon: closeOutline }}
                        fill="clear"
                        color="medium"
                        size="small"
                        onClick={() => props.handleContactOnDelete(index)}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>

            <p className="subtitle-medium-14">{t("ProductImages")}</p>
            <p className="text-color-grey-lighter description ion-no-margin">
              {t("optionalIncreaseChance")}
            </p>

            <ImageUploadGrid
              onUpdate={props.updatePhotos}
              setLoading={props.setLoading}
              ids={props.imagesIds}
              images={props.photos}
            />
          </form>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

/***************************************************************************
 * Second Page
 **************************************************************************/
const ThirdPage = (props: any) => {
  const { i18n, t } = useTranslation();
  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-align-items-start">
          <form className="terms-cond-container">
            <p className="subtitle-medium-14 ion-no-margin">{t("warranty")}</p>
            <Textarea
              className="terms-cond-input"
              name="warranty"
              value={props.shop.warranty}
              onChange={props.handleInputOnChange}
            ></Textarea>

            <div className="text-color-grey-lightest agreement-container ion-text-center">
              <IonCheckbox
                checked={props.term}
                onIonChange={(e) => {
                  props.setTerm(e.detail.checked);
                }}
              />
              {t("iAgree")}&nbsp;
              <Link
                to="/terms-of-conditions"
                color="medium"
                className="terms-of-conditions text-color-grey-lightest"
              >
                <span>{t("termsConditions")}</span>
              </Link>
              {/* {error.agree && <ErrorNote error={error.agree} align="center" />} */}
            </div>
          </form>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
const SecondPage = (props: any) => {
  const { i18n, t } = useTranslation();
  return (
    <IonContent>
      <IonGrid>
        <IonRow className="ion-align-items-start">
          <form className="terms-cond-container">
            <p className="subtitle-medium-14 ion-no-margin">
              {t("description")}
            </p>
            <Textarea
              className="terms-cond-input"
              name="description"
              value={props.shop.description}
              onChange={props.handleInputOnChange}
            ></Textarea>
          </form>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

const BecomeShopOwnerPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const { photos: icon, takePhoto: iconTake } = usePhotoGallery();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState(false);
  const [photos, setphotos]: any = useState([]);
  const [categries, setCategeries]: any = useState([]);
  const history = useHistory();
  const [shop, setShop] = useState({
    name: "",
    foundDate: "",
    icon: "",
    description: "",
    categories: [],
    contactMethods: [{ key: "", value: "" }],
    productPhotos: [] as string[],
    warranty: "",
  });
  useIonViewWillEnter(() => {
    getCategories(100).then((e) => {
      if (Array.isArray(e)) {
        setCategeries(e);
      }
    });
  });
  const [page, setPage] = useState(1);
  const errorMessage = (st: string) => {
    message({
      type: "error",
      // message: st,
      message: t("global_error", {
        Errormessage: `${st}`,
      }),
    });
  };
  const successMessage = (st: string) => {
    message({
      type: "success",
      message: st,
    });
  };
  const handleSubmit = () => {
    if (shop.contactMethods.length === 0) {
      errorMessage(t("leastContact"));
    }
    if (shop.contactMethods[0].key.length === 0) {
      errorMessage(t("leastContact"));
    } else if (shop.icon.length === 0) {
      errorMessage(t("profileImage"));
    } else if (shop.name.length === 0) {
      errorMessage(t("nameRequired"));
    } else if (shop.categories.length === 0) {
      errorMessage(t("categoriesRequired"));
    } else if (shop.foundDate.length === 0) {
      errorMessage(t("foundDateRequired"));
    } else if (shop.description.length === 0) {
      errorMessage(t("descriptionRequired"));
    } else if (shop.warranty.length === 0) {
      errorMessage(t("warrantyRequired"));
    } else {
      setLoading(true);
      postShop(shop).then((e) => {
        setLoading(false);
        if (e.haveError) {
          errorMessage(e.error[0]);
        } else {
          successMessage(t("shopRequestSubmitted"));
          history.goBack();
        }
      });
    }
  };
  const handleAvatarChange = (e) => {
    iconTake().then((a) => {
      if (Array.isArray(a) && a.length > 0) {
        setLoading(true);
        uploadImageBolb(a[0]).then((e) => {
          setLoading(false);
          if (!e.haveError) {
            setShop((c) => ({ ...c, icon: e.data.id }));
          } else {
            errorMessage(t("InternetProblem"));
          }
        });
      }
    });
  };
  const handleInputOnChange = (e) => {
    setShop({ ...shop, [e.target.name]: e.detail.value });
  };
  const updatePhotos = (ids: string[], images: any[]) => {
    console.log(ids, images);
    setShop((e) => {
      return { ...e, productPhotos: ids };
    });
    setphotos(images);
  };

  const handleMethodOnSelect = (e, index) => {
    const currentContact = [...shop.contactMethods];
    currentContact[index].key = e.detail.value;
    setShop({
      ...shop,
      contactMethods: currentContact,
    });
  };

  const handleContactOnEdit = (e, index) => {
    const currentContact = [...shop.contactMethods];
    currentContact[index].value = e.detail.value;
    setShop({
      ...shop,
      contactMethods: currentContact,
    });
  };

  const handleContactOnAdd = () => {
    const currentContact = [...shop.contactMethods];
    currentContact.push({ key: "", value: "" });
    setShop({
      ...shop,
      contactMethods: currentContact,
    });
  };

  const handleContactOnDelete = (index) => {
    const currentContact = [...shop.contactMethods];
    currentContact.splice(index, 1);
    setShop({
      ...shop,
      contactMethods: currentContact,
    });
  };

  return (
    <NoTabBarLayout>
      <IonPage className="become-shop-owner-page">
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="owner-settings"
              icon={chevronBackOutline}
              text=""
              color="dark"
            />
          </IonButtons>
          <IonTitle>{t("becomeShopOwner")}</IonTitle>
        </IonToolbar>
        </IonHeader>
        {page === 1 ? (
          <FirstPage
            shop={shop}
            photos={photos}
            icon={icon}
            setLoading={setLoading}
            imagesIds={shop.productPhotos}
            categries={categries}
            updatePhotos={updatePhotos}
            handleAvatarChange={handleAvatarChange}
            handleInputOnChange={handleInputOnChange}
            handleMethodOnSelect={handleMethodOnSelect}
            handleContactOnEdit={handleContactOnEdit}
            handleContactOnAdd={handleContactOnAdd}
            handleContactOnDelete={handleContactOnDelete}
          />
        ) : page == 2 ? (
          <SecondPage shop={shop} handleInputOnChange={handleInputOnChange} />
        ) : (
          <ThirdPage
            term={term}
            setTerm={setTerm}
            shop={shop}
            handleInputOnChange={handleInputOnChange}
          />
        )}

        <IonFooter className="ion-no-border footer">
          <IonToolbar>
            {page === 1 ? (
              <IonButton
                color="primary"
                fill="solid"
                expand="block"
                mode="ios"
                className="ion-margin"
                onClick={() => {
                  if (shop.contactMethods.length === 0) {
                    errorMessage(t("leastContact"));
                  } else if (shop.contactMethods[0].key.length === 0) {
                    errorMessage(t("leastContact"));
                  } else if (shop.icon.length === 0) {
                    errorMessage(t("profileImage"));
                  } else if (shop.name.length === 0) {
                    errorMessage(t("nameRequired"));
                  } else if (shop.categories.length === 0) {
                    errorMessage(t("categoriesRequired"));
                  } else if (shop.foundDate.length === 0) {
                    errorMessage(t("foundDateRequired"));
                  } else {
                    setPage(2);
                  }
                }}
              >
                {t("next")}
                {loading && (
                  <IonSpinner name="circles" style={{ marginLeft: "5px" }} />
                )}
              </IonButton>
            ) : (
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <IonButton
                      color="light"
                      fill="solid"
                      expand="block"
                      mode="ios"
                      onClick={() => setPage(page - 1)}
                    >
                      {t("back")}
                    </IonButton>
                  </IonCol>
                  {page == 3 ? (
                    <IonCol>
                      <IonButton
                        color="primary"
                        fill="solid"
                        expand="block"
                        mode="ios"
                        disabled={loading || !term}
                        onClick={() => handleSubmit()}
                      >
                        {t("submit")}
                        {loading && (
                          <IonSpinner
                            name="circles"
                            style={{ marginLeft: "5px" }}
                          />
                        )}
                      </IonButton>
                    </IonCol>
                  ) : (
                    <IonButton
                      color="primary"
                      fill="solid"
                      expand="block"
                      mode="ios"
                      className="ion-margin"
                      disabled={loading}
                      onClick={() => {
                        if (shop.description.length === 0) {
                          errorMessage(t("descriptionRequired"));
                        } else {
                          setPage(3);
                        }
                      }}
                    >
                      {t("next")}
                    </IonButton>
                  )}
                </IonRow>
              </IonGrid>
            )}
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default BecomeShopOwnerPage;
