import {
  ILoginFailureAction,
  ILoginSuccessAction,
  IResetUserAction,
  IUser,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  RESET_USER,
  SET_USER_PROFILE
} from '../../types/UserTypes';

export const setUser = (user: IUser): ILoginSuccessAction => ({
  type: LOGIN_SUCCESS,
  data: user,
});

export const setUserError = (error: string): ILoginFailureAction => ({
  type: LOGIN_FAILURE,
  data: error,
});
export const setProfile = (user) => ({
  type: SET_USER_PROFILE,
  data: user,
});

export const resetUser = (): IResetUserAction => ({ type: RESET_USER });
