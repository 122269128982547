import { IonApp, IonRouterOutlet, isPlatform } from "@ionic/react";

/* CSS variables */
import "./theme/main.scss";
// import "@ionic/react/css/ionic-swiper.css";
/* Components */
import { UIProvider } from "./utils/UIContext";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router";
import { history } from "./utils/history";
import RoutingPage from "./routes/RoutingPage";
import { customQueryClient } from "./config/reactQuery";
import { QueryClientProvider } from "react-query";
import { useEffect } from "react";
import axios from "axios";
import { getMyFollowShops, getMyLikedProduct } from "./apis/LikeApi";
import { store } from "./redux/store";
import { setFollow, setLikes } from "./redux/actions/likeActions";
import { getMe, refreshToken } from "./apis/AuthApi";
import { getToken } from "./core/baseFunction";
import { setUser } from "./redux/actions/userActions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const App: React.FC = () => {
  const { i18n, t } = useTranslation();
  interface RootState {
    lang: {
      lang: string;
    };
  }
  const languageData = useSelector((state: RootState) => state.lang.lang);
  useEffect(() => {
    const langLocale = localStorage.getItem("language");
    if (langLocale !== null) {
      i18n.changeLanguage(langLocale);
    } else {
      i18n.changeLanguage(languageData);
    }
    // console.log("L", langLocale);

    if (getToken() != null && getToken() != "") {
      refreshToken().then((e) => {
        if (e) {
          getMe().then((e) => {
            if (!e.haveError) {
              store.dispatch(setUser(e.data));
              getMyLikedProduct().then((e) => {
                if (!e.haveError) {
                  store.dispatch(setLikes(e.data));
                }
              });
              getMyFollowShops().then((e) => {
                if (!e.haveError) {
                  store.dispatch(setFollow(e.data));
                }
              });
            }
          });
        }
      });
    }
  }, []);
  return (
    <QueryClientProvider client={customQueryClient}>
      <UIProvider>
        <IonApp style={{ marginTop: isPlatform("ios") ? "2.5rem" : "" }}>
          <IonReactRouter history={history}>
            <IonRouterOutlet>
              <Route path="/" component={RoutingPage} />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </UIProvider>
    </QueryClientProvider>
  );
};

export default App;
