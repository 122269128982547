import { IonActionSheet, IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useState } from "react";
import ImageUpload from "../ImageUpload";
import "./style.scss";
import { uploadImageBolb } from "../../apis/upload";
import { usePhotoGallery } from "../../native/usePhotoGallery";
import { errorMessage } from "../../core/popups";
import { useTranslation } from "react-i18next";
interface ImageUploadGridProps {
  images: any[];
  setLoading: (e: boolean) => void;
  ids: string[];
  onUpdate: (ids: string[], images: any[], del: string | null) => void;
}

const ImageUploadGrid: React.FC<ImageUploadGridProps> = (props) => {
  const { i18n, t } = useTranslation();
  const { takePhoto, deletePhoto } = usePhotoGallery();
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number>(-1);
  const handleImagesChange = () => {
    takePhoto().then((a) => {
      if (Array.isArray(a) && a.length > 0) {
        props.setLoading(true);
        uploadImageBolb(a[0]).then((e) => {
          props.setLoading(false);
          if (!e.haveError) {
            deletePhoto(0);
            const im = [...props.ids];
            im.push(e?.data?.id);
            const pp = props.images;
            pp.push(a[0]);
            props.onUpdate(im, pp, null);
          } else {
            errorMessage(t("network_Error"));
          }
        });
      }
    });
  };
  const handleEditImagesChange = (index) => {
    takePhoto().then((a) => {
      if (Array.isArray(a) && a.length > 0) {
        props.setLoading(true);
        uploadImageBolb(a[0]).then((e) => {
          props.setLoading(false);
          if (!e.haveError) {
            deletePhoto(0);
            const im = props.ids;
            const del = im[index];
            im[index] = e?.data.id;
            const pp = props.images;
            pp[index] = a[0];
            props.onUpdate(im, pp, del);
          } else {
            errorMessage(t("network_Error"));
          }
        });
      }
    });
  };
  const handleDeleteImagesChange = (index) => {
    deletePhoto(0);
    const im = props.ids;
    const del = im[index];
    im.splice(index, 1);
    const pp = props.images;
    pp.splice(index, 1);
    props.onUpdate(im, pp, del);
  };
  const renderProductImagesGrid = () => {
    const rows: any = [];
    for (let index = 0; index <= props.images.length + 1; index += 3) {
      let numOfItems = 3;
      const cols: any = [];
      if (index === 0) {
        cols.push(
          <IonCol size="3" className="ion-justify-content-center" key={-1}>
            <ImageUpload onUpload={handleImagesChange} />
          </IonCol>
        );
      } else {
        numOfItems = 4;
      }
      for (let i = 0; i < numOfItems; i++) {
        cols.push(
          <IonCol
            size="3"
            className="ion-justify-content-center"
            key={index + i}
          >
            {props.images[index + i] && (
              <ImageUpload
                key={index}
                image={props.images[index + i]?.webviewPath}
                onEdit={() => {
                  setSelectedImage(index + i);
                  setIsActionSheetOpen(true);
                }}
              />
            )}
          </IonCol>
        );
      }
      rows.push(<IonRow key={index}>{cols}</IonRow>);
    }
    return rows;
  };

  return (
    <IonGrid>
      {props.images.length + 1 > 0 ? (
        renderProductImagesGrid()
      ) : (
        <IonRow>
          <IonCol>
            <ImageUpload onUpload={handleImagesChange} />
          </IonCol>
        </IonRow>
      )}
      <IonActionSheet
        mode="ios"
        isOpen={isActionSheetOpen}
        onDidDismiss={() => setIsActionSheetOpen(false)}
        buttons={[
          {
            text: "Replace",
            role: "replace",
            handler: () => {
              handleEditImagesChange(selectedImage);
            },
            cssClass: "text-color-grey",
          },
          {
            text: "Delete",
            role: "destructive",
            handler: () => {
              handleDeleteImagesChange(selectedImage);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "text-color-grey",
          },
        ]}
      ></IonActionSheet>
    </IonGrid>
  );
};

export default ImageUploadGrid;
