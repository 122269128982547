import { IonAvatar } from '@ionic/react';
import { cameraOutline } from 'ionicons/icons';
import IconButton from '../IconButton';
import './style.scss';

interface AvatarUploadProps {
  image?: string;
  onClick?: () => void;
}

const AvatarUpload: React.FC<AvatarUploadProps> = (props) => {
  return (
    <div className="avatar-upload-container" onClick={props.onClick}>
      {props.image ? (
        <IonAvatar className="avatar">
          <img src={props.image} />
        </IonAvatar>
      ) : (
        <IonAvatar className="empty-image">No Image</IonAvatar>
      )}
      <IconButton
        color="light"
        iconProps={{ icon: cameraOutline }}
        size="small"
        className="upload-button"
        onClick={props.onClick}
      />
    </div>
  );
};

export default AvatarUpload;
