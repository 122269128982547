import { IonAvatar, IonIcon } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import { IStore } from '../../types/StoreTypes';
import './style.scss';
import {TShop} from "../../pages/CategoryPage";

interface ShopHeaderProps {
  className?: string;
}
type TShopHeader = {
  name: string,
  verified: boolean,
  icon: {
    location: string
}
}
type PropsType = ShopHeaderProps & TShopHeader;

const ShopHeader: React.FC<PropsType> = (props) => {
  return (
    <div className={`shop-name-container ${props.className}`}>
      <div className="small-avatar-container">
        <IonAvatar className="small-avatar">
          <img src={props?.icon?.location ? props?.icon?.location : 'https://via.placeholder.com/150'} />
        </IonAvatar>
      </div>
      <div className="shop-name text-color-grey-title line-clamp" style={{ WebkitLineClamp: 1 }}>
        {props.name}
      </div>
      {props.verified && (
        <div className="checkmark-container">
          <IonIcon icon={checkmarkCircle} className="checkmark" color="primary"></IonIcon>
        </div>
      )}
    </div>
  );
};

export default ShopHeader;
