/* eslint-disable no-useless-escape */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonSpinner,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
  useIonViewDidLeave,
  useIonViewWillEnter,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import "./style.scss";
import { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import TextInput from "../../components/TextInput";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import { message } from "antd-notifications-messages";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { sendOtp, verifyOtp } from "../../apis/AuthApi";
import { errorMessage } from "../../core/popups";

const createCodeArray = () => {
  const code: string[] = [];
  for (let i = 0; i < 6; i++) {
    code.push("");
  }
  return code;
};

const VerifyPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const [code, setCode] = useState(createCodeArray());
  const [verifycode, setVerifyCode] = useState("");
  const fromRef: any = useRef(null);
  const [endTime, setEndTime] = useState<Date>(
    new Date(new Date().getTime() + 4 * 60000)
  );
  const [now, setSetNow] = useState("4:00");
  const [loading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const x = setInterval(function () {
      // Get today's date and time
      setEndTime((end) => {
        const now = new Date().getTime();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const distance = new Date(end as Date) - now;

        // Time calculations for days, hours, minutes and seconds
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"
        setSetNow(minutes + ":" + seconds);
        if (distance < 0) {
          setSetNow("0:00");
        } else {
          setSetNow(minutes + ":" + seconds);
        }
        return end;
      });
    }, 1000);
    return () => clearInterval(x);
  }, [endTime]);

  useIonViewWillEnter(() => {
    otpSend();
  });

  const otpSend = () => {
    sendOtp().then((e) => {
      if (e.error) {
        message({
          type: "error",
          // message: st,
          message: t("global_error", {
            Errormessage: `${e.error}`,
          }),
        });
        // errorMessage(e.error);
      }
      console.log(e);
    });
  };

  const handleCodeOnChange = (e) => {
    console.log(e.target.value);
    if (e.target.value != "") {
      if (Number(e.target.name) < 5) {
        document
          .getElementsByName((Number(e.target.name) + 1).toString())[1]
          ?.focus();
      }
    }
    const updatedCode = [...code];
    updatedCode[Number(e.target.name)] = e.detail.value!;
    setCode(updatedCode);
  };

  // TODO API Verify
  const verify = (e) => {
    e.preventDefault();
    let inputtedCode = "";
    code.forEach((digit) => (inputtedCode += digit));
    // props.history.push('/home');
    if (verifycode.trim().length <= 0) {
      message({
        type: "error",
        message: t("pleaseDigit"),
      });
    } else {
      setIsLoading(true);
      verifyOtp(verifycode).then((e) => {
        setIsLoading(false);
        if (!e.haveError) {
          setIsLoading(false);
          message({
            type: "success",
            message: t("verifySuccess"),
          });
          const path = "/home";
          history.push(path);
        } else {
          message({
            type: "error",
            // message: st,
            message: t("global_error", {
              Errormessage: `${e.error}`,
            }),
          });
          // errorMessage(e.error);
        }
      });
    }
  };

  // TODO: countdown
  return (
    <NoTabBarLayout>
      <IonPage className="auth-page verify-page">
      <IonHeader>

        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="signup"
              icon={chevronBackOutline}
              color="medium"
              text=""
              mode="ios"
            />
          </IonButtons>
        </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonGrid>
            <IonRow>
              <div className="ion-text-center">
                <p className="auth-heading">{t("checkPhone")}</p>
                <IonText color="secondary">{t("sentCode")}</IonText>
                <form ref={fromRef} onSubmit={verify} className="">
                  <TextInput
                    name="verifycode"
                    placeholder={t("pleaseDigit")}
                    maxlength={6}
                    value={verifycode}
                    clearInput
                    onChange={(e) => {
                      setVerifyCode(e.detail.value);
                    }}
                    inputmode="numeric"
                  />
                  {/* {code.map((digit, index) => (
                    <TextInput
                      key={index}
                      name={String(index)}
                      value={digit}
                      onChange={handleCodeOnChange}
                      className="digit-input"
                      maxlength={1}
                      inputmode="numeric"
                    />
                  ))} */}
                  <div className="text-color-grey ion-padding">
                    {t("codeExpires")}&nbsp;
                    <span className="text-color-grey-title time">{now}</span>
                  </div>

                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    type="submit"
                    className="auth-button ion-margin-bottom"
                    mode="ios"
                  >
                    {t("verify")}
                    {loading && (
                      <IonSpinner
                        name="circles"
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </IonButton>
                  <IonButton
                    color="medium"
                    fill="outline"
                    shape="round"
                    expand="block"
                    className="auth-button ion-margin-top"
                    onClick={() => {
                      otpSend();
                      setEndTime(new Date(new Date().getTime() + 4 * 60000));
                    }}
                    mode="ios"
                  >
                    {t("sendAgain")}
                  </IonButton>
                </form>
              </div>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default VerifyPage;
