import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonToggle,
  IonSegment,
  IonSegmentButton,
  useIonViewWillEnter,
} from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { Link, useHistory } from "react-router-dom";
import "./style.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { useState } from "react";
import { store } from "../../redux/store";
import { changeLanguage } from "../../redux/actions/languageAction";
import { useTranslation } from "react-i18next";
const GuestSettingsPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const user = useSelector((e: RootState) => e.user.user);
  const [locale, setLocale] = useState("en");
  const history = useHistory();
  useIonViewWillEnter(() => {
    const lang = localStorage.getItem("locale");
    if (lang != null) {
      setLocale(lang);
    }
  });
  const saveSetting = (locale) => {
    setLocale(locale);
    store.dispatch(changeLanguage(locale));
    localStorage.setItem("language", locale);
    i18n.changeLanguage(locale);
  };
  return (
    <IonPage className="settings-page">
      <IonContent>
        {user?.account?.username &&
        user?.account?.username !== "" &&
        user?.profile?.bio &&
        user?.profile?.bio !== "" ? (
          <IonItem lines="none" className="ion-padding-vertical">
            <IonAvatar slot="start" className="settings-avatar">
              <img
                src={
                  user?.profile?.avatar?.location ||
                  "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
                }
              />
            </IonAvatar>
            <IonLabel>
              <h2 className="text-color-grey-title">
                <span>{user?.account?.username}</span>
              </h2>
            </IonLabel>
          </IonItem>
        ) : (
          <IonItem lines="none" className="ion-padding-vertical">
            <IonAvatar slot="start" className="settings-avatar">
              <img
                onError={(e) => {
                  e.currentTarget.onerror = null;
                }}
                src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
              />
            </IonAvatar>
            <IonLabel>
              <h2 className="text-color-grey-title">
                <span
                  onClick={(e) => {
                    history.push("/login");
                  }}
                  className="auth-link"
                >
                  {t("login")}
                </span>{" "}
                /{" "}
                <span
                  onClick={(e) => {
                    history.push("/signup");
                  }}
                  className="auth-link"
                >
                  {t("signup")}
                </span>{" "}
                {t("tocontinue")}
              </h2>
            </IonLabel>
          </IonItem>
        )}
        <p className="text-color-grey settings-subtitle ion-padding-horizontal">
          {t("pref")}
        </p>
        {/* <Link to="/languages"> */}
        <IonItem lines="none">
          {t("lan")}
          <IonSegment
            className={"size-med"}
            onClick={(e) => {
              // localStorage.setItem('locale', e.currentTarget.value ?? "");

              saveSetting(e.currentTarget.value);
            }}
            slot="end"
          >
            <IonSegmentButton value="en">
              <IonLabel>English</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="ch">
              <IonLabel>Cantonese</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {/* <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon> */}
        </IonItem>
        {/* </Link> */}

        <p className="text-color-grey settings-subtitle ion-padding-horizontal">
          {t("other")}
        </p>
        <Link to="/terms-conditions">
          <IonItem lines="none">
            {t("tc")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </Link>
        <span
          onClick={(e) => {
            window.open("https://merchport.hk/support", "_blank");
          }}
        >
          <IonItem lines="none">
            {t("hs")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </span>
      </IonContent>
    </IonPage>
  );
};

export default GuestSettingsPage;
