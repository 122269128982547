import { IonButton, IonIcon } from '@ionic/react';
import './style.scss';

interface IconButtonProps {
  title?: string;
  color?: string;
  size?: 'default' | 'small' | 'large' | undefined;
  shape?: 'round' | undefined;
  slot?: string;
  fill?: 'clear' | 'outline' | 'solid' | 'default' | undefined;
  mode?: 'ios' | 'md';
  className?: string;
  iconProps: {
    icon: string;
    color?: string;
    size?: string;
    className?: string;
    slot?: string;
  };
  onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IonButton
      className={`icon-button ${props.className}`}
      icon-only
      size={props.size}
      shape={props.shape}
      color={props.color}
      slot={props.slot ? props.slot : ''} // may cause error if undefined
      fill={props.fill}
      // dk why ios mode + clear will change the btn color onclick
      mode={props.mode ? props.mode : props.fill === 'clear' ? 'md' : 'ios'}
      onClick={props.onClick}
    >
      {props.iconProps.slot === 'end' && props.title}
      <IonIcon
        className={props.iconProps.className}
        icon={props.iconProps.icon}
        color={props.iconProps.color}
        size={props.iconProps.size}
        // slot={props.iconProps.slot}
      ></IonIcon>
      {props.iconProps.slot === 'start' && props.title}
    </IonButton>
  );
};

export default IconButton;
