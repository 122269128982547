/* eslint-disable no-useless-escape */
import {
  IonBackButton,
  IonButton,
  IonSpinner,
  IonButtons,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
  useIonViewDidLeave,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import "./style.scss";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import TextInput from "../../components/TextInput";
import { checkEmailFormat } from "../../utils/formHelper";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import { message } from "antd-notifications-messages";
import axios from "axios";
import { getLocaleHeaders } from "../../core/baseFunction";
const ForgotPwPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState<boolean>(false);
  useIonViewDidLeave(() => {
    setError("");
  });

  // TODO API recovery
  const recover = (e) => {
    e.preventDefault();

    // check email
    if (!email) {
      setError(t("eremail"));
    } else if (!checkEmailFormat(email)) {
      setError(t("invalidEmail"));
    } else {
      setIsLoading(true);
      const body = {
        email: email,
      };
      axios
        .post(
          "https://api.merchport.hk/api/auth/forgot-password",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              local: getLocaleHeaders(),
            },
          }
        )
        .then((res) => {
          if (res.data) {
            setIsLoading(false);
            message({
              type: "success",
              message: `${res.data}`,
            });
            // const path = "/verify";
            // props.history.push(path);
          }
        })
        .catch(function (error) {
          if (error.response) {
            setIsLoading(false);
            console.log("Error", error.response.data.message);
            message({
              type: "error",
              // message: `${error.response.data.message}`,
              message: t("login_response_error", {
                Errormessage: `${error.response.data.message}`,
              }), //dynamic tranlation
            });
            // message({
            //   type: "error",
            //   message: `${error.response.data.message}`,
            // });
          } else {
            setIsLoading(false);
            message({
              type: "error",
              message: t("network_Error"),
            });
          }
        });
      // alert(`email: ${email}`);
      // props.history.push('/home');
    }
  };

  return (
    <NoTabBarLayout>
      <IonPage className="auth-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="signup"
              icon={chevronBackOutline}
              color="medium"
              text=""
              mode="ios"
            />
          </IonButtons>
        </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonGrid>
            <IonRow>
              <div className="ion-text-center">
                <p className="auth-heading">{t("passwordRecovery")}</p>
                <IonText color="secondary">{t("enterYourEmail")}</IonText>
                <form onSubmit={recover}>
                  <TextInput
                    className="ion-padding"
                    name="email"
                    placeholder={t("emailsig")}
                    value={email}
                    error={error}
                    clearInput
                    onChange={(e) => setEmail(e.detail.value!)}
                    inputmode="email"
                  />
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    type="submit"
                    className="auth-button ion-margin"
                    mode="ios"
                  >
                    {t("recoverPassword")}{" "}
                    {loading && (
                      <IonSpinner
                        name="circles"
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </IonButton>
                </form>
              </div>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default ForgotPwPage;
