import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonToggle,
  useIonViewWillEnter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { chevronForwardOutline, notificationsOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import "./style.scss";
import { Storage } from "@capacitor/storage";
import { resetUser, setUser } from "../../redux/actions/userActions";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers";
import { store } from "../../redux/store";
import { useState } from "react";
import { setUserSetting } from "../../apis/upload";
import { errorMessage } from "../../core/popups";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions/languageAction";
const mapStateToProps = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatch = {
  resetUser: resetUser,
};

const connector = connect(mapStateToProps, mapDispatch);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const CustomerSettingsPage: React.FC<PropsType> = (props) => {
  const { i18n, t } = useTranslation();
  const [locale, setLocale] = useState("en");
  interface RootState {
    lang: {
      lang: string;
    };
  }

  const history = useHistory();
  const handleLogout = async () => {
    await Storage.remove({ key: "user" });
    await Storage.remove({ key: "api_token" });
    store.dispatch(resetUser());
    const path = "/login";
    history.replace(path);
  };
  const saveSetting = (local, notification) => {
    console.log("LANGUAGE", local);
    setLocale(locale);
    localStorage.setItem("language", locale);
    store.dispatch(changeLanguage(local));
    i18n.changeLanguage(local);
    // setUserSetting(
    //   local || "en-US",
    //   notification,
    //   props?.user?.profile?.bio || "",
    //   props?.user?.profile?.avatarFileId || ""
    // ).then((e) => {
    //   if (!e.haveError) {
    //     store.dispatch(setUser(e.data));
    //   } else {
    //     errorMessage(e.error);
    //   }
    // });
  };

  return (
    <IonPage className="settings-page">
      <IonContent>
        <IonItem lines="none" className="ion-padding-vertical">
          <IonAvatar slot="start" className="settings-avatar">
            <img
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src =
                  "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y";
              }}
              src={
                props.user?.profile?.avatar?.location === "" ||
                props.user?.profile?.avatar?.location == undefined
                  ? "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
                  : props.user?.profile?.avatar?.location
              }
            />
          </IonAvatar>
          <IonLabel>
            <h1>{props?.user?.profile?.displayName}</h1>
            <h3 className="text-color-grey-lighter">
              @{props.user?.account?.username}
            </h3>
          </IonLabel>
          <Link to="/profile-edit">
            <IonButton slot="end" fill="outline" mode="ios">
              {t("editProfile")}
            </IonButton>
          </Link>
        </IonItem>
        <p className="text-color-grey settings-subtitle ion-padding-horizontal">
          {t("acc")}
        </p>
        <Link to="/change-password">
          <IonItem lines="none">
            {t("chps")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </Link>
        <Link to="/become-shop-owner">
          <IonItem lines="none">
            {t("becshow")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </Link>
        <p className="text-color-grey settings-subtitle ion-padding-horizontal">
          {t("pref")}
        </p>
        <IonItem lines="none">
          {t("lan")}
          <IonSegment
            // value="en"

            className={"size-med"}
            onClick={(e) => {
              saveSetting(
                e.currentTarget.value,
                props?.user?.preference?.receiveNotification
              );
            }}
            slot="end"
          >
            <IonSegmentButton value="en">
              <IonLabel>English</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="ch">
              <IonLabel>Cantonese</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonItem>
        <span>
          <IonItem lines="none">
            {t("Notf")}
            <IonToggle
              slot="end"
              onClick={() => {
                saveSetting(
                  props?.user?.preference?.locale,
                  !props?.user?.preference?.receiveNotification
                );
                return 0;
              }}
              value={"notification"}
              checked={props?.user?.preference?.receiveNotification}
              color="primary"
            />
          </IonItem>
        </span>
        <p className="text-color-grey settings-subtitle ion-padding-horizontal">
          {t("other")}
        </p>
        <Link to="/terms-conditions">
          <IonItem lines="none">
            {t("tc")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </Link>
        <span
          onClick={(e) => {
            window.open("https://merchport.hk/support", "_blank");
          }}
        >
          <IonItem lines="none">
            {t("hs")}
            <IonIcon
              slot="end"
              icon={chevronForwardOutline}
              size="small"
            ></IonIcon>
          </IonItem>
        </span>
        <IonItem lines="none" onClick={handleLogout}>
          {t("log")}
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default connector(CustomerSettingsPage);
