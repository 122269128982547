import { IStore, IStoreProfile } from "./StoreTypes";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const RESET_USER = "RESET_USER";
export const SET_USER_PROFILE = "SET_USER_PROFILE";

export interface IUser {
  id: string;
  account: {
    createdAt: string,
    email: string,
    isDisabled: boolean,
    isEmailVerified: boolean,
    isPhoneNumberVerified: boolean,
    phoneNumber: string,
    updatedAt: string,
    userId: string,
    username: string
  }
  account_type: "customer" | "shop";
  updatedAt: string;
  createdAt: boolean;
  profile: {
    avatar: {
      location: string
    },
    avatarFileId: string,
    bio: string,
    createdAt: string,
    updatedAt: string,
    displayName: string,
    userId: string
  };
  preference: {
    userId: string,
    createdAt: string,
    updatedAt: string,
    locale: "zh-Hant-HK" | "en-US",
    receiveNotification: boolean
  },
  _count: {
    shops: number,
    ownedApprovedShops: number
  }
}

export interface IPreference {
  is_owner: boolean;
  is_following: boolean;
  is_store: boolean;
}
export interface ILoginRequest {
  login: string;
  password: string;
}

export interface ILoginResponse {
  data: IUser;
  api: {
    token: string;
  };
}

export interface IRegisterRequest {
  profilePicSrc: string;
  username: string;
  email: string;
  phoneNo: string;
  repassword: string;
  password: string;
}

export interface IRegisterRequestError {
  username: string;
  email: string;
  phoneNo: string;
  password: string;
  agree: string;
}

export interface IUserState {
  user: IUser | null;
  error: string;
}

export interface ILoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  data: IUser;
}

export interface ILoginFailureAction {
  type: typeof LOGIN_FAILURE;
  data: string;
}

export interface IResetUserAction {
  type: typeof RESET_USER;
}

export interface IUserResponse {
  preference: IPreference;
  user?: IUser;
  store?: IStoreProfile;
}

export type TShopProfileUpdate = {
  description: string,
  icon: string,
  productPhotos: {
    connect: string[],
    delete: string[]
  }
  warranty: string
};