import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import IconButton from "../IconButton";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

interface CarouselProps {
  children: any;
  itemsToShow?: number;
  itemWidth?: number | string;
  itemHeight?: number | string;
  isFullWidth?: boolean;
  showIndicators?: boolean;
}

const Carousel: React.FC<CarouselProps> = (props) => {
  const { children, itemsToShow } = props;

  const sliderRef = useRef<HTMLDivElement>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(0);

  const [touchPosition, setTouchPosition] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Set the length to match current children from props
  useEffect(() => {
    if (children) {
      setLength(children.length);
    }
  }, [children]);

  const next = () => {
    if (itemsToShow && currentIndex < length - itemsToShow) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }
    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;
    if (diff > 5) {
      next();
    }
    if (diff < -5) {
      prev();
    }
    setTouchPosition(null);
  };

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    if (sliderRef.current) {
      setStartX(e.pageX - sliderRef.current.offsetLeft);
      setScrollLeft(sliderRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (sliderRef.current) {
      const offsetX = e.pageX - sliderRef.current.offsetLeft;
      const diff = offsetX - startX; //scroll-fast
      sliderRef.current.scrollLeft = scrollLeft - diff;
    }
  };

  const renderIndicators = () => {
    const indicators: any = [];
    for (let i = 0; i < length; i++) {
      indicators.push(
        <IonCol key={i}>
          <div
            className="indicator"
            style={{
              backgroundColor:
                currentIndex === i
                  ? "var(--ion-color-primary)"
                  : "var(--ion-color-grey-lightest)",
            }}
          ></div>
        </IonCol>
      );
    }
    return indicators;
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        {/*{(currentIndex > 0 || scrollLeft > 0) && (*/}
        {/*	<IconButton*/}
        {/*		iconProps={{ icon: chevronBackOutline }}*/}
        {/*		color="light"*/}
        {/*		onClick={prev}*/}
        {/*		className="left-arrow"*/}
        {/*	/>*/}
        {/*)}*/}
        <div
          className="carousel-content-wrapper"
          style={{ overflow: props.isFullWidth ? "" : "scroll" }}
          ref={sliderRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onMouseDown={handleMouseDown}
          onMouseLeave={() => setIsMouseDown(false)}
          onMouseUp={() => setIsMouseDown(false)}
          onMouseMove={handleMouseMove}
        >
          <div
            className={`carousel-content show-${itemsToShow}`}
            style={{
              transform: itemsToShow
                ? `translateX(-${currentIndex * (100 / itemsToShow)}%)`
                : "",
              width: props.itemWidth,
              height: props.itemHeight,
            }}
          >
            {children}
          </div>
        </div>
        {/*{currentIndex <= length - (itemsToShow ??0) && (*/}
        {/*	<IconButton*/}
        {/*		color="light"*/}
        {/*		iconProps={{ icon: chevronForwardOutline}}*/}
        {/*		onClick={next}*/}
        {/*		className="right-arrow"*/}
        {/*	/>*/}
        {/*)}*/}
      </div>
      {props.showIndicators && (
        <IonGrid>
          <IonRow className={"no-scroll"}>{renderIndicators()}</IonRow>
        </IonGrid>
      )}
    </div>
  );
};

export default Carousel;
