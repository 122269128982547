import {
  IUser,
  IUserState,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  RESET_USER, SET_USER_PROFILE,
} from '../../types/UserTypes';

const initialState: IUserState = {
  user: null,
  error: '',
};

export const userType = [ "customer", "shop" ];

export const userReducer = (state = initialState, action: any): IUserState => {
  switch (action.type) {
    case RESET_USER:
      localStorage.clear();
      return { ...state, user: null, error: '' };
    case LOGIN_SUCCESS:
      if( typeof action?.data?._count?.ownedApprovedShops != "undefined"){
        action.data.account_type = action?.data?._count?.ownedApprovedShops > 0 ? userType[1]: userType[0];
      }else{
        action.data.account_type = userType[0];
      }
      return { ...state, user: action.data, error: '' };
    case SET_USER_PROFILE:
      return { ...state, user: { ...state?.user, profile: action.data } as IUser, error: '' };
    case LOGIN_FAILURE:
      return { ...state, error: action.data };
    default:
      return state;
  }
};
