import { create } from "ionicons/icons";
import React from "react";
import IconButton from "../IconButton";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const about = {
  payment:
    "Lorem ipsum dolor sit amet, condimentum nonummy sagittis aptent pede lobortis, hendrerit eget erat. Vestibulum vel adipiscing quisque consectetuer malesuada, tempus ullamcorper lectus molestie cursus. Sit in ut mollis nullam rhoncus mus.  ",
  shipping: "Lor",
  termsConditions:
    "Lorem ipsum dolor sit amet, condimentum nonummy sagittis aptent pede lobortis, hendrerit eget erat. Vestibulum vel adipiscing quisque consectetuer malesuada, tempus ullamcorper lectus molestie cursus. Sit in ut mollis nullam rhoncus mus.  ",
};

interface ShopAboutTabProps {
  paymentDetails?: string;
  shippingDetails?: string;
  termsOfService?: string;
  isShop: boolean;
  paymentDesc: string;
}

// TODO: API refactor props

const ShopAboutTab: React.FC<ShopAboutTabProps> = (props) => {
  const { i18n, t } = useTranslation();
  console.log("PROPS AT SHOP ABOUT TAB", props);
  const history = useHistory();
  return (
    <div className="shop-about-tab">
      <div className="toolbar">
        {props.isShop == true ? (
          <IconButton
            size="small"
            color="medium"
            fill="clear"
            onClick={() => {
              history.push("/profile-edit");
            }}
            iconProps={{
              icon: create,
              className: "toolbar-button",
              size: "small",
              slot: "start",
            }}
            title={t("edit")}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="ion-padding-horizontal">
        {props.paymentDetails && (
          <>
            <p className="subtitle-medium-12">{t("warranty")}</p>
            <p className="content text-color-grey-lighter ion-text-justify">
              {props.paymentDetails}
            </p>
          </>
        )}
        {props.paymentDesc && (
          <>
            <p className="subtitle-medium-12">{t("description")}</p>
            <p className="content text-color-grey-lighter ion-text-justify">
              {props.paymentDesc}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ShopAboutTab;
