import {ServerApi} from "../core/api";

const api = new ServerApi();

export function getMyLikedProduct(){
    return api.getMyLikedProduct();
}

export function setLikeProduct(id: string){
    return api.setLikeProduct(id);
}

export function removeLikeProduct(id: string){
    return api.removeLikeProduct(id);
}

export function getMyFollowShops(){
    return api.getMyFollowShops();
}

export function setFollowShop(id: string){
    return api.setFollowShop(id);
}

export function removeFollowShop(id: string){
    return api.removeFollowShop(id);
}