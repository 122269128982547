import { IonCheckbox } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import ImageWrapper from '../ImageWrapper';
import './style.scss';
import {useHistory} from "react-router-dom";

interface SmallProductCardProps {
  id: any;
  title: string;
  images: [
      {
          location: string
      }
  ];
  price: string;
  isEdit: boolean;
  onSelect: (id: any) => void;
}

const SmallProductCard: React.FC<SmallProductCardProps> = (props) => {
  const [isSelected, setIsSelected] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (props.isEdit) {
      setIsSelected(false);
    }
  }, [props.isEdit]);

  const handleOnSelect = () => {
    if( !props.isEdit ){
      history.push('/product/view/'+props.id);
    }
    props.onSelect(props.id);
    setIsSelected(!isSelected);
  };

  return (
    <div className="small-product-card-container" onClick={handleOnSelect}>
      <div className="product-image">
        <ImageWrapper
          src={ Array.isArray(props.images) && props.images.length >0 ? props.images[0].location: ''}
          className={`${isSelected ? 'layer' : ''}`}
        />
        {props.isEdit && (
          <IonCheckbox
            mode="ios"
            className="circle"
            checked={isSelected}
          ></IonCheckbox>
        )}
      </div>
      <p
        className="margin-vertical line-clamp product-name"
        style={{ WebkitLineClamp: 2 }}
      >
        {props.title}
      </p>
      <p className="text-color-grey-lighter margin-vertical price">
        ${props.price}
      </p>
    </div>
  );
};

export default SmallProductCard;
