import { REQUEST_CHANGE } from "../../types/LanguageTypes";

const initialState: State = {
  lang: "en",
};

export const languageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REQUEST_CHANGE:
      return { ...state, lang: action.data };
    default:
      return state;
  }
};

type State = {
  lang: string;
};
