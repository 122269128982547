import axios from "axios";
import { BaseApi } from "./baseApi";
import { Storage } from "@capacitor/storage";
import { store } from "../redux/store";
import { resetUser, setUser } from "../redux/actions/userActions";
import { TProductReview } from "../types/ReviewTypes";
import { TShopProfileUpdate } from "../types/UserTypes";
// const baseUrl = "https://merchport.z1p.xyz/api/";
const baseUrl = "https://api.merchport.hk/api/";
export class ServerApi extends BaseApi {
  async refreshToken() {
    const token = this.getRefreshToken();
    if (typeof token !== "undefined" && token != "") {
      try {
        const response = await axios.post(`${baseUrl}auth/refresh`, {
          refresh_token: token,
        });
        const result = this.handleResponse(response);
        if (!result.haveError) {
          localStorage.setItem("access_token", result.data.access_token);
          localStorage.setItem("refresh_token", result.data.refresh_token);
          store.dispatch(setUser(result.data.user));
          await Storage.set({
            key: "user",
            value: JSON.stringify(result.data.user),
          });
          await Storage.set({
            key: "api_token",
            value: result.data.access_token,
          });
          return true;
        } else {
          localStorage.clear();
          await Storage.clear();
          store.dispatch(resetUser());
          return false;
        }
      } catch (e) {
        localStorage.clear();
        await Storage.clear();
        store.dispatch(resetUser());
      }
    } else {
      localStorage.clear();
      await Storage.clear();
      store.dispatch(resetUser());
    }
  }
  async googleLogin() {
    try {
      const response = await axios.get(`${baseUrl}auth/google/redirect`);
      return this.handleResponse(response);
    } catch (e) {
      console.log(e);
      return this.handleErrorResponse(e);
    }
  }
  async sendOtp() {
    try {
      const response = await axios.post(
        `${baseUrl}auth/verify-phone-number`,
        {},
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async verifyOtp(otp: string) {
    try {
      const response = await axios.post(
        `${baseUrl}auth/confirm-phone-number`,
        {
          code: otp,
        },
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }

  async getCategory(take = 100) {
    const response = await axios.get(`${baseUrl}categories?take=${take}`);
    return response;
  }
  async getMe() {
    try {
      const response = await axios.get(`${baseUrl}users/@me`, {
        headers: this.getGetAuthHeader(),
      });
      return this.handleResponse(response);
    } catch (e) {
      return this.handleErrorResponse(e);
    }
  }
  async postShop(data: any) {
    try {
      const response = await axios.post(`${baseUrl}shops`, data, {
        headers: this.getGetAuthHeader(),
      });
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async AddProductInShop(shopId: string, productId: string) {
    try {
      const response = await axios.post(
        `${baseUrl}shops/${shopId}/products`,
        {
          productId: productId,
        },
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }

  async postProduct(data: any) {
    try {
      const response = await axios.post(`${baseUrl}products`, data, {
        headers: this.getGetAuthHeader(),
      });
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }

  async deleteShopProduct(shopId: string, productid: string) {
    try {
      const response = await axios.delete(
        `${baseUrl}shops/${shopId}/products`,
        {
          data: {
            productId: productid,
          },
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }

  async getTrendingProduct(take = 10, skip = 0) {
    const response = await axios.get(
      `${baseUrl}products/trend?skip=${skip}&take=${take}`
    );
    return this.handleResponseCount(response);
  }
  async getRecommendedProduct(take = 10, skip = 0) {
    const response = await axios.get(
      `${baseUrl}users/@me/feed/products?skip=${skip}&take=${take}`,
      {
        headers: this.getGetAuthHeader(),
      }
    );
    return this.handleResponseCount(response);
  }
  async getProductByCategoryId(id: string) {
    const response = await axios.get(`${baseUrl}categories/${id}/products`);
    return this.handleResponseCount(response);
  }

  async getShopsByCategoryId(id: string) {
    const response = await axios.get(`${baseUrl}categories/${id}/shops`);
    return this.handleResponseCount(response);
  }

  async searchProducts(
    keyword: string,
    scope: string | any,
    shopId: string | any,
    categoryId: string | null | undefined,
    filter: any | null | undefined,
    skip = 0
  ) {
    let url = `${baseUrl}search?q=${keyword
      .split(" ")
      .join("+")}&skip=${skip}&limit=10`;
    if (scope && scope?.trim().length > 0) {
      url = url + "&scope=" + scope;
    }
    if (shopId && shopId.trim().length > 0) {
      url = url + "&shopId=" + shopId;
    }
    if (categoryId && categoryId != "") {
      url = url + "&categoryId=" + categoryId;
    }
    if (filter != null) {
      if (
        filter.price_max &&
        filter.price_max !== 999999 &&
        filter.price_max !== "0"
      ) {
        url = url + "&priceUpperLimit=" + filter.price_max;
      }
      if (filter.price_min != null) {
        url = url + "&priceLowerLimit=" + filter.price_min;
      }
      if (filter.sort_by == "popularity") {
        url = url + "&orderBy=views&order=desc";
      }
      if (filter.sort_by == "price_asc") {
        url = url + "&orderBy=price&order=asc";
      }
      if (filter.sort_by == "price_desc") {
        url = url + "&orderBy=price&order=desc";
      }
    }
    // console.log("URL", url);
    const response = await axios.get(url);
    return this.handleResponseCount(response);
  }
  async getProductById(id: string) {
    const response = await axios.get(`${baseUrl}products/${id}`);
    return this.handleResponse(response);
  }
  async getProductsByStoreId(id: string, skip: number, take: number) {
    const response = await axios.get(
      `${baseUrl}shops/${id}/products?skip=${skip}&take=${take}`
    );
    return this.handleResponseCount(response);
  }

  async getShopById(id: string) {
    const response = await axios.get(`${baseUrl}shops/${id}`);
    return this.handleResponse(response);
  }
  async getShopReviewByShopId(id: string) {
    const response = await axios.get(`${baseUrl}shops/${id}/comments`);
    return this.handleResponseCount(response);
  }
  async getShopProductReviewByShopId(id: string, count = 10, skip = 0) {
    const response = await axios.get(
      `${baseUrl}shops/${id}/products/comments?skip=${skip}&take=${count}`
    );
    return this.handleResponseCount(response);
  }
  async getProductReviewsById(id: string, take, skip) {
    const response = await axios.get(`${baseUrl}products/${id}/comments`);
    return this.handleResponse(response);
  }
  async getMyLikedProduct() {
    try {
      const response = await axios.get(
        `${baseUrl}users/@me/favourite/products`,
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async setLikeProduct(id: string) {
    try {
      const response = await axios.post(
        `${baseUrl}products/${id}/likes`,
        {},
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async removeLikeProduct(id: string) {
    try {
      const response = await axios.delete(
        `${baseUrl}users/@me/favourite/products/${id}`,
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async getMyFollowShops() {
    try {
      const response = await axios.get(`${baseUrl}users/@me/following/shops`, {
        headers: this.getGetAuthHeader(),
      });
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async setFollowShop(id: string) {
    try {
      const response = await axios.post(
        `${baseUrl}shops/${id}/follows`,
        {},
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async removeFollowShop(id: string) {
    try {
      const response = await axios.delete(
        `${baseUrl}users/@me/following/shops/${id}`,
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async setUserProfile(id: string | null, bio: string, displayName: string) {
    try {
      const data: any = {
        bio: bio,
        displayName: displayName,
      };
      if (id != null) {
        data.avatarFileId = id;
      }
      const response = await axios.patch(`${baseUrl}users/@me`, data, {
        headers: this.getGetAuthHeader(),
      });
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
  async setShopProfile(id: string, bio: TShopProfileUpdate) {
    try {
      const response = await axios.patch(`${baseUrl}shops/${id}`, bio, {
        headers: this.getGetAuthHeader(),
      });
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }

  async uploadImageBolb(bob) {
    try {
      const response = await fetch(bob.data);
      const blob = await response.blob();
      const formData = new FormData();
      formData.append("file", blob, bob.filepath);
      const image = await axios.post(
        "https://api.merchport.hk/api/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return this.handleResponse(image);
    } catch (e) {
      return this.handleErrorResponse(e);
    }
  }

  async PostProductReview(id: string, review: TProductReview) {
    try {
      const response = await axios.post(
        `${baseUrl}products/${id}/comments`,
        review,
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleErrorResponse(e);
    }
  }

  async getMyShop() {
    try {
      const response = await axios.get(`${baseUrl}users/@me/shops`, {
        headers: this.getGetAuthHeader(),
      });
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }

  async updateProductById(productId: string, product: any) {
    try {
      const response = await axios.patch(
        `${baseUrl}products/${productId}`,
        product,
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }

  async setUserSetting(
    locale: string,
    receiveNotification: boolean,
    bio: string,
    id: string
  ) {
    try {
      const response = await axios.patch(
        `${baseUrl}users/@me`,
        { locale, receiveNotification, bio },
        {
          headers: this.getGetAuthHeader(),
        }
      );
      return this.handleResponse(response);
    } catch (e) {
      return this.handleResponsewithRedirect(e);
    }
  }
}
