import Carousel from "../../components/Carousel";
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonPage,
  IonContent,
  IonGrid,
  useIonViewWillEnter,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import "./style.scss";
import SectionTab from "../../components/SectionTab";
import SectionTabs from "../../components/SectionTabs";
import { Link } from "react-router-dom";
import CategoryBox from "../../components/CategoryBox";
import ProductCard from "../../components/ProductCard";
import { useMutation } from "react-query";
import { getCategories } from "../../apis/categoryApis";
import { useTranslation } from "react-i18next";
import {
  getRecommendedProduct,
  getTrendingProduct,
} from "../../apis/productApis";
import { useState } from "react";
import { TProduct } from "../CategoryPage";
import { getToken } from "../../core/baseFunction";

const HomePage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [category, setCategory]: any = useState([]);
  const [trandingCont, setTrandingCont] = useState(0);
  const [trandingLoad, setTrandingLoad] = useState(false);
  const [trandingList, setTrandingList] = useState<any>([]);
  const [recCont, setRecCont] = useState(0);
  const [recLoad, setRecLoad] = useState(false);
  const [recList, setRecList] = useState<any>([]);
  const {
    data: tranding,
    mutate: trandingMutate,
    isLoading: isTrandingLoading,
  } = useMutation((skip: number) => getTrendingProduct(10, skip), {
    onSuccess: (data, variables, context) => {
      if (!data.haveError) {
        setTrandingList(data.data);
        setTrandingLoad(false);
        setTrandingCont(0);
      }
    },
  });
  const {
    data: feed,
    mutate: feedMutate,
    isLoading: isFeedLoading,
  } = useMutation((skip: number) => getRecommendedProduct(10, skip), {
    onSuccess: (data, variables, context) => {
      if (!data.haveError) {
        setRecLoad(false);
        setRecList(data.data);
        setRecCont(0);
      }
    },
  });
  const loadNextTrending = (ev: any) => {
    getTrendingProduct(10, trandingCont + 10).then((e) => {
      if (!e.haveError) {
        setTrandingList([...trandingList, ...e.data]);
        setTrandingCont(trandingCont + 10);
        if (e.data.length < 10) {
          setTrandingLoad(true);
        }
        ev.target.complete();
      }
    });
  };
  const loadNextRed = (ev: any) => {
    getRecommendedProduct(10, trandingCont + 10).then((e) => {
      if (!e.haveError) {
        setRecList([...recList, ...e.data]);
        setRecCont(recCont + 10);
        if (e.data.length < 10) {
          setRecLoad(true);
        }
        ev.target.complete();
      }
    });
  };
  useIonViewWillEnter(() => {
    getCategories()
      .then((e) => {
        if (Array.isArray(e)) {
          setCategory(e);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    trandingMutate(0);
    if (getToken() != null) {
      feedMutate(0);
    }
  });
  return (
    <IonPage className="homepage">
      <IonContent>
        <IonToolbar>
          <IonTitle className="toolbar-ion-title">{t("cat")}</IonTitle>
          <IonButtons slot="end">
            <Link to="/categories">
              <IonButton
                className="text-color-grey-lightest ion-text-capitalize view-all"
                mode="ios"
                color="medium"
              >
                {t("viewall")}
              </IonButton>
            </Link>
          </IonButtons>
        </IonToolbar>
        <Carousel itemWidth={90} itemHeight={50}>
          {category?.map((catego, index) => {
            if (index < 5) {
              return (
                <CategoryBox key={catego.id} {...catego} className="category" />
              );
            }
          })}
        </Carousel>
        <IonToolbar>
          <IonTitle className="toolbar-ion-title">{t("explore")}</IonTitle>
        </IonToolbar>
        {/* NOTE: now trending and recommended are the same */}

        <SectionTabs>
          <SectionTab label={t("trend")}>
            {isTrandingLoading || !tranding ? (
              <div className="spinner-container">
                <IonSpinner name="crescent" color="primary" />
              </div>
            ) : (
              <>
                {!tranding.haveError && tranding.data?.length > 0 ? (
                  <>
                    <IonGrid
                      // style={{ marginLeft: "2%" }}
                      className="product-card-grid ion-justify-content-center  p-10 jst"
                    >
                      {trandingList?.map((product: TProduct, index) => (
                        <ProductCard
                          store={product.shops[0].shop}
                          added_on={product.createdAt}
                          preview_src={
                            Array.isArray(product.images) &&
                            product.images.length > 0
                              ? product.images[0].location
                              : ""
                          }
                          {...product}
                          key={index}
                        />
                      ))}
                    </IonGrid>
                    <IonInfiniteScroll
                      onIonInfinite={(ev: any) => {
                        loadNextTrending(ev);
                      }}
                      threshold="100px"
                      disabled={trandingLoad}
                    >
                      <IonInfiniteScrollContent
                        loadingSpinner="bubbles"
                        loadingText={t("Loading")}
                      ></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                  </>
                ) : (
                  <p className="ion-text-center"> {t("nodata")}</p>
                )}
              </>
            )}
          </SectionTab>
          <SectionTab label={t("recom")}>
            {getToken() != null ? (
              <>
                {isFeedLoading || !feed ? (
                  <IonSpinner name="crescent" color="primary" />
                ) : (
                  <>
                    {!feed.haveError && feed.data?.length > 0 ? (
                      <IonGrid className="product-card-grid p-10 jst">
                        {feed.data?.map((product: TProduct, index) => (
                          <ProductCard
                            store={product.shops[0].shop}
                            added_on={product.createdAt}
                            preview_src={
                              Array.isArray(product.images) &&
                              product.images.length > 0
                                ? product.images[0].location
                                : ""
                            }
                            {...product}
                            key={index}
                          />
                        ))}
                        <>
                          <IonInfiniteScroll
                            onIonInfinite={(ev: any) => {
                              loadNextRed(ev);
                            }}
                            threshold="100px"
                            disabled={recLoad}
                          >
                            <IonInfiniteScrollContent
                              loadingSpinner="bubbles"
                              loadingText="Loading more data..."
                            ></IonInfiniteScrollContent>
                          </IonInfiniteScroll>
                        </>
                      </IonGrid>
                    ) : (
                      <p className="ion-text-center">{t("nodata")}</p>
                    )}
                  </>
                )}
              </>
            ) : (
              <p className="ion-text-center">{t("loginsee")}</p>
            )}
          </SectionTab>
        </SectionTabs>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
