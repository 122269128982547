import {
  IonToolbar,
  IonButtons,
  IonTitle,
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonBackButton,
  useIonViewWillEnter,
  IonHeader
} from "@ionic/react";
import "./style.scss";
import { chevronBackOutline } from "ionicons/icons";
import CategoryBox from "../../components/CategoryBox";
import { useMutation } from "react-query";
import { getCategories } from "../../apis/categoryApis";
import { useTranslation } from "react-i18next";

const CategoriesPage: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { data: categories, mutate: categoriesMutate } =
    useMutation(getCategories);
  useIonViewWillEnter(() => {
    categoriesMutate(1000);
  });
  const renderRows = () => {
    const rows: any = [];
    for (let index = 0; index <= categories?.length; index += 3) {
      const cols: any = [];
      for (let i = 0; i < 3; i++) {
        cols.push(
          <IonCol
            size="4"
            className="ion-justify-content-center"
            key={index + i}
          >
            {categories[index + i] && (
              <CategoryBox
                key={index + i}
                {...categories[index + i]}
                className="category"
              />
            )}
          </IonCol>
        );
      }
      rows.push(
        <IonRow className="ion-padding-bottom" key={index}>
          {cols}
        </IonRow>
      );
    }
    return rows;
  };

  return (
    <IonPage className="categories-page">
       <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            defaultHref="home"
            icon={chevronBackOutline}
            color="medium"
            text=""
            mode="ios"
          />
        </IonButtons>
        <IonTitle className="text-color-grey-title text-bold">
          {t("cat")}
        </IonTitle>
      </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>{renderRows()}</IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CategoriesPage;
