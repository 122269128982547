import React, { useEffect, useState } from "react";
import ReviewItem from "../ReviewItem";
import "./style.scss";
import { SampleReviewType } from "../../pages/ProductPage";
import {
  getShopProductReviewByShopId,
  searchProducts,
} from "../../apis/productApis";
import { TProduct } from "../../pages/CategoryPage";
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface ShopReviewsTabProps {
  storeId?: string;
}
type ReviewWithProduct = {
  product: TProduct;
};
export type TReviewWithProduct = SampleReviewType & ReviewWithProduct;
type ReviewType = {
  count: number;
  data: TReviewWithProduct[];
};

const ShopReviewsTab: React.FC<ShopReviewsTabProps> = (props) => {
  const { i18n, t } = useTranslation();
  const [data, setData] = useState<TReviewWithProduct[]>([]);
  const [limit, setlimit] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  useEffect(() => {
    getShopProductReviewByShopId(props.storeId || "").then((e) => {
      if (!e.haveError) {
        setData(e.data);
        setlimit(0);
        setIsLoad(false);
      }
    });
  }, []);
  const getDatanext = (ev) => {
    getShopProductReviewByShopId(props.storeId || "", 10, limit + 10).then(
      (e) => {
        ev.target.complete();
        if (!e.haveError) {
          setData([...data, ...e.data]);
          setCount(e.count);
          if (e.data.length < 10) {
            setIsLoad(true);
          } else {
            setlimit(limit + 10);
          }
        } else {
          setData([]);
          setCount(0);
        }
      }
    );
  };
  return (
    <div className="shop-reviews-tab ion-padding-horizontal">
      <p className="subtitle-medium-12 avg">{t("averageRating")}</p>
      <div className="rating-container text-color-grey">
        <div className="rating-value">
          {Array.isArray(data) && data.length > 0
            ? parseFloat(
                (data.reduce((a, b) => a + b.rating, 0) / data.length).toFixed(
                  1
                )
              )
            : 0}
        </div>
        <div className="rating-total">
          /5 ({t("total")} {count} {t("riviews")})
        </div>
      </div>
      <p className="review-subtitle subtitle-medium-12">{t("riviews")}</p>
      {data && data.length > 0 ? (
        <>
          {data.map((review) => (
            <ReviewItem key={review.id} {...review} />
          ))}
          <IonInfiniteScroll
            onIonInfinite={(ev: any) => {
              getDatanext(ev);
            }}
            threshold="100px"
            disabled={isLoad}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText={t("loading")}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
      ) : (
        <p className="no-reviews text-color-grey">{t("noReviewsYet")}</p>
      )}
    </div>
  );
};

export default ShopReviewsTab;
