/* eslint-disable no-useless-escape */
export const checkEmailFormat = (email) => {
	const emailFormat =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailFormat.test(email);
};

export const checkPhoneFormat = (phone) => {
	// temporary check for hk local number only
	return /^\d+$/.test(phone) && phone.length === 8;
};
