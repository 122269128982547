import "./style.scss";

interface ImageWrapperProps {
  src?: string;
  className?: string;
}

const ImageWrapper: React.FC<ImageWrapperProps> = (props) => {
  return (
    <div
      className={`ion-padding ion-text-center ion-justify-content-center text-color-grey-lighter product-image ${props.className}`}
      style={
        props.src
          ? {
              backgroundImage: `url(${props.src})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
            }
          : {
              boxShadow: "0 0 3px var(--ion-color-grey-lightest)",
            }
      }
    >
      {!props.src && "No image available"}
    </div>
  );
};

export default ImageWrapper;
