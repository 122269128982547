import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import './style.scss';

interface ErrorNoteProps {
	error: string;
	align?: string;
}

const ErrorNote: React.FC<ErrorNoteProps> = (props) => {
	return (
		<div className={`error-note ion-text-${props.align}`}>
			<IonIcon icon={alertCircleOutline} color="danger"></IonIcon>
			<span className="error-message">{props.error}</span>
		</div>
	);
};

export default ErrorNote;
