import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonItem,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { useState } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import Textarea from "../../components/Textarea";
import TextInput from "../../components/TextInput";
import ImageUploadGrid from "../../components/ImageUploadGrid";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import "./style.scss";
import { getCategories } from "../../apis/categoryApis";
import { errorMessage, successMessage } from "../../core/popups";
import {
  addProductInShop,
  getProductById,
  postProduct,
  updateProductById,
} from "../../apis/productApis";
import { useHistory } from "react-router-dom";
import { ArrayLength, GetParams } from "../../core/baseFunction";
import { useTranslation } from "react-i18next";
import { message } from "antd-notifications-messages";

export type Tcategories = {
  id: string;
  name: string;
};

const MAX_LEN = 140;

const AddProductPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const [productImages, setProductImages] = useState<any[]>([]);
  const [oldProductImages, setOldProductImages] = useState<string[]>([]);
  const [deleteProductImages, setDeleteProductImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<Tcategories[]>([]);
  const [oldCategories, setOldCategories] = useState<string[]>([]);
  const history = useHistory();
  const params = GetParams(props.location.search || "");
  const shopId = params?.id || "";
  const productId = params?.productId;
  const [product, setProduct] = useState({
    name: "",
    images: [] as string[],
    categories: [],
    price: 0,
    description: "",
    shippingDetails: "",
    paymentDetails: "",
  });

  useIonViewDidEnter(() => {
    getCategories().then((e) => {
      if (Array.isArray(e) && e.length > 0) {
        setCategories(e);
      }
    });
    console.log(productId);
    if (typeof productId != "undefined" && productId != "") {
      getProductById(productId).then((e) => {
        if (!e.haveError) {
          const cate = ArrayLength(e.data.categories)
            ? e.data.categories.map((e) => e.id)
            : [];
          const img = ArrayLength(e.data.images)
            ? e.data.images.map((e) => ({
                webviewPath: e.location,
                id: e.id,
              }))
            : [];
          const data = {
            images: img.map((e) => e.id),
            paymentDetails: e.data?.paymentDetails || "",
            shippingDetails: e.data?.shippingDetails || "",
            categories: cate,
            price: e.data?.price || 0,
            name: e?.data?.name || "",
            description: e?.data?.description || "",
          };
          setProduct(data);
          setOldCategories(cate);
          setProductImages([...img]);
          setOldProductImages([...img.map((e) => e.id)]);
        } else {
          errorMessage(e.error);
        }
      });
    }
  });
  const handleInputOnChange = (e) => {
    if (e.detail.value != "" && e.detail.value != "0") {
      if (e.target.name === "price") {
        setProduct({ ...product, [e.target.name]: parseFloat(e.detail.value) });
      } else {
        setProduct((a) => ({
          ...a,

          [e.target.name]: e.detail.value,
        }));
        console.log("NAME", e.target.name);
        //
      }
    } else {
      setProduct({ ...product, [e.target.name]: e.detail.value });
    }
  };

  const handleProductOnSubmit = (e) => {
    e.preventDefault();
    console.log("TRIM", product);
    if (product?.name?.trim().length === 0) {
      errorMessage(t("nameRequired"));
    } else if (product.price == 0) {
      errorMessage(t("priceRequired"));
    } else if (product.images.length == 0) {
      errorMessage(t("imageRequired"));
    } else if (product.categories.length == 0) {
      errorMessage(t("categoryRequired"));
    } else if (product.shippingDetails.length == 0) {
      errorMessage(t("shippingRequired"));
    } else if (product.paymentDetails.length == 0) {
      errorMessage(t("paymentRequired"));
    } else if (product.description.length == 0) {
      errorMessage(t("productDescription"));
    } else {
      // console.log("ELSE BOKC");
      if (typeof productId != "undefined" && productId != "") {
        // console.log("ELSE CHILD IF");
        const deleteCat: string[] = [];
        oldCategories.forEach((oldC) => {
          if (!product.categories.find((c) => c == oldC)) {
            deleteCat.push(oldC);
          }
        });
        const addCat = product.categories.filter((addc) => {
          {
            return !oldCategories.find((fc) => fc === addc);
          }
        });
        const deleCat = oldCategories.filter((addc) => {
          {
            return !product.categories.find((fc) => fc === addc);
          }
        });
        const addPho = product.images.filter((addc) => {
          {
            console.log(oldProductImages.find((fc) => fc === addc));
            return !oldProductImages.find((fc) => fc === addc);
          }
        });
        updateProductById(productId, {
          name: product.name,
          price: product.price,
          description: product.description,
          paymentDetails: product.paymentDetails,
          shippingDetails: product.shippingDetails,
          categories: {
            connect: addCat,
            disconnect: deleCat,
          },
          images: {
            connect: addPho,
            disconnect: deleteProductImages,
          },
        }).then((r) => {
          if (!r.haveError) {
            successMessage(t("productAddedShop"));
            history.goBack();
          } else {
            message({
              type: "error",
              // message: `${error.response.data.message}`,
              message: t("global_error", {
                Errormessage: `${r.error}`,
              }), //dynamic tranlation
            });
            // errorMessage(r.error);
          }
        });
      } else {
        // console.log(" CHILD ELSE");
        postProduct(product).then((e) => {
          if (!e.haveError) {
            // successMessage("Your Product is Added");
            addProductInShop(shopId, e.data.id).then((e) => {
              if (!e.haveError) {
                successMessage(t("productAddedShop"));
              } else {
                errorMessage(t("productNotShop"));
              }
              history.goBack();
            });
          } else {
            errorMessage(e.error);
          }
        });
      }
    }
  };

  return (
    <NoTabBarLayout>
      <IonPage className="add-product-page">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="login"
              icon={chevronBackOutline}
              text=""
              color="dark"
            />
          </IonButtons>
          <IonTitle>{t("aboutMyProducts")}</IonTitle>
        </IonToolbar>

        <IonContent>
          <form>
            <p className="subtitle-medium-14 ion-no-margin">
              {t("productName")} <span style={{ color: "red" }}>*</span>
            </p>
            <TextInput
              name="name"
              value={product.name}
              onChange={(e) => handleInputOnChange(e)}
            />

            <p className="subtitle-medium-14 ion-no-margin">
              {t("productPhotos")} <span style={{ color: "red" }}>*</span>
            </p>
            <ImageUploadGrid
              images={productImages}
              ids={product.images}
              onUpdate={(id, im, del) => {
                setProduct({ ...product, images: id });
                setProductImages(im);
                if (del != null) {
                  const findO = oldProductImages.find((e) => e === del);
                  console.log(
                    del,
                    "aaaaaaaaaaa",
                    findO,
                    "bbbbbbbbbbbbbbb",
                    oldProductImages
                  );
                  if (findO) {
                    const delPho = deleteProductImages;
                    deleteProductImages.push(del);
                    setDeleteProductImages(delPho);
                  }
                }
              }}
              setLoading={(e) => setLoading(e)}
            />

            <p className="subtitle-medium-14">
              {t("categories")} <span style={{ color: "red" }}>*</span>
            </p>
            <IonItem
              className="bordered-ion-item ion-margin-bottom"
              lines="none"
            >
              <IonSelect
                placeholder={t("selectCategories")}
                name="categories"
                value={product.categories}
                multiple
                cancelText={t("cancel")}
                okText={t("oK")}
                onIonChange={(e) => handleInputOnChange(e)}
                interfaceOptions={{ header: "Category" }}
              >
                {categories.map((category, index) => (
                  <IonSelectOption
                    key={index}
                    value={category.id}
                    className="ion-text-capitalize"
                  >
                    {t(category.name.toLowerCase())}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            <p className="subtitle-medium-14 ion-no-margin">
              {t("price")} <span style={{ color: "red" }}>*</span>
            </p>
            <TextInput
              name="price"
              value={product.price}
              onChange={(e) => handleInputOnChange(e)}
              inputmode="numeric"
              type="number"
              isPrice
            />

            <p className="subtitle-medium-14 ion-no-margin">
              {t("productDescription")} <span style={{ color: "red" }}>*</span>
            </p>
            <Textarea
              name="description"
              value={product.description}
              onChange={(e) => handleInputOnChange(e)}
              maxlength={MAX_LEN}
            ></Textarea>

            <p className="subtitle-medium-14 ion-no-margin">
              {t("shippingDetails")} <span style={{ color: "red" }}>*</span>
            </p>
            <Textarea
              name="shippingDetails"
              value={product.shippingDetails}
              onChange={(e) => handleInputOnChange(e)}
              maxlength={MAX_LEN}
            ></Textarea>

            <p className="subtitle-medium-14 ion-no-margin">
              {t("paymentDetails")} <span style={{ color: "red" }}>*</span>
            </p>
            <Textarea
              name="paymentDetails"
              value={product.paymentDetails}
              onChange={(e) => handleInputOnChange(e)}
              maxlength={MAX_LEN}
            ></Textarea>
          </form>
        </IonContent>

        <IonFooter className="ion-no-border footer">
          <IonToolbar>
            <IonButton
              color="primary"
              fill="solid"
              expand="block"
              mode="ios"
              className="ion-margin"
              disabled={loading}
              onClick={(e) => handleProductOnSubmit(e)}
            >
              {t("post")}
              {loading && (
                <IonSpinner name="circles" style={{ marginLeft: "5px" }} />
              )}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default AddProductPage;
