import axios from 'axios';
import { Storage } from '@capacitor/storage';
import qs from 'qs';

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENV === 'DEV' ? process.env.REACT_APP_DEV_BASE_URL : process.env.REACT_APP_PROD_BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    // get auth token from storage
    const token = await Storage.get({ key: 'api_token' });
    if (token.value) {
      config.headers!['Authorization'] = `${token.value}`;
    }
    // stringify the data
    config.data = qs.stringify(config.data);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
