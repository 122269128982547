import {
  IonBackButton,
  IonButton,
  IonSpinner,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
  useIonToast,
  IonHeader,

} from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import "./style.scss";
import { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import PasswordInput from "../../components/PasswordInput";
import TextInput from "../../components/TextInput";
import { checkEmailFormat, checkPhoneFormat } from "../../utils/formHelper";
import ErrorNote from "../../components/ErrorNote";
import AvatarUpload from "../../components/AvatarUpload";
import NoTabBarLayout from "../../utils/NoTabBarLayout";
import { usePhotoGallery } from "../../native/usePhotoGallery";
import { IRegisterRequest, IRegisterRequestError } from "../../types/UserTypes";
import { Storage } from "@capacitor/storage";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { register } from "../../apis/userApis";
import { store } from "../../redux/store";
import { setUser } from "../../redux/actions/userActions";
import axios from "axios";
import { message } from "antd-notifications-messages";
import { useHistory } from "react-router-dom";
import { getMe } from "../../apis/AuthApi";
import { errorMessage } from "../../core/popups";
import { useTranslation } from "react-i18next";
import { getLocaleHeaders } from "../../core/baseFunction";
const SignUpPage: React.FC<RouteComponentProps> = (props) => {
  const { i18n, t } = useTranslation();
  const { photos, takePhoto } = usePhotoGallery();
  const history = useHistory();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<IRegisterRequest>({
    username: "",
    email: "",
    phoneNo: "",
    password: "",
    repassword: "",
    profilePicSrc: photos[0]?.webviewPath ?? "",
  });
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [isHiddenSecend, setIsHiddenSecend] = useState<boolean>(true);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [error, setError] = useState<IRegisterRequestError>({
    username: "",
    email: "",
    phoneNo: "",
    password: "",
    agree: "",
  });
  const [present] = useIonToast();

  const { isLoading, mutate } = useMutation(register, {
    // store api token to storage
    onSuccess: async (data) => {
      // store api token to storage
      await Storage.set({
        key: "api_token",
        value: data.api.token,
      });
      await Storage.set({
        key: "user",
        value: JSON.stringify(data.data),
      });

      // set user in redux store
      store.dispatch(setUser(data.data));
      props.history.push("/");
    },
    onError: (error: AxiosError) => {
      present({
        duration: 3000,
        color: "danger",
        message: error.response?.data.message,
      });
    },
  });

  const handleAccountOnChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.detail.value! });
  };

  const signUp = (e) => {
    e.preventDefault();

    const tempError = {
      username: "",
      email: "",
      phoneNo: "",
      password: "",
      agree: "",
    };
    // check username
    if (!account.username) {
      tempError.username = `${t("eruserName")}`;
    }
    // check email
    if (!account.email) {
      tempError.email = `${t("eremail")}`;
    } else if (!checkEmailFormat(account.email)) {
      tempError.email = `${t("invalidEmail")}`;
    }
    // check phoneNo
    if (!account.phoneNo) {
      tempError.phoneNo = `${t("phoneNumber")}`;
    } else if (!checkPhoneFormat(account.phoneNo)) {
      tempError.phoneNo = `${t("invalidPhoneNumber")}`;
    }
    // check password
    if (!account.password) {
      tempError.password = `${t("pasword")}`;
    } else if (account.password.length < 8) {
      tempError.password = `${t("invalidPassword")}`;
    } else if (account.password !== account.repassword) {
      tempError.password = `${t("passwordmatch")}`;
    }
    // check agreement
    if (!isAgreed) {
      tempError.agree = `${t("terms")}`;
    }
    setError(tempError);

    // if all are valid then log
    if (Object.values(tempError).every((key) => !key)) {
      // mutate(account);
      if (photos[0]?.webviewPath) {
        uploadPic(photos[0]);
      } else {
        uploadFields("");
      }
    }
  };
  const uploadPic = async (data) => {
    setIsLoading(true);
    const response = await fetch(data.data);

    const blob = await response.blob();

    const formData = new FormData();
    formData.append("file", blob, data.filepath);
    axios
      .post("https://api.merchport.hk/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data) {
          // setIsLoading(false);
          uploadFields(res.data.id);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          setIsLoading(false);
          message({
            type: "error",
            message: t("network_Error"),
            position: "topLeft",
            style: {
              width: "120%",
              overflowWrap: "anywhere",
            },
          });
        } else {
          setIsLoading(false);
          message({
            type: "error",
            message: t("network_Error"),
            position: "topLeft",
            style: {
              width: "120%",
              overflowWrap: "anywhere",
            },
          });
        }
      });
  };

  const uploadFields = (id) => {
    setIsLoading(true);
    interface body {
      email: string;
      password: string;
      phoneNumber: string;
      username: string;
      avatarFileId?: number;
    }
    const obj: body = {
      email: account.email,
      password: account.password,
      phoneNumber: account.phoneNo,
      username: account.username,
      avatarFileId: id,
    };
    if (id === "") {
      // delete obj.avatarFileId;
      delete obj["avatarFileId"];
    }
    axios
      .post("https://api.merchport.hk/api/auth/register", obj, {
        headers: {
          "Content-Type": "application/json",
          local: getLocaleHeaders(),
        },
      })
      .then((res) => {
        if (res.data) {
          console.log("RES", res.data);
          setIsLoading(false);
          message({
            type: "success",
            message: t("success_register"),
          });
          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("user", JSON.stringify(res.data.user.profile));
          getMe().then((meData) => {
            console.log("GETME", meData);
            if (!meData.haveError) {
              store.dispatch(setUser(meData.data));
              const path = "/verify";
              history.push(path);
            }
          });
          // history.push("guest-settings");
        }
      })
      .catch(function (error) {
        if (error.response) {
          setIsLoading(false);
          message({
            type: "error",
            // message: `${error.response.data.message}`,
            message: t("register_response_error", {
              Errormessage: `${
                Array.isArray(error.response.data.message) &&
                error.response.data.message.length > 0
                  ? error.response.data.message[0]
                  : error.response.data.message
              }`,
            }), //dynamic tranlation
          });
          // message({
          //   type: "error",
          //   position: "topLeft",
          //   style: {
          //     width: "90%",
          //     overflowWrap: "anywhere",
          //   },
          //   message: `${
          //     Array.isArray(error.response.data.message) &&
          //     error.response.data.message.length > 0
          //       ? error.response.data.message[0]
          //       : error.response.data.message
          //   }`,
          // });
        } else {
          setIsLoading(false);
          message({
            type: "error",
            position: "topLeft",
            style: {
              width: "90%",
              overflowWrap: "anywhere",
            },
            message: t("network_Error"),
          });
        }
      });
  };

  return (
    <NoTabBarLayout>
     
        <IonPage className="auth-page">
          <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton
                defaultHref="login"
                icon={chevronBackOutline}
                text={t("backLogin")}
                color="medium"
                mode="ios"
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent >
          <IonGrid>
            <IonRow>
              <div className="ion-text-center" style={{width: '70%', overflowY:'scroll'}}>
                <p className="auth-heading">{t("createAccount")}</p>
                <IonText color="secondary">{t("accountDetails")}</IonText>
                <form onSubmit={signUp} style={{overflowY:'scroll'}}>
                  <AvatarUpload
                    image={photos[0]?.webviewPath}
                    onClick={() => takePhoto()}
                  />
                  <TextInput
                    name="username"
                    placeholder={t("userName")}
                    value={account.username}
                    error={error.username}
                    clearInput
                    onChange={handleAccountOnChange}
                    inputmode="text"
                    disabled={isLoading}
                  />

                  <TextInput
                    name="email"
                    placeholder={t("emailsig")}
                    value={account.email}
                    error={error.email}
                    clearInput
                    onChange={handleAccountOnChange}
                    inputmode="email"
                    disabled={isLoading}
                  />
                  <TextInput
                    name="phoneNo"
                    placeholder={t("phoneNumber")}
                    value={account.phoneNo}
                    error={error.phoneNo}
                    clearInput
                    onChange={handleAccountOnChange}
                    inputmode="tel"
                    disabled={isLoading}
                  />
                  <PasswordInput
                    name="password"
                    placeholder={t("password")}
                    value={account.password}
                    isHidden={isHidden}
                    error={error.password}
                    onChange={handleAccountOnChange}
                    onClick={() => setIsHidden(!isHidden)}
                    disabled={isLoading}
                  />
                  <PasswordInput
                    name="repassword"
                    placeholder={t("confirmPassword")}
                    value={account.repassword}
                    onChange={handleAccountOnChange}
                    disabled={isLoading}
                    onClick={() => setIsHiddenSecend(!isHiddenSecend)}
                    isHidden={isHiddenSecend}
                  />
                  <div className="text-color-grey-lightest">
                    <IonCheckbox
                      checked={isAgreed}
                      onIonChange={(e) => setIsAgreed(e.detail.checked)}
                      disabled={isLoading}
                    />
                    {t("agree")}&nbsp;
                    <Link
                      to="/terms-conditions"
                      color="medium"
                      className="terms-of-service text-color-grey-lightest"
                    >
                      <span> {t("tos")}</span>
                    </Link>
                    {error.agree && (
                      <ErrorNote error={error.agree} align="center" />
                    )}
                  </div>
                  <IonButton
                    color="primary"
                    shape="round"
                    expand="block"
                    type="submit"
                    className="auth-button button-padding "
                    mode="ios"
                    disabled={loading}
                  >
                    {t("signup")}{" "}
                    {loading && (
                      <IonSpinner
                        name="circles"
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </IonButton>
                </form>
              </div>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </NoTabBarLayout>
  );
};

export default SignUpPage;
