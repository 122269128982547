import {ServerApi} from "../core/api";

const api = new ServerApi();

export const getShopById = async (id:string) => {
    return await api.getShopById(id);
};

export const getShopReviewByShopId = async (id:string) => {
    return await api.getShopReviewByShopId(id);
};

export const getMyShop = async () => {
    return await api.getMyShop();
};