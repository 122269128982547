import {
  IonPage,
  IonContent,
  useIonViewWillEnter,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonHeader
} from "@ionic/react";
import { chevronBackOutline, optionsOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import IconButton from "../../components/IconButton";
import ProductCard from "../../components/ProductCard";
import SortFilterModal from "../../components/SortFilterModal";
import "./style.scss";
import {
  getProductByCategoryId,
  getShopsByCategoryId,
} from "../../apis/categoryApis";
import { searchProducts } from "../../apis/productApis";

export type TProduct = {
  createdAt: string;
  description: string;
  id: string;
  name: string;
  images: [
    {
      location: string;
    }
  ];
  shops: [
    {
      shop: TShop;
    }
  ];
  ownerUserId: string;
  price: string;
  updatedAt: string;
};
export type TShop = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  foundDate: string;
  description: string;
  iconFileId: string;
  ownerUserId: string;
  contactMethods: string;
  verified: false;
  warranty: string;
  owner: {
    id: string;
    createdAt: string;
    updatedAt: string;
    profile: {
      userId: string;
      createdAt: string;
      updatedAt: string;
      name: string;
      bio: string;
      avatarFileId: string;
      avatar: {
        location: string;
      };
    };
  };
  icon: {
    location: "https://merchport-bucket.s3.ap-southeast-1.amazonaws.com/dc73a3994305a0146c5358db70140014";
  };
};

const CategoryPage: React.FC<RouteComponentProps> = (props) => {
  const [category, setCategory] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [maxPrice, setMaxPrice] = useState(0);
  const [filter, setFilter] = useState({ price_max: 100000, price_min: 0 });
  const id = props.location.search.replace("?id=", "");
  const [data, setData] = useState<TProduct[]>([]);
  const [limit, setlimit] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const getData = (e) => {
    setFilter(e);
    searchProducts({ startFrom: 0, categoryId: id, filter: e, q: "" }).then(
      (e) => {
        if (!e.haveError) {
          setData(e.data);
          setlimit(0);
          setIsLoad(false);
        } else {
          setData([]);
        }
      }
    );
  };
  const getDatanext = (ev) => {
    searchProducts({
      startFrom: limit + 10,
      categoryId: id,
      filter: filter,
      q: "",
    }).then((e) => {
      if (!e.haveError) {
        setData([...data, ...e.data]);
        ev.target.complete();
        if (e.data.length < 10) {
          setIsLoad(true);
        } else {
          setlimit(limit + 10);
        }
      } else {
        setData([]);
      }
    });
  };
  useIonViewWillEnter(() => {
    getData({ price_max: 100000, price_min: 0 });
    const prices = data.map((product) => parseFloat(product.price));
    setMaxPrice(Math.max(...prices));
    setCategory(props.location.pathname.replace("/category/", ""));
  });
  const handleProducts = () => {
    console.log("TYPE CHECK");
  };
  return (
    <IonPage className="category-page">
     <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton
            defaultHref="categories"
            icon={chevronBackOutline}
            color="medium"
            text=""
            mode="ios"
          />
        </IonButtons>
        <IonTitle className="text-color-grey-title text-bold ion-text-capitalize">
          {category}
        </IonTitle>
        <IonButtons slot="end">
          <IconButton
            className="default-icon-button"
            iconProps={{ icon: optionsOutline }}
            color="medium"
            onClick={() => setShowModal(true)}
          />
        </IonButtons>
      </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="product-card-grid ion-justify-content-center">
          {Array.isArray(data) ? (
            data.map((product: TProduct, index) => (
              <ProductCard
                store={product.shops[0].shop}
                added_on={product.createdAt}
                preview_src={
                  Array.isArray(product.images) && product.images.length > 0
                    ? product.images[0].location
                    : ""
                }
                {...product}
                key={index}
              />
            ))
          ) : (
            <></>
          )}
        </IonGrid>
        <>
          <IonInfiniteScroll
            onIonInfinite={(ev: any) => {
              getDatanext(ev);
            }}
            threshold="100px"
            disabled={isLoad}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </>
        <SortFilterModal
          show={showModal}
          filter={filter}
          onDismiss={() => setShowModal(false)}
          onSubmit={(e) => getData(e)}
          handleProducts={handleProducts}
        />
      </IonContent>
    </IonPage>
  );
};

export default CategoryPage;
