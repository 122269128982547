interface WrapperProps {
  src?: string;
  className?: string;
  handleClick: (url: string) => any;
}

const Wrapper: React.FC<WrapperProps> = (props) => {
  return (
    <div
      onClick={() => {
        props.handleClick("");
      }}
      className={`ion-padding ion-text-center ion-justify-content-center text-color-grey-lighter product-image ${props.className}`}
      style={
        props.src
          ? {
              backgroundImage: `url(${props.src})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
            }
          : {
              boxShadow: "0 0 3px var(--ion-color-grey-lightest)",
            }
      }
    >
      {!props.src && "No image available"}
    </div>
  );
};

export default Wrapper;
