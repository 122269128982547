import {ServerApi} from "../core/api";
import {TShopProfileUpdate} from "../types/UserTypes";

const api = new ServerApi();

export async function uploadImageBolb(bob){
   return api.uploadImageBolb(bob);
}
export async function setUserProfile(id: string | null, bio: string, displayName: string) {
   return api.setUserProfile(id, bio, displayName);
}

export async function setUserSetting(locale: string, receiveNotification: boolean, bio: string, id: string) {
   return api.setUserSetting(locale, receiveNotification, bio, id);
}

export async function setShopProfile(id: string, bio: TShopProfileUpdate) {
   return api.setShopProfile(id, bio);
}

