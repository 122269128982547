import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
} from "@ionic/react";
import {
  add,
  heartOutline,
  homeOutline,
  personCircleOutline,
  searchOutline,
  settingsOutline,
} from "ionicons/icons";
import { Redirect, Route, RouteComponentProps } from "react-router";
import CategoryPage from "../../pages/CategoryPage";
import FavouritesPage from "../../pages/FavouritesPage";
import CategoriesPage from "../../pages/CategoriesPage";
import HomePage from "../../pages/HomePage";
import SearchPage from "../../pages/SearchPage";
import CustomerSettingsPage from "../../pages/CustomerSettingsPage";
import GuestSettingsPage from "../../pages/GuestSettingsPage";
import OwnerSettingsPage from "../../pages/OwnerSettingsPage";
import ShopProfilePage from "../../pages/ShopProfilePage";
import "./style.scss";
import ForgotPwPage from "../../pages/ForgotPwPage";
import LoginPage from "../../pages/LoginPage";
import ResetPwPage from "../../pages/ResetPwPage";
import SignUpPage from "../../pages/SignUpPage";
import VerifyPage from "../../pages/VerifyPage";
import UIContext from "../../utils/UIContext";
import { useContext, useEffect, useState } from "react";
import ProductPage from "../../pages/ProductPage";
import ShopReviewsPage from "../../pages/ShopReviewsPage";
import EditProfilePage from "../../pages/EditProfilePage";
import BecomeShopOwnerPage from "../../pages/BecomeShopOwnerPage";
import AddProductPage from "../../pages/AddProductPage";
import EditAboutUsPage from "../../pages/EditAboutUsPage";
import AddReviewPage from "../../pages/AddReviewPage";
import ChatsPage from "../../pages/ChatsPage";
import ChatroomPage from "../../pages/ChatroomPage";
import { RootState } from "../../redux/reducers";
import { connect, ConnectedProps } from "react-redux";
import { setUserError, setUser } from "../../redux/actions/userActions";
import ProtectedRoute from "../ProtectedRoute.tsx";
import CustomerRoute from "../CustomerRoute.tsx";
import ShopOwnerRoute from "../ShopOwnerRoute.tsx";
import ChangePwPage from "../../pages/ChangePwPage";
import {getMyShop} from "../../apis/shopApi";
import {errorMessage} from "../../core/popups";
import {useHistory} from "react-router-dom";
import TermsAndConditions from "../../pages/TermsAndConditions";

const mapStateToProps = (state: RootState) => ({
  user: state.user.user,
});

const mapDispatch = {
  setUserError: setUserError,
  setUser: setUser,
};

const connector = connect(mapStateToProps, mapDispatch);
type PropsType = ConnectedProps<typeof connector> & RouteComponentProps;

const RoutingPage: React.FC<PropsType> = (props) => {
  const { showTabBar } = useContext(UIContext);
  const [id, setId] = useState('');
  const tabBarStyle = showTabBar ? undefined : { display: "none" };
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
      if(props.user?.account_type == "shop"){
        getMyShop().then((e)=>{
          if(!e.haveError && Array.isArray(e.data) && e.data.length >0){
            setId(e.data[0].id);
          }else{
            errorMessage(e.error);
          }
        });
      }
  }, [props.user?.account_type]);

  const renderSettings = () => {
    switch (props.user?.account_type) {
      case "shop":
        return (
          <IonTabButton tab="owner_profile" href={'/profile/'+id}>
            <IonIcon icon={personCircleOutline} />
          </IonTabButton>
        );
      case "customer":
        return (
          <IonTabButton tab="customer_settings" href="/customer-settings">
            <IonIcon icon={settingsOutline} />
          </IonTabButton>
        );
      default:
        return (
          <IonTabButton tab="guest_settings" href="/guest-settings">
            <IonIcon icon={settingsOutline} />
          </IonTabButton>
        );
    }
  };

  return (
    <>
      {/* NOTE: temporary solution (BUG now: black screen when code update and page auto reload), need refactoring later */}
      {!isLoading && (
        <IonTabs>
          <IonRouterOutlet >
            <Route path="/login" component={LoginPage} exact />
            <Route path="/signup" component={SignUpPage} exact />
            <Route path="/verify" component={VerifyPage} exact />
            <Route path="/forgot-password" component={ForgotPwPage} exact />
            <Route path="/reset-password/:name" component={ResetPwPage} exact />
            <Route path="/home" component={HomePage} exact />
            <Route path="/search" component={SearchPage} />
            <Route path="/categories" component={CategoriesPage} exact />
            <Route path="/category/:name" component={CategoryPage} />
            <Route exact path="/shop/:username/reviews" component={ShopReviewsPage} />
            <Route exact path="/shop/:username" component={ShopProfilePage} />
            <Route exact={true} path="/terms-conditions" component={TermsAndConditions} />
            <ShopOwnerRoute path="/product/create" component={AddProductPage} />
            <Route exact path="/product/view/:id" component={ProductPage} />
            <Route
              path="/become-shop-owner"
              component={BecomeShopOwnerPage}
              exact={true}
            />
            <Route  path="/guest-settings" component={GuestSettingsPage} exact />
            <Route path="/" render={() => {
                if( props.user == null  ){
                  const data = localStorage.getItem("allow");
                  if( data == null ){
                    return <Redirect to="/login" />;
                  }
                }
              return <Redirect to="/home" />;
            }} exact />
            <ProtectedRoute path="/profile/:username" component={ShopProfilePage} />
            <ProtectedRoute path="/profile-edit" component={EditProfilePage} />
            <ProtectedRoute path="/chats" component={ChatsPage} />
            <ProtectedRoute path="/chat/:id" component={ChatroomPage} />
            <ProtectedRoute path="/favourites" component={FavouritesPage} />
            <Route exact path="/change-password" component={ChangePwPage} />

            <Route
              exact
              path="/customer-settings"
              component={CustomerSettingsPage}
            />
            <Route path="/review/create" component={AddReviewPage} />

            <Route
              exact
              path="/owner-settings"
              component={OwnerSettingsPage}
            />
            <ShopOwnerRoute path="/about-us/edit" component={EditAboutUsPage} />
          </IonRouterOutlet>

          <IonTabBar slot="bottom" style={tabBarStyle} className="tabbar">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={homeOutline} />
            </IonTabButton>

            <IonTabButton tab="search" href="/search">
              <IonIcon icon={searchOutline} />
            </IonTabButton>

            <IonTabButton tab="favourites" href="/favourites">
              <IonIcon icon={heartOutline} />
            </IonTabButton>

            {renderSettings()}
          </IonTabBar>
        </IonTabs>
      )}

      <IonContent fullscreen className="tabpage" style={tabBarStyle}>
        <IonFab
          horizontal="center"
          slot="fixed"
          style={{ bottom: isPlatform("ios") ? "60px" : "30px" }}
        >
          <IonFabButton mode="ios"
          onClick={()=>{
            if(props.user == null){
              history.push('/login');
            }else if(props.user?.account_type == "shop") {
              history.push('/product/create?id=' + id);
            }else{
              history.push('/become-shop-owner');
            }
          }}
          >
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </>
  );
};

export default connector(RoutingPage);
