import {
  IonButtons,
  IonToolbar,
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { closeCircle, grid, trashOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "../IconButton";
import Searchbar from "../Searchbar";
import FavShopCard from "../FavShopCard";
import "./style.scss";
import UIContext from "../../utils/UIContext";
import EditFavFooter from "../EditFavFooter";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  getMyFollowShops,
  getMyLikedProduct,
  removeFollowShop,
} from "../../apis/LikeApi";
import { store } from "../../redux/store";
import { setFollow, setLikes } from "../../redux/actions/likeActions";
import { errorMessage } from "../../core/popups";
import { useTranslation } from "react-i18next";

const favShops = [
  {
    id: 1567,
    name: "original shop3 ",
    username: "origialshop3",
    avatar:
      "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    products: [],
    isCheckmarked: false,
  },
  {
    id: 1,
    name: "original  ",
    username: "origialshop3",
    avatar:
      "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    products: [
      { id: 1, src: "https://via.placeholder.com/150" },
      { id: 2, src: "" },
      { id: 3, src: "https://via.placeholder.com/150" },
      { id: 41, src: "" },
    ],
    isCheckmarked: true,
  },
  {
    id: 7,
    name: "original shop3 ",
    username: "origialshop3",
    avatar: "",
    products: [
      { id: 3, src: "https://via.placeholder.com/150" },
      { id: 41, src: "" },
      { id: 5, src: "https://via.placeholder.com/150" },
    ],
    isCheckmarked: false,
  },
  {
    id: 67,
    name: "haha shop3 ",
    username: "hahashop3",
    avatar:
      "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    products: [
      { id: 1, src: "https://via.placeholder.com/150" },
      { id: 3, src: "https://via.placeholder.com/150" },
      { id: 41, src: "" },
      { id: 5, src: "https://via.placeholder.com/150" },
    ],
    isCheckmarked: false,
  },
  {
    id: 567,
    name: "original shop3 ",
    username: "origialshop3",
    avatar: "",
    products: [
      { id: 41, src: "" },
      { id: 5, src: "https://via.placeholder.com/150" },
    ],
    isCheckmarked: false,
  },
  {
    id: 156,
    name: "original shop3 ",
    username: "origialshop3",
    avatar:
      "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    products: [
      { id: 1, src: "https://via.placeholder.com/150" },
      { id: 2, src: "" },
      { id: 3, src: "https://via.placeholder.com/150" },
      { id: 41, src: "" },
    ],
    isCheckmarked: true,
  },
  {
    id: 157,
    name: "bye shop3 ",
    username: "byeshop3",
    avatar:
      "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y",
    products: [{ id: 5, src: "https://via.placeholder.com/150" }],
    isCheckmarked: true,
  },
  {
    id: 167,
    name: "original shop3 ",
    username: "origialshop3",
    avatar: "",
    products: [
      { id: 1, src: "https://via.placeholder.com/150" },
      { id: 2, src: "" },
    ],
    isCheckmarked: true,
  },
];

// TODO: search API; edit function; refactor datatype

const FavShopsTab: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [shops, setShops] = useState<any[]>(favShops);
  const like = useSelector((x: RootState) => x.like.shop);
  const [searchValue, setSearchValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedShops, setSelectedShops] = useState<any>([]);

  const { showTabBar, setShowTabBar } = useContext(UIContext);

  useEffect(() => {
    if (isEdit) {
      setShowTabBar(false);
      setSelectedShops([]);
    } else {
      setShowTabBar(true);
    }
  }, [isEdit]);

  const handleSearchOnChange = (e) => {
    const value = e.detail.value!;
    setSearchValue(value);
    if (!value) {
      setShops(favShops);
    } else {
      setShops(shops.filter((shop) => shop.name.includes(value)));
    }
  };

  const handleShopOnSelect = (shopId) => {
    if (!selectedShops.includes(shopId)) {
      setSelectedShops([...selectedShops, shopId]);
    } else {
      setSelectedShops(selectedShops.filter((id) => id !== shopId));
    }
  };

  const handleShopsOnDelete = () => {
    const prom = selectedShops.map((e) => removeFollowShop(e));
    Promise.allSettled(prom)
      .then((e) => {
        getMyFollowShops().then((e) => {
          if (!e.haveError) {
            store.dispatch(setFollow(e.data));
          } else {
            errorMessage(t("network_Error"));
          }
        });
      })
      .catch((e) => {
        errorMessage(t("network_Error"));
        getMyFollowShops().then((e) => {
          if (!e.haveError) {
            store.dispatch(setFollow(e.data));
          }
        });
      });
    setShops(shops.filter((shop) => !selectedShops.includes(shop.id)));
    setIsEdit(false);
  };

  return (
    <>
      <IonToolbar>
        {!isEdit && (
          <Searchbar
            placeholder={t("searchShop")}
            value={searchValue}
            onChange={handleSearchOnChange}
          ></Searchbar>
        )}
        <IonButtons slot="end">
          {isEdit ? (
            <IconButton
              color="medium"
              onClick={() => setIsEdit(false)}
              iconProps={{
                icon: closeCircle,
                className: "toolbar-button",
                size: "small",
                slot: "start",
              }}
              title={t("cancel")}
            />
          ) : (
            <IconButton
              color="medium"
              onClick={() => setIsEdit(true)}
              iconProps={{
                icon: grid,
                className: "toolbar-button",
                size: "small",
                slot: "start",
              }}
              title={t("edit")}
            />
          )}
        </IonButtons>
      </IonToolbar>

      {like.length > 0 ? (
        <>
          {(searchValue && searchValue.length > 0
            ? like.filter((shop) => {
                return shop?.shop?.name.includes(searchValue);
              })
            : like
          ).map((shop, index) => {
            return (
              <Link to={isEdit ? "#" : `/shop/${shop?.shop?.id}`} key={index}>
                <FavShopCard
                  {...shop}
                  products={shop.products}
                  isEdit={isEdit}
                  onSelect={handleShopOnSelect}
                />
              </Link>
            );
          })}
        </>
      ) : (
        <p className="ion-text-center">{t("NoFavouriteShops")}</p>
      )}

      {isEdit && !showTabBar && (
        <EditFavFooter
          count={selectedShops.length}
          itemName="shop"
          onDelete={handleShopsOnDelete}
        />
      )}
    </>
  );
};

export default FavShopsTab;
